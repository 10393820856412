import './polyfills'
import {createRoot} from 'react-dom/client'
import React from 'react'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'

// Apps
import './theme/assets/fonticon/fonticon.css'
import './theme/assets/keenicons/duotone/style.css'
import './theme/assets/keenicons/outline/style.css'
import './theme/assets/keenicons/solid/style.css'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './theme/assets/css/style.rtl.css'
 **/
import './theme/assets/sass/style.scss'
import './theme/assets/sass/plugins.scss'
import './theme/assets/sass/style.react.scss'
import {setupAxios} from './app/modules/auth'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {persistor, store} from './app/redux/store'
import {BrowserRouter} from 'react-router-dom'
import {App} from './app/App'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    // <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
    // </React.StrictMode>
  )
}
