import React from 'react'
import { isTokenActivated } from '../utils/Helpers'

type Props = {
  to: string
  replace: boolean,
  token: string
}

const withAuth = (RenderComponent: React.ComponentType, NavigateComponent: React.ComponentType<{ to: string; replace: boolean }>) =>
  ({ to, replace, token, ...props }: Props) => {
    return !isTokenActivated(token) ? (
      <RenderComponent {...props} />
    ) : (
      <NavigateComponent {...{ to, replace }} />
    )
  }

export default withAuth
