import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getReport, getReportById, updateReport } from '../../modules/auth/core/_requests'
import { getReportParamModal, getTemplateParamModal } from '../../modules/auth'

export const callGetReport = createAsyncThunk(
  'reportSlice/callGetReport',
  async (params: getReportParamModal, { rejectWithValue }) => {
    try {
      return await getReport(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

export const callGetReportById = createAsyncThunk(
  'reportSlice/callGetReportById',
  async (params: getTemplateParamModal, { rejectWithValue }) => {
    try {
      return await getReportById(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

export const callGetOverDueReport = createAsyncThunk(
  'reportSlice/callGetOverDueReport',
  async (params: getReportParamModal, { rejectWithValue }) => {
    try {
      return await getReport(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

export const callGetUpcomingReport = createAsyncThunk(
  'reportSlice/callGetUpcomingReport',
  async (params: getReportParamModal, { rejectWithValue }) => {
    try {
      return await getReport(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

export const callGetOneTimeReport = createAsyncThunk(
  'reportSlice/callGetOneTimeReport',
  async (params: getReportParamModal, { rejectWithValue }) => {
    try {
      return await getReport(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any
export const callEditReportById = createAsyncThunk(
  'reportSlice/callEditReportById',
  async (params: any, { rejectWithValue }) => {
    try {
      return await updateReport(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any
const initialState = {
  report: {
    loading: false,
    data: [],
  },
  report_details: {
    loading: false,
    report_detail: {},
    error: null,
  },
  overdueReport: {
    loading: false,
    result: { data: [], total_page: 0 },
  },
  upcomingReport: {
    loading: false,
    result: { data: [], total_page: 0 },
  },
  oneTimeReport: {
    loading: false,
    result: { data: [], total_page: 0 },
  }
}

const ReportSlice = createSlice({
  name: 'reportSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(callGetReport.pending, (state, action) => {
        state.report.loading = true
      })
      .addCase(callGetReport.fulfilled, (state, action) => {
        state.report.loading = false
        state.report.data = action.payload.data.result[0]
      })
      .addCase(callGetReport.rejected, (state, action) => {
        state.report.loading = false
      })

      .addCase(callGetReportById.pending, (state, action) => {
        state.report_details.loading = true
      })
      .addCase(callGetReportById.fulfilled, (state, action) => {
        state.report_details.loading = false
        state.report_details.report_detail = action?.payload?.data?.result
      })
      .addCase(callGetReportById.rejected, (state, action) => {
        state.report_details.loading = false
      })

      .addCase(callGetOverDueReport.pending, (state, action) => {
        state.overdueReport.loading = true
      })
      .addCase(callGetOverDueReport.fulfilled, (state, action) => {
        state.overdueReport.loading = false
        state.overdueReport.result = action.payload.data.result[0]
      })
      .addCase(callGetOverDueReport.rejected, (state, action) => {
        state.overdueReport.loading = false
      })

      .addCase(callGetUpcomingReport.pending, (state, action) => {
        state.overdueReport.loading = true
      })
      .addCase(callGetUpcomingReport.fulfilled, (state, action) => {
        state.upcomingReport.loading = false
        state.upcomingReport.result = action.payload.data.result[0]
      })
      .addCase(callGetUpcomingReport.rejected, (state, action) => {
        state.upcomingReport.loading = false
      })

      .addCase(callGetOneTimeReport.pending, (state, action) => {
        state.oneTimeReport.loading = true
      })
      .addCase(callGetOneTimeReport.fulfilled, (state, action) => {
        state.oneTimeReport.loading = false
        state.oneTimeReport.result = action.payload.data.result[0]
      })
      .addCase(callGetOneTimeReport.rejected, (state, action) => {
        state.oneTimeReport.loading = false
      })
  },
})

export default ReportSlice.reducer
