const TextPdfElement = ({elementData}: any) => {
  return (
    <div style={{gridColumn: `span ${elementData.col || 4}`}}>
      <div>
        {elementData.isLabel && <p style={{margin: 0, fontWeight: 'bold'}}>{elementData.label}</p>}
        <p>{elementData.value}</p>
      </div>
    </div>
  )
}
export {TextPdfElement}
