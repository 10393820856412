import {SVGProps} from 'react'

const Left = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} viewBox='0 0 6 14' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='1' cy='1' r='1' transform='matrix(1 0 0 -1 0 2)' fill='#9FAFC0' />
      <circle cx='1' cy='1' r='1' transform='matrix(1 0 0 -1 0 6)' fill='#9FAFC0' />
      <circle cx='1' cy='1' r='1' transform='matrix(1 0 0 -1 0 10)' fill='#9FAFC0' />
      <circle cx='1' cy='1' r='1' transform='matrix(1 0 0 -1 0 14)' fill='#9FAFC0' />
      <circle cx='1' cy='1' r='1' transform='matrix(1 0 0 -1 4 2)' fill='#9FAFC0' />
      <circle cx='1' cy='1' r='1' transform='matrix(1 0 0 -1 4 6)' fill='#9FAFC0' />
      <circle cx='1' cy='1' r='1' transform='matrix(1 0 0 -1 4 10)' fill='#9FAFC0' />
      <circle cx='1' cy='1' r='1' transform='matrix(1 0 0 -1 4 14)' fill='#9FAFC0' />
    </svg>
  )
}

export default Left
