import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {createAttorney, getAttorney, getAttorneys} from '../../modules/auth/core/_requests'
import {createAttorneyModal, getAttorneysModal} from '../../modules/auth'

export const callCreateAttorney = createAsyncThunk(
  'attorneySlice/callCreateAttorney',
  async (params: createAttorneyModal, {rejectWithValue}) => {
    try {
      return await createAttorney(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

export const callGetAttorneys = createAsyncThunk(
  'attorneySlice/callGetAttorneys',
  async (params: getAttorneysModal, {rejectWithValue}) => {
    try {
      return await getAttorneys(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

export const callAttorney = createAsyncThunk(
  'attorneySlice/callAttorney',
  async (params: any, {rejectWithValue}) => {
    try {
      return await getAttorney(true, `?attorney=1`)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

export const callSA = createAsyncThunk(
  'attorneySlice/callSA',
  async (params: any, {rejectWithValue}) => {
    try {
      return await getAttorney(true, `?supervising_attorney=1`)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

const initialState = {
  createAttorney: {loading: false, error: null},
  attorneys: {loading: false, data: {data: [], total_page: 0}, totalPage: 1},
  all_attorney: {loading: false, data: []},
  all_sa: {loading: false, data: []},
}

const AttorneySlice = createSlice({
  name: 'attorneySlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(callCreateAttorney.pending, (state) => {
        state.createAttorney.loading = true
      })
      .addCase(callCreateAttorney.fulfilled, (state, action) => {
        state.createAttorney.loading = false
        state.createAttorney.error = null
      })
      .addCase(callCreateAttorney.rejected, (state, action) => {
        state.createAttorney.loading = false
        state.createAttorney.error = action.payload.response.data.message
      })

      .addCase(callGetAttorneys.pending, (state) => {
        state.attorneys.loading = true
      })
      .addCase(callGetAttorneys.fulfilled, (state, action) => {
        state.attorneys.loading = false
        state.attorneys.data = action.payload.data.result
      })
      .addCase(callGetAttorneys.rejected, (state, action) => {
        state.attorneys.loading = false
      })

      .addCase(callAttorney.pending, (state, action) => {
        state.all_attorney.loading = true
      })
      .addCase(callAttorney.fulfilled, (state, action) => {
        state.all_attorney.loading = false
        state.all_attorney.data = action.payload.data?.result.data
      })
      .addCase(callAttorney.rejected, (state, action) => {
        state.all_attorney.loading = false
      })

      .addCase(callSA.pending, (state, action) => {
        state.all_sa.loading = true
      })
      .addCase(callSA.fulfilled, (state, action) => {
        state.all_sa.loading = false
        state.all_sa.data = action.payload.data?.result.data
      })
      .addCase(callSA.rejected, (state, action) => {
        state.all_sa.loading = false
      })
  },
})

export default AttorneySlice.reducer
