import {PageTitle} from '../../../theme/layout/core'
import {DefaultTitle} from '../../../theme/layout/components/header/page-title/DefaultTitle'
import {
  ROLES,
  accountRole,
  dashboardBreadCrumbs,
  fakeAttorneyDataHead,
  states,
} from '../../../utils/constants'
import Button from '../../components/Button'

import {useEffect, useState} from 'react'
import {CreateAttorneyForm} from './components/CreateAttorneyForm'
import {useLocation, useNavigate} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../redux/redux-hook/hooks'
import {CustomSelect} from '../../components/CustomSelect'
import {useSelector} from 'react-redux'
import {RootStateTypes} from '../../modules/auth'
import SearchBar from '../../components/SearchBar'
import {callFrequency} from '../../redux/reducer/FrequencySlice'
import DateFieldIcon from '../../assets/icons/DateFieldIcon'
import {Pagination} from '../../components/Pagination'
import moment from 'moment'
import {callGetAttorneys} from '../../redux/reducer/AttorneySlice'

export const ViewAttorney = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const createAttorney = queryParams.get('create-attorney')
  const [createAttorneyModalOpen, setCreateAttorneyModalOpen] = useState(Boolean(createAttorney))
  const navigate = useNavigate()
  const [params, setParams] = useState({
    search: '',
    date_filter: '',
    state: '',
    admin: 1,
    attorney: 1,
    supervising_attorney: 1,
    limit: 10,
    page: 1,
  })
  const [roleValue, setRoleValue] = useState('')

  const dispatch = useAppDispatch()
  const {token, role} = useSelector((state: RootStateTypes) => state.auth.user.data.result)
  const frequency = useAppSelector((state) => state.frequencySlice.frequency.frequencies)
  const {data, loading} = useAppSelector((state) => state.attorney.attorneys)

  const onSearch = (e: any) => {
    const {value} = e.target
    setParams({...params, search: value})
  }

  const onFilterDate = (value: any) => {
    setParams({...params, date_filter: value._id})
  }

  const accountRoleOnChange = (value: any) => {
    setRoleValue(value._id)
    if (value._id === 'attorney') {
      setParams({...params, attorney: 1, admin: 0, supervising_attorney: 0})
    } else if (value._id === 'admin') {
      setParams({...params, attorney: 0, admin: 1, supervising_attorney: 0})
    } else if (value._id === 'supervising_attorney') {
      setParams({...params, attorney: 0, admin: 0, supervising_attorney: 1})
    } else {
      setParams({...params, attorney: 1, admin: 1, supervising_attorney: 1})
    }
  }

  const stateOnChange = (value: any) => {
    setParams({...params, state: value._id})
  }

  const onPageChange = (page: number) => {
    setParams({...params, page: page})
  }

  useEffect(() => {
    setCreateAttorneyModalOpen(Boolean(createAttorney))
  }, [createAttorney])

  useEffect(() => {
    dispatch(callGetAttorneys({token, ...params}))
  }, [dispatch, params, token])

  useEffect(() => {
    dispatch(callFrequency({token}))
  }, [dispatch, token])

  return (
    // <div className='vh-100' style={{ padding: '50px 0px' }}>
    <div className='min-vh-100' style={{padding: '50px 0px'}}>
      {/* Modal */}
      <CreateAttorneyForm open={createAttorneyModalOpen} setOpen={setCreateAttorneyModalOpen} />

      {/* Page Head */}
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Attorney Manager</PageTitle>
      <DefaultTitle
        onBackPress={() => {
          navigate(-1)
        }}
      />

      {/* <div
        className='card shadow-sm border'
        style={{height: 'calc(100% - 120px)', padding: '30px 0px', marginTop: '33px'}}
      > */}
      <div
        className='card shadow-sm border'
        style={{minHeight: 'calc(100vh - 232px)', padding: '30px 0px', marginTop: '33px'}}
      >
        {/* begin::Header */}
        <div className='h-auto' style={{padding: '0px 30px 20px 30px'}}>
          <div className='w-100 d-flex justify-content-between align-items-center p-0 m-0 gap-4 flex-wrap'>
            <SearchBar
              placeholder='Search Attorneys'
              handleSearch={onSearch}
              loading={loading && params.search.length !== 0}
            />
            <div className='d-flex gap-4 flex-wrap justify-content-between'>
              <CustomSelect
                name='filterRole'
                className='w-200px h-45px'
                placeHolder='Filter Role'
                options={[
                  ...accountRole,
                  {
                    _id: '',
                    name: 'Reset Filter',
                  },
                ]}
                value={roleValue}
                onChange={accountRoleOnChange}
              />
              <CustomSelect
                name='filterStates'
                className='w-200px h-45px'
                placeHolder='Filter States'
                options={[
                  ...states.map((state: any) => ({_id: state.value, name: state.value})),
                  {
                    _id: '',
                    name: 'Reset Filter',
                  },
                ]}
                value={params.state}
                onChange={stateOnChange}
              />
              <CustomSelect
                className='w-175px h-45px'
                name='date'
                value={params.date_filter}
                placeHolder='Select Date Range'
                options={[...frequency, {_id: '', name: 'Reset Filter'}] || []}
                onChange={onFilterDate}
                icon={<DateFieldIcon fill='none' height={16} stroke='black' />}
              />
              {role === ROLES.ADMIN && (
                <Button
                  text='Create Attorney'
                  props={{onClick: () => setCreateAttorneyModalOpen(true)}}
                />
              )}
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body overflow-auto position-relative' style={{padding: '0px 30px'}}>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead className='' style={{position: 'sticky', top: 0, zIndex: 2}}>
                <tr className='fw-bold text-muted'>
                  {fakeAttorneyDataHead.map((columnName, key) => {
                    return (
                      <th key={key} className={`${columnName === 'ATTORNEY' && 'min-w-275px'}`}>
                        {columnName}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {data.data.map((data: any, key) => {
                  return (
                    <tr key={key}>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data.first_name + ' ' + data.last_name}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data.email}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data.bar_number}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data.states.join(', ')}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data.role
                              .split('_')
                              .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                              .join(' ')}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {moment(data.created_date).format('MMM D, YYYY')}
                          </span>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {data.data.length === 0 && (
            <div
              className='position-absolute top-0 left-0 right-0 h-100 d-flex justify-content-center align-items-center'
              style={{width: 'calc(100% - 60px)'}}
            >
              <h1 className='m-0 fs-2x text-gray-400'>No Data Found</h1>
            </div>
          )}
          {/* end::Table container */}
        </div>
        <div className='h-auto d-flex justify-content-end' style={{padding: '20px 30px 0px 30px'}}>
          <Pagination
            currentPage={params.page}
            totalPages={data.total_page}
            onPageChange={onPageChange}
          />
        </div>
        {/* begin::Body */}
      </div>
    </div>
  )
}
