const SelectPdfElement = ({values}: any) => {
  return (
    <div style={{gridColumn: `span ${values.col || 4}`}}>
      {values.isLabel && <p style={{fontWeight: 'bold', margin: 0}}>{values.labelTitle}</p>}
      <p>{values.selectedOption}</p>
    </div>
  )
}

export {SelectPdfElement}
