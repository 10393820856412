import axios from 'axios'
import {
  CheckOtpModal,
  LoginModal,
  MattersParamModal,
  createAttorneyModal,
  resetPasswordModal,
  sentForgotMailModal,
  createMatterModal,
  ClientParamModal,
  getTemplateParamModal,
  createReportModal,
  updateTemplateModal,
  getReportParamModal,
  getTemplateParamsModal,
  getAttorneysModal,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL
export const BASE_URL = 'https://reportl-api.azurewebsites.net/api'
// export const BASE_URL = 'http://localhost:3040/api'
// export const BASE_URL_IMAGE = 'https://192.168.86.100:3030'

export const LOGIN_URL = `${BASE_URL}/login`
export const CHECK_OTP = `${BASE_URL}/check_otp`
export const FORGOT_PASS_MAIL = `${BASE_URL}/send_reset_mail`
export const CHANGE_PASS_URL = `${BASE_URL}/reset_password`
export const CREATE_ATTORNEY = `${BASE_URL}/create_user`
export const GET_ATTORNEY = `${BASE_URL}/get_all_users`
export const GET_USER_PROFILE_URL = `${BASE_URL}/me?token=`
export const DELETE_MATTER = `${BASE_URL}/delete_matter/`
export const CREATE_MATTER = `${BASE_URL}/create_matter`
export const GET_ALL_MATTERS = `${BASE_URL}/get_all_matters`
export const CREATE_TEMPLATE = `${BASE_URL}/create_template`
export const UPDATE_TEMPLATE = `${BASE_URL}/update_template`
export const CREATE_REPORT = `${BASE_URL}/create_report`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const GET_CLIENTS_URL = `${BASE_URL}/get_all_clients`
export const GET_FREQUENCY_URL = `${BASE_URL}/get_all_frequency`
export const GET_TEMPLATE_URL = `${BASE_URL}/get_all_templates`
export const GET_TEMPLATE_BY_ID_URL = `${BASE_URL}/get_template_details`
export const DELETE_TEMPLATE = `${BASE_URL}/delete_template`
export const GET_REPORT_BY_ID_URL = `${BASE_URL}/get_report_details`
export const GET_REPORT_URL = `${BASE_URL}/get_all_reports`
export const EDIT_REPORT_URL = `${BASE_URL}/update_report`
export const SENT_REPORT = `${BASE_URL}/send_approve_mail`
// export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
// export function login(email: string, password: string) {
//   return axios.post<AuthModel>(LOGIN_URL, {
//     email,
//     password,
//   })
// }

// Server should return AuthModel

export function login(param: LoginModal) {
  return axios.post<LoginModal>(LOGIN_URL, param)
}

export function checkOtp(param: CheckOtpModal) {
  return axios.post<CheckOtpModal>(CHECK_OTP, param)
}

export function sendForgotMail(param: sentForgotMailModal) {
  return axios.get(
    `${FORGOT_PASS_MAIL}?email=${param.email}&reset_url=http://localhost:3011/reset-password`
  )
}

export function resetPassword(param: resetPasswordModal) {
  return axios.post(CHANGE_PASS_URL, param)
}
export const getUserByToken = (token: string) => {
  return axios.get(`${GET_USER_PROFILE_URL}${token}`)
}
export function createAttorney(param: createAttorneyModal) {
  return axios.post(CREATE_ATTORNEY, param)
}

export function getAttorney(isFilter = false, value?: any) {
  if (isFilter) {
    // console.log('IsFlitered')
    return axios.get(`${GET_ATTORNEY}${value}`)
  } else {
    // console.log('Called Default')
    return axios.get(GET_ATTORNEY)
  }
}

export const getAttorneys = (param: getAttorneysModal) => {
  return axios.get(
    `${GET_ATTORNEY}?attorney=${param.attorney}&supervising_attorney=${param.supervising_attorney}&state=${param.state}&search=${param.search}&date_filter=${param.date_filter}&page=${param.page}&limit=${param.limit}&admin=${param.admin}`,
    {
      headers: {
        Authorization: `Bearer ${param.token}`,
      },
    }
  )
}

export const getAllMatters = (param: MattersParamModal) => {
  // console.log('🚀 ~ file: _requests.ts:107 ~ getAllMatters ~ param:', param)
  return axios.get(
    `${GET_ALL_MATTERS}?list_all=${param.list_all}&client_id=${param.client_id}&search=${param.search}&page=${param.page}&limit=${param.limit}`,
    {
      headers: {
        Authorization: `Bearer ${param.token}`,
      },
    }
  )
}

export function deleteMatter(param: {id: string; token: string}) {
  return axios.delete(DELETE_MATTER + param.id, {
    headers: {
      Authorization: `Bearer ${param.token}`,
    },
  })
}

export function createMatter({token, ...param}: any) {
  return axios.post(CREATE_MATTER, param, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getClients = ({token, param}: ClientParamModal) => {
  return axios.get(`${GET_CLIENTS_URL}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
export const getPublicClients = ({token, ...param}: any) => {
  // console.log('param: ', param)

  return axios.get(`${GET_CLIENTS_URL}?one_time_report=${param?.oneTimeReport}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
export const getFrequency = ({token}: ClientParamModal) => {
  return axios.get(GET_FREQUENCY_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// create Template
export function createTemplate({token, ...param}: createMatterModal) {
  return axios.post(CREATE_TEMPLATE, param, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// update Template
export function updateTemplate({token, ...param}: updateTemplateModal) {
  return axios.put(UPDATE_TEMPLATE, param, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// Assign Report
export function assignReport({token, ...param}: createReportModal) {
  return axios.post(CREATE_REPORT, param, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getTemplate({token, ...param}: getTemplateParamsModal) {
  return axios.get(
    `${GET_TEMPLATE_URL}?search=${param.search}&limit=${param.limit}&page=${param.page}&client_id=${param.client_id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}
// get_template_details
export function getTemplateById({token, ...param}: getTemplateParamModal) {
  return axios.get(`${GET_TEMPLATE_BY_ID_URL}?id=${param.report_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// delete_template
export function deleteTemplate({token, ...param}: getTemplateParamModal) {
  return axios.delete(`${DELETE_TEMPLATE}/${param.report_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

//get_report_details
export function getReportById({token, ...param}: getTemplateParamModal) {
  return axios.get(`${GET_REPORT_BY_ID_URL}?id=${param.report_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getReport({token, ...param}: getReportParamModal) {
  return axios.get(
    `${GET_REPORT_URL}?search=${param.search}&limit=${param.limit}&page=${param.page}&client_id=${param.client_id}&status=${param.status}&date_filter=${param.date_filter}&due_date=${param.due_date}&attorney_id=${param.attorney_id}&supervising_attorney_id=${param.supervising_attorney_id}&matter_id=${param.matter_id}&order=${param.order}&sort=${param.sort}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export function updateReport({token, ...param}: any) {
  return axios.put(EDIT_REPORT_URL, param, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
export function sentReport({token, ...param}: any) {
  // console.log('param: ', formData);

  return axios.post(SENT_REPORT, param, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export const setHeaderTocken = (access_token: string) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
}
