import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  createAttorney,
  createTemplate,
  deleteTemplate,
  getAttorney,
  getTemplate,
  getTemplateById,
} from '../../modules/auth/core/_requests'
import {getTemplateParamModal, getTemplateParamsModal} from '../../modules/auth'
import {Template} from 'webpack'

export const callCreateTemplate = createAsyncThunk(
  'templateSlice/callCreateTemplate',
  async (params: any, {rejectWithValue}) => {
    try {
      return await createTemplate(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

export const callGetTemplate = createAsyncThunk(
  'templateSlice/callGetTemplate',
  async (params: getTemplateParamsModal, {rejectWithValue}) => {
    try {
      return await getTemplate(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

export const callGetTemplateById = createAsyncThunk(
  'templateSlice/callGetTemplateById',
  async (params: getTemplateParamModal, {rejectWithValue}) => {
    try {
      return await getTemplateById(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

export const callEditTemplateById = createAsyncThunk(
  'templateSlice/callEditTemplateById',
  async (params: getTemplateParamModal, {rejectWithValue}) => {
    try {
      return await getTemplateById(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

export const callDeleteTemplate = createAsyncThunk(
  'templateSlice/callDeleteTemplate',
  async (params: getTemplateParamModal, {rejectWithValue}) => {
    try {
      return await deleteTemplate(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

const initialState = {
  templates: {
    loading: false,
    data: {data: [], total_page: 0},
  },
  template_detail: {
    loading: false,
    template_details: {},
    error: null,
  },
}

const TemplateSlice = createSlice({
  name: 'templateSlice',
  initialState,
  reducers: {
    clearTemplateDetails: (state) => {
      state.template_detail = initialState.template_detail
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(callGetTemplate.pending, (state, action) => {
        state.templates.loading = true
      })
      .addCase(callGetTemplate.fulfilled, (state, action) => {
        state.templates.loading = false
        state.templates.data = action.payload.data.result
      })
      .addCase(callGetTemplate.rejected, (state, action) => {
        state.templates.loading = false
      })

      .addCase(callGetTemplateById.pending, (state, action) => {
        state.templates.loading = true
      })
      .addCase(callGetTemplateById.fulfilled, (state, action) => {
        // console.log('action: ', action)
        state.template_detail.loading = false
        state.template_detail.template_details = action.payload?.data?.result
      })
      .addCase(callGetTemplateById.rejected, (state, action) => {
        state.templates.loading = false
        state.template_detail.error = action.payload?.data?.message
      })
      .addCase(callDeleteTemplate.pending, (state, action) => {
        state.templates.loading = true
      })
      .addCase(callDeleteTemplate.fulfilled, (state, action) => {
        // console.log('action: ', action)
        state.templates.loading = false
      })
      .addCase(callDeleteTemplate.rejected, (state, action) => {
        state.templates.loading = false
      })
  },
})

export const {clearTemplateDetails} = TemplateSlice.actions
export default TemplateSlice.reducer
