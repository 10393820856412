import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../theme/helpers'
import Button from '../../../components/Button'
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hook/hooks'
import InputBox from '../../../components/InputBox'
import {useFormik} from 'formik'
import {reviewSendReportValidationSchema} from '../../../../utils/validations'
import {CustomSelect} from '../../../components/CustomSelect'
import {callAllClient} from '../../../redux/reducer/ClientSlice'
import {useSelector} from 'react-redux'
import {RootStateTypes} from '../../../modules/auth'
import CreatableMultiSelectTextInput from '../../../components/CreatableMultiSelectTextInput'

interface Option {
  label: string
  value: string
}
interface ReportReviewModalProps {
  open: boolean
  setOpen: Function
  reportId: string
  report_detail: any
  handleSendReport: any
  multiEmailValue: readonly Option[]
  setMultiEmailValue: Dispatch<SetStateAction<readonly Option[]>>
}

export const ReportReviewModal: React.FC<ReportReviewModalProps> = ({
  open,
  setOpen,
  reportId,
  report_detail,
  handleSendReport,
  multiEmailValue,
  setMultiEmailValue,
}) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const token = useSelector((state: RootStateTypes) => state.auth.user.data.result.token)
  // const allClient = useAppSelector((state) => state.clientSlice.allClients.client)

  const {handleChange, handleSubmit, touched, handleBlur, errors, values, setFieldValue}: any =
    useFormik({
      initialValues: {
        reportName: report_detail?.name,
        matterName: report_detail.matter?.name,
        client: report_detail.client?.name,
        receiptEmail: [],
      },
      validationSchema: reviewSendReportValidationSchema,
      enableReinitialize: true,
      onSubmit: async (values) => {
        try {
          setLoading(true)
          await handleSendReport()
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      },
    })

  const clientOnChange = (value: {_id: string; name: string}) => {
    setFieldValue('client', value._id)
  }

  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    dispatch(callAllClient({token}))
  }, [dispatch, token])

  return (
    <Modal show={open} centered className='modal-lg' onHide={onClose}>
      <div className='modal-content gap-8 rounded-3' style={{padding: '40px'}}>
        <div className='modal-header border-0 p-0 flex-column align-items-start gap-3'>
          <KTIcon iconName='folder' className='fs-2hx text-light bg-primary rounded-circle p-4' />
          <div className='d-flex flex-column gap-3'>
            <h1 className='m-0'>Review Your Report</h1>
            <p className='m-0'>Confirm the report details before sending to the client.</p>
          </div>
        </div>
        <div className='modal-body border rounded' style={{padding: '30px'}}>
          <form className='d-flex flex-column gap-5 w-100' onSubmit={(e) => e.preventDefault()}>
            <div className='row'>
              <div className='col'>
                <InputBox
                  label='REPORT NAME'
                  labelClassName='fs-8 text-gray-500'
                  className='p-0 border-0 rounded-0 bg-transparent text-dark'
                  error={touched.reportName && errors.reportName}
                  props={{
                    type: 'text',
                    name: 'reportName',
                    value: values.reportName,
                    onChange: handleChange,
                    onBlur: handleBlur,
                    disabled: true,
                  }}
                />
              </div>
              <div className='col'>
                <InputBox
                  label='MATTER NAME'
                  labelClassName='fs-8 text-gray-500'
                  className='p-0 border-0 rounded-0 bg-transparent text-dark'
                  error={touched.matterName && errors.matterName}
                  props={{
                    type: 'text',
                    name: 'matterName',
                    value: values.matterName || 'N/A',
                    onChange: handleChange,
                    onBlur: handleBlur,
                    disabled: true,
                  }}
                />
              </div>
              <div className='col'>
                <label className={`form-label fw-bolder text-dark fs-6 mb-2 fs-8 text-gray-500`}>
                  ASSIGNED CLIENT
                </label>
                <div
                  className={`collapsed cursor-pointer border px-3 d-flex justify-content-between align-items-center rounded bg-gray-200 border-gray-200`}
                  style={{height: '38px'}}
                >
                  <h6
                    className='m-0 fs-6 fw-normal'
                    style={{
                      display: 'block',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '100%',
                    }}
                  >
                    {values.client}
                  </h6>
                  <KTIcon iconName='profile-circle' className='fs-1 text-dark' />
                </div>
                {/* <CustomSelect
                  label='ASSIGNED CLIENT'
                  labelClassName='fs-8 text-gray-500'
                  name='client'
                  placeHolder='Client'
                  options={allClient}
                  value={values.client}
                  onChange={clientOnChange}
                  error={touched.client && errors.client}
                  icon={<KTIcon iconName='profile-circle' className='fs-1 text-dark' />}
                  className='bg-white'
                  clientSelect={true}
                /> */}
              </div>
            </div>
            <div className='row'>
              <label className={`form-label fw-bolder mb-2 fs-8 text-gray-500 text-uppercase`}>
                Recipient email
              </label>
              <CreatableMultiSelectTextInput
                multiEmailValue={multiEmailValue}
                setMultiEmailValue={setMultiEmailValue}
                placeholder='Type email and press enter...'
              />
            </div>
          </form>
        </div>
        <div className='modal-footer border-0 p-0 justify-content-between'>
          <div className='d-flex gap-4'>
            <Button text='Cancel' className='btn-secondary' props={{onClick: onClose}} />
            <Button
              text='Send Report'
              loading={loading}
              props={{
                type: 'button',
                disabled: multiEmailValue.length === 0 || loading,
                onClick: handleSubmit,
              }}
            />
          </div>
          <p className='m-0 fs-6 fw-bold text-muted'>Report ID: {reportId}</p>
        </div>
      </div>
    </Modal>
  )
}