import React, {DragEvent, useMemo, useState} from 'react'
import {useFocus} from '../../../../../hooks/useFocus'
import {KTIcon} from '../../../../../theme/helpers'
import CheckBoxIcon from '../../../../assets/icons/CheckBoxIcon'
import Left from '../../../../assets/icons/Left'

interface CheckBoxFieldProps {
  index: number
  onDelete: (param: number) => void
  onDuplicate: (param: number) => void
  onSave: (data: any, index: number) => void
  values: any
  handleFormDragStart: (e: DragEvent<HTMLDivElement>) => void
  handleFormDragOver: (e: DragEvent<HTMLDivElement>) => void
  handleFormDragEnd: (e: DragEvent<HTMLDivElement>) => void
}

const CheckBoxField: React.FC<CheckBoxFieldProps> = ({
  index,
  onDelete,
  onDuplicate,
  onSave,
  values,
  handleFormDragStart,
  handleFormDragOver,
  handleFormDragEnd,
}) => {
  const [edit, setEdit] = useState(false)
  const [isLabel, setIsLabel] = useState(values.isLabel)
  const [labelTitle, setLabelTitle] = useState(values.labelTitle)

  const [checkboxOptions, setCheckboxOptions] = useState(values.checkboxOptions)

  const {inputRef: checkLabelRef, focusInput: checkLabelFocusInput} = useFocus()

  const addOption = () => {
    setCheckboxOptions([...checkboxOptions, {title: 'Option Title', isChecked: false}])
  }

  const onEditOption = (index: number, value: {title: string; isChecked: boolean}) => {
    const updatedData = checkboxOptions.map((option: any, i: any) => {
      if (i === index) {
        return {...option, title: value.title, isChecked: value.isChecked}
      }
      return option
    })
    setCheckboxOptions(updatedData)
  }

  const onSaveClick = () => {
    setEdit(false)
    onSave({values: {isLabel, labelTitle, checkboxOptions, col: values.col}}, index)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      onSaveClick()
    }
  }

  const onResize = () => {
    if (values.col === 12) {
      onSave(
        {
          values: {...values, col: 6},
        },
        index
      )
    } else {
      onSave(
        {
          values: {...values, col: 12},
        },
        index
      )
    }
  }

  useMemo(() => {
    setIsLabel(values.isLabel)
    setLabelTitle(values.labelTitle)
    setCheckboxOptions(values.checkboxOptions)
  }, [values])

  return (
    <div
      className={`col-${values.col || 6}`}
      draggable
      onDragStart={handleFormDragStart}
      onDragOver={handleFormDragOver}
      onDragEnd={handleFormDragEnd}
    >
      {edit ? (
        // Edit View
        <div className='border rounded overflow-hidden'>
          {/* Top Section */}
          <div className='d-flex justify-content-between bg-gray-300 py-3 px-4'>
            <div className='d-flex align-items-center gap-2'>
              <div
                className='bg-dark d-flex align-items-center'
                style={{borderRadius: '3px', padding: '4px'}}
              >
                <CheckBoxIcon height={14} fill='none' stroke='white' />
              </div>

              <p className='m-0 fw-bold fs-7'>Checkbox</p>
            </div>
            <div className='d-flex align-items-center gap-2'>
              <div
                className='d-flex align-items-center gap-1 cursor-pointer'
                onClick={() => onDuplicate(index)}
              >
                <KTIcon iconName='copy' className='fs-6 text-dark' />
                <p className='m-0 fs-8 fw-semibold'>Duplicate</p>
              </div>
              <span
                className='badge badge-primary fs-8 py-0 px-3 fw-semibold cursor-pointer h-25px rounded-1'
                onClick={onSaveClick}
              >
                Save
              </span>
            </div>
          </div>

          {/* Middle Section */}
          <div className='p-4 d-flex flex-column'>
            {isLabel && (
              <div
                className='input-group border border-primary px-3 py-2 w-175px mb-3 gap-3'
                style={{borderRadius: '3px'}}
              >
                <input
                  type='text'
                  ref={checkLabelRef}
                  className='form-control bg-transparent border-0 p-0 text-dark fs-6 fw-normal rounded-0'
                  value={labelTitle}
                  onChange={(e) => setLabelTitle(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <span
                  className='input-group-text bg-transparent border-0 p-0 rounded-0 cursor-pointer'
                  onClick={() => checkLabelFocusInput()}
                >
                  <KTIcon iconName='pencil' className='fs-6 text-primary' />
                </span>
              </div>
            )}
            <div
              className='border border-primary p-3 d-flex flex-column gap-3'
              style={{borderRadius: '3px'}}
            >
              <div className='d-flex align-align-items-center flex-wrap gap-3'>
                {checkboxOptions.map((option: any, index: number) => (
                  <div key={index} className='d-flex align-items-center gap-1'>
                    <input
                      type='checkbox'
                      className='form-check-input h-15px w-15px'
                      style={{borderRadius: '3px'}}
                      checked={option.isChecked}
                      onChange={(e) =>
                        onEditOption(index, {title: option.title, isChecked: e.target.checked})
                      }
                      onKeyDown={handleKeyPress}
                    />
                    <input
                      type='text'
                      id={`checkOption${index}`}
                      className='form-control bg-transparent border-0 p-0 text-dark fs-6 fw-normal rounded-0 align-self-start mw-90px'
                      value={option.title}
                      onChange={(e) =>
                        onEditOption(index, {title: e.target.value, isChecked: option.isChecked})
                      }
                      onKeyDown={handleKeyPress}
                      onBlur={() => {
                        if (option.title.length === 0) {
                          if (index < 3) {
                            checkboxOptions[index].title = 'Option Title'
                            setCheckboxOptions([...checkboxOptions])
                          } else {
                            setCheckboxOptions(
                              checkboxOptions.filter((_: any, i: number) => i !== index)
                            )
                          }
                        }
                      }}
                    />
                    <button
                      className='btn btn-icon btn-bg-light p-0 h-20px w-20px cursor-pointer'
                      onClick={() => document.getElementById(`checkOption${index}`)?.focus()}
                    >
                      <KTIcon iconName='pencil' className='fs-7 text-primary' />
                    </button>
                  </div>
                ))}
                <div
                  className='d-flex align-interpolation gap-1 cursor-pointer'
                  onClick={addOption}
                >
                  <KTIcon iconName='plus-circle' className='fs-3 text-primary' />
                  <p className='text-primary fs-7 fw-normal m-0'>Add Option</p>
                </div>
              </div>
            </div>

            <div className='separator my-3' />

            {/* Bottom Section */}
            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-align-items-center gap-1'>
                <input
                  className='form-check-input h-15px w-15px m-0'
                  style={{borderRadius: '3px'}}
                  type='checkbox'
                  checked={isLabel}
                  onChange={(e) => setIsLabel(e.target.checked)}
                  onKeyDown={handleKeyPress}
                />
                <label className='form-check-label text-dark fs-7 m-0 fw-normal'>Field Label</label>
              </div>
              <button
                className='btn border border-danger text-danger fs-8 btn-sm py-0 px-3 h-25px align-self-end'
                onClick={() => {
                  onDelete(index)
                  setEdit(false)
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      ) : (
        // Main View
        <div
          className='border rounded ps-0 py-5 pe-5 d-flex align-items-center grab'
          style={{margin: '0px 0px'}}
        >
          <Left height={15} className='mx-3' />

          <div className='d-flex flex-column gap-2 w-100'>
            <div
              className={`d-flex justify-content-${isLabel ? 'between' : 'end'} align-items-center`}
            >
              {isLabel && <h6 className='m-0 fw-medium'>{labelTitle}</h6>}
              <div className='d-flex align-items-center gap-2'>
                <button
                  className='btn align-self-end btn-icon btn-bg-light btn-active-color-primary btn-sm h-25px w-25px align-self-end pencil-div'
                  style={{padding: '1px 2px 0px 0px'}}
                  onClick={onResize}
                >
                  <KTIcon iconName='arrow-two-diagonals' className='fs-2 pencil-icon-style' />
                </button>
                <button
                  className='btn align-self-end btn-icon btn-bg-light btn-active-color-primary btn-sm p-0 h-25px w-25px align-self-end pencil-div'
                  onClick={() => {
                    setEdit(true)
                  }}
                >
                  <KTIcon iconName='pencil' className='fs-5 m-1 pencil-icon-style' />
                </button>
              </div>
            </div>
            <div className='d-flex align-align-items-center flex-wrap gap-3'>
              {checkboxOptions.map((option: any, index: number) => (
                <div className='d-flex align-items-center gap-2'>
                  <input
                    type='checkbox'
                    className='form-check-input h-15px w-15px'
                    style={{borderRadius: '3px'}}
                    checked={option.isChecked}
                    onChange={(e) =>
                      onEditOption(index, {title: option.title, isChecked: e.target.checked})
                    }
                    onKeyDown={handleKeyPress}
                  />
                  <label>{option.title}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default CheckBoxField
