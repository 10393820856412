import {SVGProps} from 'react'

const SelectFieldIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 3C1 1.89543 1.89543 1 3 1H10V4H1V3Z' stroke={props.stroke} />
      <path d='M11 1C12.1046 1 13 1.89543 13 3L13 4L10 4L10 1L11 1Z' stroke={props.stroke} />
      <path
        d='M10 11C10 12.1046 9.10457 13 8 13L3 13C1.89543 13 1 12.1046 1 11L1 4L10 4L10 11Z'
        stroke={props.stroke}
      />
      <path d='M3 7H8' stroke={props.stroke} strokeWidth='0.75' strokeLinecap='round' />
      <path d='M3 10H8' stroke={props.stroke} strokeWidth='0.75' strokeLinecap='round' />
    </svg>
  )
}

export default SelectFieldIcon
