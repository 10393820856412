

import { SVGProps } from 'react'

const SavedTempateIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="#3E97FF" />
            <g clip-path="url(#clip0_225_18685)">
                <path opacity="0.3" d="M31.5969 36.7035L25.1449 33.1129C24.7983 32.9198 24.4058 32.8183 24.0063 32.8183C23.6069 32.8183 23.2143 32.9198 22.8677 33.1129L16.4158 36.7035C16.0704 36.8973 15.6787 36.9995 15.28 37C14.8812 37.0005 14.4893 36.8992 14.1434 36.7062C13.7976 36.5132 13.5099 36.2353 13.3093 35.9004C13.1086 35.5654 13.002 35.1851 13 34.7975V17.4719C13.0034 16.0195 13.5993 14.6278 14.6571 13.6019C15.7148 12.5761 17.1481 12 18.6423 12H29.3703C30.8624 12.0032 32.2924 12.5808 33.3474 13.6063C34.4025 14.6317 34.9967 16.0217 35 17.4719V34.7975C34.9968 35.1833 34.8899 35.5616 34.6899 35.8949C34.4899 36.2281 34.2037 36.5048 33.8598 36.6974C33.5159 36.89 33.1261 36.9919 32.7292 36.993C32.3322 36.994 31.9419 36.8942 31.5969 36.7035Z" fill="#EEF6FF" />
                <path d="M22.8415 26.3491C22.6 26.3483 22.3684 26.2556 22.1963 26.0908L20.2101 24.2587C20.0324 24.0858 19.9326 23.8514 19.9326 23.607C19.9326 23.3626 20.0324 23.1282 20.2101 22.9553C20.388 22.7826 20.6292 22.6855 20.8806 22.6855C21.132 22.6855 21.3732 22.7826 21.5511 22.9553L22.8795 24.1849L27.3073 19.918C27.3876 19.8042 27.4932 19.7094 27.6163 19.6405C27.7394 19.5717 27.8768 19.5305 28.0184 19.5202C28.1601 19.5098 28.3023 19.5305 28.4346 19.5807C28.5669 19.6308 28.6859 19.7093 28.7829 19.8101C28.8798 19.911 28.9523 20.0317 28.9948 20.1634C29.0374 20.2951 29.049 20.4344 29.0288 20.571C29.0086 20.7076 28.9571 20.8381 28.8781 20.9528C28.7991 21.0675 28.6946 21.1635 28.5724 21.2338L23.512 26.1523C23.3231 26.2998 23.0828 26.3703 22.8415 26.3491Z" fill="#EEF6FF" />
            </g>
            <defs>
                <clipPath id="clip0_225_18685">
                    <rect width="22" height="25" fill="white" transform="translate(13 12)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SavedTempateIcon

