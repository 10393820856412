import {PageLink} from '../theme/layout/core'
import TextAreaIcon from '../app/assets/icons/TextAreaIcon'
import DateFieldIcon from '../app/assets/icons/DateFieldIcon'
import NumberFieldIcon from '../app/assets/icons/NumberFieldIcon'
import SelectFieldIcon from '../app/assets/icons/SelectFieldIcon'
import CheckBoxIcon from '../app/assets/icons/CheckBoxIcon'
import RadioButtonIcon from '../app/assets/icons/RadioButtonIcon'
import TextFieldIcon from '../app/assets/icons/TextFieldIcon'
import SectionHeadingIcon from '../app/assets/icons/SectionHeadingIcon'
import SectionDivider from '../app/assets/icons/SectionDivider'

export const emailRegEx = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
export const upperCaseRegEx = /[A-Z]/
export const lowerCaseRegEx = /[a-z]/
export const numberRegEx = /[0-9]/
export const specialCharRegEx = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/
export const passwordLength = 8
export const noSpecialCharacters = /^[a-zA-Z0-9]*$/

export const ROLES = {
  ADMIN: 'admin',
  SUPERVISING_ATTORNEY: 'supervising_attorney',
  ATTORNEY: 'attorney',
}

export const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Home',
    path: '/view-attorney',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Home',
    path: '/template-builder',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Home / Attorney Manager',
    path: '/profile',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Home',
    path: '/client-matters',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Home',
    path: '/client-templates',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Home',
    path: '/report',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Home',
    path: '/archive-report',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Home / Report Archive',
    path: '/archive-report-detail',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Home',
    path: '/active-report',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Home',
    path: '/one-time-report',
    isSeparator: false,
    isActive: false,
  },
]

export const accountRole = [
  {
    _id: 'attorney',
    name: 'Attorney',
  },
  {
    _id: 'supervising_attorney',
    name: 'Supervising Attorney',
  },
  {
    _id: 'admin',
    name: 'Admin',
  },
]

export const state = [
  {
    _id: 'alaska',
    name: 'Alaska',
  },
  {
    _id: 'california',
    name: 'California',
  },
  {
    _id: 'colorado',
    name: 'Colorado',
  },
]

export const clients = [
  {
    value: '64bfb0ea2036c969fee46813',
    title: 'AllState',
    email: 'support@allstate.com',
  },
  {
    value: '64bfb1152036c969fee46814',
    title: 'Amazon',
    email: 'support@amazon.com',
  },
  {
    value: 'fedex',
    title: 'FedEx',
    email: 'support@fedex.com',
  },
  {
    value: 'geico',
    title: 'Geico',
    email: 'support@geico.com',
  },
  {
    value: 'libertyMutual',
    title: 'Liberty Mutual',
    email: 'support@libertyMutual.com',
  },
  {
    value: 'progressive',
    title: 'Progressive',
    email: 'support@progressive.com',
  },
  {
    value: '',
    title: 'Reset Filter',
  },
]

export const date = [
  {
    _id: 'today',
    name: 'Today',
  },
  {
    _id: 'yesterday',
    name: 'Yesterday',
  },
  {
    _id: 'last7days',
    name: 'Last 7 Days',
  },
  {
    _id: 'last30days',
    name: 'Last 30 Days',
  },
  {
    _id: 'thismonth',
    name: 'This Month',
  },
  {
    _id: 'lastmonth',
    name: 'Last Month',
  },
  {
    _id: '',
    name: 'Reset Filter',
  },
]

export const reportStatus = [
  {
    _id: 'sent',
    name: 'Sent',
  },
  {
    _id: 'approved',
    name: 'Approved',
  },
  {
    _id: 'not_started',
    name: 'Not Started',
  },
  {
    _id: 'in_progress',
    name: 'In Progress',
  },
  {
    _id: 'in_review',
    name: 'In Review',
  },
  {
    _id: 'requires_revision',
    name: 'Requires Revision',
  },
  {
    _id: '',
    name: 'Reset Filter',
  },
]

export const reportFrequency = [
  {
    value: '30days',
    title: '30 Days',
  },
  {
    value: '60days',
    title: '60 Days',
  },
  {
    value: '90days',
    title: '90 Days',
  },
  {
    value: '',
    title: 'Reset Filter',
  },
]

export const matters = [
  {
    value: 'id',
    title: 'Sierra v. FedEx',
  },
  {
    value: 'id',
    title: 'Freeman v. FedEx',
  },
  {
    value: 'id',
    title: 'Gill v. FedEx',
  },
  {
    value: 'id',
    title: 'Larson v. FedEx',
  },
]

export const reportInputElements = [
  {
    title: 'Text Field',
    Icon: TextFieldIcon,
    label: 'Text Field Label',
    type: 'text-field',
    defaultValue: 'Enter your text data',
    isLabel: true,
    isText: true,
    isEmail: false,
    isPhone: false,
    value: '',
    col: 12,
  },
  {
    title: 'Number Field',
    Icon: NumberFieldIcon,
    type: 'number-field',
    isLabel: true,
    label: 'Number Field',
    placeHolder: 'Insert Number',
    value: 0,
    col: 12,
  },
  {
    title: 'Text Area',
    Icon: TextAreaIcon,
    type: 'text-area',
    values: {
      isLabel: true,
      isReadOnly: true,
      isEditable: false,
      labelTitle: 'Text Area Label',
      text: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            attrs: {
              textAlign: 'left',
            },
            content: [
              {
                type: 'text',
                text: 'Dommy lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id mi at justo convallis feugiat in id justo. Fusce at mi dapibus, scelerisque velit in, posuere dolor. Nullam at turpis nec purus consectetur euismod. Ut tincidunt consectetur dui, eget condimentum justo. Integer eu imperdiet dui, quis mattis purus.',
              },
            ],
          },
        ],
      },
      col: 12,
    },
  },
  {
    title: 'Date Field',
    Icon: DateFieldIcon,
    type: 'date-field',
    values: {
      isLabel: true,
      labelTitle: 'Date Field Label',
      datePlaceholder: 'Select Date',
      inputType: 'text',
      date: '',
      col: 12,
    },
  },
  {
    title: 'Select Field',
    Icon: SelectFieldIcon,
    type: 'select-field',
    values: {
      isLabel: true,
      labelTitle: 'Select Field Label',
      selectedOption: 'Select Option',
      selectOptions: [{title: 'Option 1'}, {title: 'Option 2'}, {title: 'Option 3'}],
      col: 12,
    },
  },
  {
    title: 'Checkbox',
    Icon: CheckBoxIcon,
    type: 'check-box',
    values: {
      isLabel: true,
      labelTitle: 'Checkbox Label',
      checkboxOptions: [
        {title: 'Option One', isChecked: false},
        {title: 'Option Two', isChecked: false},
        {title: 'Option Three', isChecked: false},
      ],
      col: 12,
    },
  },
  {
    title: 'Radio Button',
    Icon: RadioButtonIcon,
    type: 'radio-button',
    label: 'Radio Button Field',
    isLabel: true,
    options: [
      {optionName: 'Yes', isChecked: true},
      {optionName: 'No', isChecked: false},
    ],
    col: 12,
  },
]

export const reportLayoutElements = [
  {
    title: 'Section Heading',
    Icon: SectionHeadingIcon,
    type: 'section-heading',
    label: 'Header Text ',
  },
  {
    title: 'Section Divider',
    Icon: SectionDivider,
    type: 'section-divider',
    isDashed: false,
    isSolid: true,
  },
]

export const fakeAttorneyDataHead = [
  'ATTORNEY',
  'EMAIL',
  'BAR NUMBER',
  'STATE',
  'ROLE',
  'DATE ADDED ',
]

export const fakeTemplateDataHead = ['REPORT NAME', 'LOCATION', 'DATE ADDED', 'ACTIONS']

export const fakeArchiveReportDataHead = [
  'REPORT NAME',
  'MATTER',
  'CLIENT',
  'ATTORNEY',
  'S. ATTORNEY',
  'CLAIM NUMBER',
  'DUE DATE',
  'OPEN',
]

export const fakeActiveReportDataHead = [
  {label: 'REPORT NAME', accessor: 'report_name', sortable: true},
  {label: 'STATUS', accessor: 'status', sortable: true},
  {label: 'MATTER', accessor: 'matter_name', sortable: true},
  {label: 'CLIENT', accessor: 'client_name', sortable: true},
  {label: 'ATTORNEY', accessor: 'attorney_name', sortable: true},
  {label: 'CLAIM NUMBER', accessor: 'claim_number', sortable: true},
  {label: 'DUE DATE', accessor: 'due_date', sortable: true},
  {label: 'OPEN', accessor: 'open', sortable: false},
]

export const fakeOneTimeReportDataHead = ['REPORT NAME', 'LOCATION', 'DATE ADDED', 'ACTIONS']

export const fakeDashboardDataHead = [
  'REPORT NAME',
  'STATUS',
  'MATTER',
  'CLIENT',
  'DUE DATE',
  'OPEN',
]

export const states = [
  {label: 'Alabama', value: 'Alabama'},
  {label: 'Alaska', value: 'Alaska'},
  {label: 'Arizona', value: 'Arizona'},
  {label: 'Arkansas', value: 'Arkansas'},
  {label: 'California', value: 'California'},
  {label: 'Colorado', value: 'Colorado'},
  {label: 'Connecticut', value: 'Connecticut'},
  {label: 'Delaware', value: 'Delaware'},
  {label: 'Florida', value: 'Florida'},
  {label: 'Georgia', value: 'Georgia'},
  {label: 'Hawaii', value: 'Hawaii'},
  {label: 'Idaho', value: 'Idaho'},
  {label: 'Illinois', value: 'Illinois'},
  {label: 'Indiana', value: 'Indiana'},
  {label: 'Iowa', value: 'Iowa'},
  {label: 'Kansas', value: 'Kansas'},
  {label: 'Kentucky', value: 'Kentucky'},
  {label: 'Louisiana', value: 'Louisiana'},
  {label: 'Maine', value: 'Maine'},
  {label: 'Maryland', value: 'Maryland'},
  {label: 'Massachusetts', value: 'Massachusetts'},
  {label: 'Michigan', value: 'Michigan'},
  {label: 'Minnesota', value: 'Minnesota'},
  {label: 'Mississippi', value: 'Mississippi'},
  {label: 'Missouri', value: 'Missouri'},
  {label: 'Montana', value: 'Montana'},
  {label: 'Nebraska', value: 'Nebraska'},
  {label: 'Nevada', value: 'Nevada'},
  {label: 'New Hampshire', value: 'New Hampshire'},
  {label: 'New Jersey', value: 'New Jersey'},
  {label: 'New Mexico', value: 'New Mexico'},
  {label: 'New York', value: 'New York'},
  {label: 'North Carolina', value: 'North Carolina'},
  {label: 'North Dakota', value: 'North Dakota'},
  {label: 'Ohio', value: 'Ohio'},
  {label: 'Oklahoma', value: 'Oklahoma'},
  {label: 'Oregon', value: 'Oregon'},
  {label: 'Pennsylvania', value: 'Pennsylvania'},
  {label: 'Rhode Island', value: 'Rhode Island'},
  {label: 'South Carolina', value: 'South Carolina'},
  {label: 'South Dakota', value: 'South Dakota'},
  {label: 'Tennessee', value: 'Tennessee'},
  {label: 'Texas', value: 'Texas'},
  {label: 'Utah', value: 'Utah'},
  {label: 'Vermont', value: 'Vermont'},
  {label: 'Virginia', value: 'Virginia'},
  {label: 'Washington', value: 'Washington'},
  {label: 'West Virginia', value: 'West Virginia'},
  {label: 'Wisconsin', value: 'Wisconsin'},
  {label: 'Wyoming', value: 'Wyoming'},
]
