import React from 'react'

interface ButtonProps {
  text: string
  className?: string
  loading?: boolean
  props?: object
}

const Button: React.FC<ButtonProps> = ({text, className, loading, props}) => {
  return (
    <button className={`btn btn-primary ${className}`} {...props}>
      <span className='indicator-label'>
        {!loading ? (
          text
        ) : (
          <span className='indicator-label'>
            Please wait...
            <span className='spinner-border spinner-border-sm ms-2' />
          </span>
        )}
      </span>
    </button>
  )
}

export default Button
