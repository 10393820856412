import {useMemo} from 'react'
import withAuth from '../hoc/withAuth'
import {AuthPage} from '../app/modules/auth'
import {Navigate} from 'react-router-dom'
import withUser from '../hoc/withUser'
import {useSelector} from 'react-redux'
import {DashboardWrapper} from '../app/pages/dashboard/DashboardWrapper'
import {ROLES} from '../utils/constants'
import Matters from '../app/pages/clientMatters'
import {Profile} from '../app/pages/Profile'
import {TemplateBuilder} from '../app/pages/templateBuilder'
import {ViewAttorney} from '../app/pages/attorney-manager/ViewAttorney'
import {ForgotPassword} from '../app/modules/auth/components/ForgotPassword'
import {CreateNewPassword} from '../app/modules/auth/components/CreateNewPassword'
import ClientTemplates from '../app/pages/clientTemplates'
import {CreateReport} from '../app/pages/reports'
import ArchiveReports from '../app/pages/archive-report'
import {ArchiveReportDetail} from '../app/pages/archive-report/archive-report-detail'
import ActiveReports from '../app/pages/active-report'
import {ActiveReportDetail} from '../app/pages/active-report/active-report-detail'
import {OneTimeReport} from '../app/pages/one-time-report-template'

const Auth = {
  Login: withAuth(AuthPage, Navigate),
  ForgotPassword: withAuth(ForgotPassword, Navigate),
  ResetPassword: withAuth(CreateNewPassword, Navigate),
  CreateNewPassword: withAuth(CreateNewPassword, Navigate),
}

const User = {
  Dashboard: withUser(DashboardWrapper, Navigate),
  Profile: withUser(Profile, Navigate),
  Matters: withUser(Matters, Navigate),
  templateBuilder: withUser(TemplateBuilder, Navigate),
  ViewAttorney: withUser(ViewAttorney, Navigate),
  Report: withUser(CreateReport, Navigate),
  ClientTemplates: withUser(ClientTemplates, Navigate),
  ArchiveReport: withUser(ArchiveReports, Navigate),
  ArchiveReportDetail: withUser(ArchiveReportDetail, Navigate),
  ActiveReport: withUser(ActiveReports, Navigate),
  ActiveReportDetail: withUser(ActiveReportDetail, Navigate),
  OneTimeReport: withUser(OneTimeReport, Navigate),
}

export const useRoutes = () => {
  const auth = useSelector(({auth}) => auth?.user?.data)

  const routes = useMemo(
    () => [
      {
        id: 'login',
        path: '/login',
        name: 'Login',
        isAuth: true,
        element: <Auth.Login replace {...{to: '/dashboard', token: auth?.result?.token}} />,
        exact: true,
      },
      {
        id: 'default_login',
        path: '/',
        name: 'Login',
        isAuth: true,
        element: <Auth.Login replace {...{to: '/dashboard', token: auth?.result?.token}} />,
        exact: true,
      },
      {
        id: 'forgot_password',
        path: '/forgot-password',
        name: 'Forgot Password',
        isAuth: true,
        element: (
          <Auth.ForgotPassword replace {...{to: '/dashboard', token: auth?.result?.token}} />
        ),
        exact: true,
      },
      {
        id: 'create_new_password',
        path: 'auth/reset-password',
        name: 'Create Password',
        isAuth: true,
        element: (
          <Auth.CreateNewPassword replace {...{to: '/dashboard', token: auth?.result?.token}} />
        ),
        exact: true,
      },
      {
        id: 'forgot_password',
        path: '/forgot-password',
        name: 'Forgot Password',
        isAuth: true,
        element: (
          <Auth.ForgotPassword replace {...{to: '/dashboard', token: auth?.result?.token}} />
        ),
        exact: true,
      },
      {
        id: 'create_new_password',
        path: 'auth/reset-password',
        name: 'Create Password',
        isAuth: true,
        element: (
          <Auth.CreateNewPassword replace {...{to: '/dashboard', token: auth?.result?.token}} />
        ),
        exact: true,
        sideOne: true,
      },
      {
        id: 'forgot-password',
        path: '/forgot-password',
        name: 'ResetPassword',
        isAuth: true,
        element: (
          <Auth.ForgotPassword replace {...{to: '/reset-password', token: auth?.result?.token}} />
        ),
        exact: true,
      },
      {
        id: 'reset_password',
        path: '/reset-password',
        name: 'ResetPassword',
        isAuth: true,
        element: (
          <Auth.ResetPassword replace {...{to: '/reset-password', token: auth?.result?.token}} />
        ),
        exact: true,
      },
      {
        id: 'dashboard',
        path: '/dashboard',
        name: 'Login',
        isAllAccessible: true,
        roles: Object.values(ROLES),
        element: <User.Dashboard replace {...{to: '/login', token: auth?.result?.token}} />,
        exact: true,
      },
      {
        id: 'report_builder',
        path: '/template-builder',
        name: 'Template Builder',
        roles: [ROLES.ADMIN],
        element: <User.templateBuilder replace {...{to: '/login', token: auth?.result?.token}} />,
        exact: true,
        icon: 'add-files',
        sideOne: true,
      },
      {
        id: 'report_builder',
        path: '/template-builder/:templateId',
        name: 'Report Builder',
        roles: [ROLES.ADMIN],
        element: <User.templateBuilder replace {...{to: '/login', token: auth?.result?.token}} />,
        exact: true,
        icon: 'add-files',
        isAllAccessible: true,
      },
      {
        id: 'client_matter',
        path: '/client-matters',
        name: 'Matters',
        roles: [ROLES.ADMIN],
        element: <User.Matters replace {...{to: '/login', token: auth?.result?.token}} />,
        exact: true,
        icon: 'some-files',
        sideOne: true,
      },
      {
        id: 'client_templates',
        path: '/client-templates',
        name: 'Templates',
        roles: [ROLES.ADMIN, ROLES.SUPERVISING_ATTORNEY],
        element: <User.ClientTemplates replace {...{to: '/login', token: auth?.result?.token}} />,
        exact: true,
        icon: 'save-2',
        sideOne: true,
      },
      {
        id: 'report',
        path: '/report/:reportId',
        name: 'Create Report',
        roles: Object.values(ROLES),
        element: <User.Report replace {...{to: '/login', token: auth?.result?.token}} />,
        exact: true,
        icon: 'add-files',
        isAllAccessible: true,
      },
      {
        id: 'archiveReport',
        path: '/archive-report',
        name: 'Archive',
        roles: [ROLES.ADMIN, ROLES.SUPERVISING_ATTORNEY],
        element: <User.ArchiveReport replace {...{to: '/login', token: auth?.result?.token}} />,
        exact: true,
        icon: 'archive',
        sideOne: true,
      },
      {
        id: 'oneTimeReport',
        path: '/one-time-report',
        name: 'One Time Reports',
        roles: [ROLES.ATTORNEY],
        element: <User.OneTimeReport replace {...{to: '/login', token: auth?.result?.token}} />,
        exact: true,
        icon: 'archive',
        sideOne: true,
      },
      {
        id: 'activeReport',
        path: '/active-report',
        name: 'View Active Report',
        roles: [ROLES.ADMIN, ROLES.ATTORNEY, ROLES.SUPERVISING_ATTORNEY],
        element: <User.ActiveReport replace {...{to: '/login', token: auth?.result?.token}} />,
        exact: true,
        icon: 'archive',
        sideOne: true,
      },
      {
        id: 'archiveReportDetail',
        path: '/archive-report-detail/:reportId',
        name: 'Report Archive',
        roles: [ROLES.ADMIN, ROLES.SUPERVISING_ATTORNEY],
        element: (
          <User.ArchiveReportDetail replace {...{to: '/login', token: auth?.result?.token}} />
        ),
        exact: true,
        icon: 'archive',
        isAllAccessible: true,
      },
      {
        id: 'activeReportDetail',
        path: '/active-report-detail/:reportId',
        name: 'Report Active',
        roles: [ROLES.ADMIN, ROLES.ATTORNEY, ROLES.SUPERVISING_ATTORNEY],
        element: (
          <User.ActiveReportDetail replace {...{to: '/login', token: auth?.result?.token}} />
        ),
        exact: true,
        icon: 'archive',
        isAllAccessible: true,
      },
      {
        id: 'view-attorney',
        path: '/view-attorney?create-attorney=true',
        name: 'Create Attorney',
        roles: [ROLES.ADMIN],
        element: <User.ViewAttorney replace {...{to: '/login', token: auth?.result?.token}} />,
        exact: true,
        icon: 'profile-circle',
        sideOne: false,
      },
      {
        id: 'view-attorney',
        path: '/view-attorney',
        name: 'View Attorney',
        roles: [ROLES.ADMIN, ROLES.SUPERVISING_ATTORNEY],
        element: <User.ViewAttorney replace {...{to: '/login', token: auth?.result?.token}} />,
        exact: true,
        icon: 'people',
        sideOne: false,
      },
      {
        id: 'profile',
        path: '/profile',
        name: 'Profile',
        roles: Object.values(ROLES),
        element: <User.Profile replace {...{to: '/login', token: auth?.result?.token}} />,
        exact: true,
        icon: 'profile-circle',
        isAllAccessible: true,
      },
    ],
    [auth]
  )

  const getUserRole = (roles: string[] | undefined, role: string | undefined) =>
    roles?.some((val) => val === role)

  const authRoutes = useMemo(() => routes.filter(({isAuth}) => isAuth), [routes])
  const roleWiseRoutes = useMemo(
    () => routes.filter(({roles}) => getUserRole(roles, auth?.result?.role)),
    [auth, routes]
  )
  const sidebarRoutes = useMemo(
    () => roleWiseRoutes?.filter((val) => !val.isAllAccessible && val.sideOne),
    [roleWiseRoutes]
  )
  const sidebarTwoRoutes = useMemo(
    () => roleWiseRoutes?.filter((val) => !val.sideOne && !val?.isAllAccessible),
    [roleWiseRoutes]
  )
  return {routes: [...authRoutes, ...roleWiseRoutes], auth, sidebarRoutes, sidebarTwoRoutes}
}
