import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../theme/helpers'
import Button from '../../../components/Button'

interface ReportSentModalProp {
  open: boolean
  setOpen: Function
}
const ReportSentModal = ({open, setOpen}: ReportSentModalProp) => {
  const onClose = () => {
    setOpen(false)
  }

  return (
    <Modal show={open} centered onHide={onClose}>
      <div
        className='modal-content gap-8 rounded-3 d-flex justify-content-center'
        style={{padding: '30px'}}
      >
        <div className='modal-header border-0 p-0 flex-column align-items-center gap-6'>
          <KTIcon
            iconName='folder-added'
            className='fs-2hx text-light bg-primary rounded-circle p-4'
          />
          <div className='d-flex flex-column gap-3 align-items-center'>
            <h1 className='m-0'>Report Sent!</h1>
            <p className='m-0 text-gray-600 text-center'>
              The report was sent successfully to the <br /> client, please return to the dashboard.
            </p>
          </div>
          <Button text='Continue' className='btn-sm' props={{type: 'button', onClick: onClose}} />
        </div>
      </div>
    </Modal>
  )
}
export {ReportSentModal}
