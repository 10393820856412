import {Link} from 'react-router-dom'
import {OverlayTrigger} from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useDispatch} from 'react-redux'
import {resetLoginSlice} from '../../../../app/redux/reducer/LoginSlice'
import {useState} from 'react'
import {Logout} from '../../../../app/modules/auth'
const AsideFooter = ({setAsideIsActive}: any) => {
  const [logOutModal, setLogOutModal] = useState(false)

  const dispatch = useDispatch()

  const logOut = () => {
    dispatch(resetLoginSlice())
  }
  return (
    <div
      className='aside-footer d-flex flex-column align-items-center flex-column-auto'
      id='kt_aside_footer'
    >
      {/* begin::User */}
      {/* id='kt_header_user_menu_toggle' */}
      <Logout show={logOutModal} handleLogout={logOut} handleClose={() => setLogOutModal(false)} />
      <div className='d-flex flex-column align-items-center mb-10 flex-col'>
        {/* begin::Menu wrapper */}

        <div
          className='cursor-pointer symbol symbol-40px'
          data-kt-menu-trigger='click'
          data-kt-menu-overflow='false'
          data-kt-menu-placement='top-start'
          title='User profile'
          onClick={() => {
            // document.body.setAttribute('data-kt-aside-minimize', 'on')
            setAsideIsActive('false')
          }}
        >
          <Link className='cursor-pointer symbol symbol-40px' to={'/profile'} replace>
            <OverlayTrigger
              key={'Profile-tooltip'}
              placement={'right'}
              overlay={
                <Tooltip id={`tooltip-Profile`} style={{position: 'fixed'}}>
                  Attorney Profile
                </Tooltip>
              }
            >
              <div className='d-flex border border-2 border-primary px-1 pt-2 rounded-3 pb-0'>
                <KTIcon iconName='user' className='fs-2 text-primary m-0' />
              </div>
            </OverlayTrigger>
            {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='avatar' /> */}
          </Link>
        </div>
        <div className='mt-8'>
          <OverlayTrigger
            key={'Log-out-tooltip'}
            placement={'right'}
            overlay={
              <Tooltip id={`tooltip-log-out`} style={{position: 'fixed'}}>
                Log out of your account
              </Tooltip>
            }
          >
            <button className='bg-transparent border-0' onClick={() => setLogOutModal(true)}>
              <KTIcon iconType='outline' iconName='exit-right' className='fs-1 text-white' />
            </button>
          </OverlayTrigger>
        </div>
        {/* end::Menu wrapper */}

        {/* <HeaderUserMenu /> */}
      </div>
      {/* end::User */}
    </div>
  )
}

export {AsideFooter}
