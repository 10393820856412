import {SVGProps} from 'react'

const TextFieldIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg  viewBox='0 0 14 14' {...props} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 1.99927L11 1.99927C12.1046 1.99927 13 2.8947 13 3.99927L13 9.99927C13 11.1038 12.1046 11.9993 11 11.9993L10 11.9993M8.5 1.99927L3 1.99927C1.89543 1.99927 1 2.8947 1 3.99927L1 9.99927C1 11.1038 1.89543 11.9993 3 11.9993L8.5 11.9993'
        stroke={props.stroke}
      />
      <path d='M10 1V13' stroke={props.stroke} strokeLinecap='round' />
      <path d='M5 5.00073L5 9.00073' stroke={props.stroke} strokeLinecap='round' />
    </svg>
  )
}

export default TextFieldIcon
