import {SVGProps} from 'react'

const CheckBoxIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'>
      <rect x='1' y='1' width='12' height='12' rx='2' stroke={props.stroke} />
      <path
        d='M5 7.00073L6.62162 9.00073L10 5.00073'
        stroke={props.stroke}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CheckBoxIcon
