const NumberPdfElement = ({elementData}: any) => {
  return (
    <div style={{gridColumn: `span ${elementData.col || 4}`}}>
      <div>
        {elementData.isLabel && (
          <p style={{fontWeight: 'bold', margin: 0}}>
            {elementData?.label || 'Example Number Label'}
          </p>
        )}
        <p>{elementData.value}</p>
      </div>
    </div>
  )
}
export {NumberPdfElement}
