import {Button, Modal} from 'react-bootstrap'

type LogOutProps = {
  show: boolean
  handleLogout: () => void
  handleClose: () => void
}

export function Logout({show, handleLogout, handleClose}: LogOutProps) {
  return (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Log Out</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you would like to logout?</Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={handleLogout}>
          Yes
        </Button>
        <Button variant='secondary' onClick={handleClose}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
