import React from 'react'

const DividerPdfElement = ({elementData}: any) => {
  return (
    <div style={{gridColumn: 'span 12'}}>
      {elementData.isDashed ? (
        <div
          style={{
            border: '1px dashed black',
          }}
        />
      ) : (
        <div
          style={{
            border: '1px solid black',
          }}
        />
      )}
    </div>
  )
}
export {DividerPdfElement}
