import React from 'react'

const SectionDividerElement = ({elementData}: any) => {
  return (
    <div className='col-12'>
      <div
        className={`separator border border-1 border-dark ${
          elementData.isDashed && 'border-dashed'
        }`}
      />
    </div>
  )
}
export {SectionDividerElement}
