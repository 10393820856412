import React, {useMemo, useState} from 'react'
interface RadioElementProps {
  elementData: any
  onChange?: any
}

const RadioButtonElement = ({elementData, onChange}: RadioElementProps) => {
  const [option, setOption] = useState([...elementData?.options])

  const onSelectRadio = (item: any, index: number, isChecked: boolean) => {
    const updatedOptions = option.map((optionsItem: any, indexOp: number) => {
      if (indexOp === index) {
        return {...optionsItem, isChecked: isChecked}
      } else {
        return {...optionsItem, isChecked: false}
      }
    })
    setOption(updatedOptions)
    onChange(updatedOptions)
  }

  useMemo(() => {
    setOption(elementData?.options)
  }, [elementData?.options])

  return (
    <div className={`col-${elementData.col || 6}`}>
      {elementData.isLabel && <p className='fs-7 fw-bold mt-0 mb-1'>{elementData.label}</p>}

      <div className='w-100 d-flex  align-items-center my-1'>
        {option.length > 0 &&
          option.map((option: any, index: number) => {
            return (
              <div className='d-flex align-items-center me-1'>
                <input
                  type='radio'
                  checked={option?.isChecked}
                  onChange={(e: any) => onSelectRadio(option, index, e.target.checked)}
                />
                <span className='fs-7 mx-1 fw-medium'>{option.optionName}</span>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export {RadioButtonElement}
