import React, {DragEvent, useMemo, useState} from 'react'
import {KTIcon} from '../../../../../theme/helpers'
import SectionDivider from '../../../../assets/icons/SectionDivider'
import Left from '../../../../assets/icons/Left'

interface SectionDividerProps {
  index: number
  onDelete: (param: number) => void
  onDuplicate: (param: number) => void
  handleFormDragStart: (e: DragEvent<HTMLDivElement>) => void
  handleFormDragOver: (e: DragEvent<HTMLDivElement>) => void
  handleFormDragEnd: (e: DragEvent<HTMLDivElement>) => void
  onSave: (param: any) => void
  elementData: any
}

const SectionDevider: React.FC<SectionDividerProps> = ({
  index,
  onDelete,
  onDuplicate,
  handleFormDragStart,
  handleFormDragOver,
  handleFormDragEnd,
  elementData,
  onSave,
}) => {
  const [edit, setEdit] = useState(false)
  const [isDashed, setIsDashed] = useState(elementData.isDashed)

  const handleSave = () => {
    let param = {
      isDashed: isDashed,
      isSolid: true,
    }
    onSave(param)
    setEdit(false)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleSave()
    }
  }

  useMemo(() => {
    setIsDashed(elementData.isDashed)
  }, [elementData])
  return (
    <div
      className='col-12'
      draggable
      onDragStart={handleFormDragStart}
      onDragOver={handleFormDragOver}
      onDragEnd={handleFormDragEnd}
    >
      {edit ? (
        // Edit View
        <div className='border rounded overflow-hidden'>
          <div className='d-flex justify-content-between bg-gray-300 py-3 px-4'>
            <div className='d-flex align-items-center gap-2'>
              <div
                className='bg-dark d-flex align-items-center'
                style={{borderRadius: '3px', padding: '3px'}}
              >
                <SectionDivider height={16} fill='none' stroke='white' />
              </div>

              <p className='m-0 fw-bold fs-7'>Section Divider</p>
            </div>
            <div className='d-flex align-items-center gap-2'>
              <div
                className='d-flex align-items-center gap-1 cursor-pointer'
                onClick={() => onDuplicate(index)}
              >
                <KTIcon iconName='copy' className='fs-6 text-dark' />
                <p className='m-0 fs-8 fw-semibold'>Duplicate</p>
              </div>
              <span
                className='badge badge-primary fs-8 py-0 px-3 fw-semibold cursor-pointer h-25px rounded-1'
                onClick={() => handleSave()}
              >
                Save
              </span>
            </div>
          </div>
          <div className='p-4 d-flex flex-column'>
            <div
              className={`separator border border-1 border-dark  ${isDashed && 'border-dashed'}`}
            />

            <div className='separator my-3' />

            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-align-items-center gap-1'>
                <input
                  className='form-check-input h-15px w-15px m-0'
                  style={{borderRadius: '3px'}}
                  type='checkbox'
                  checked={isDashed}
                  onChange={(e) => setIsDashed(e.target.checked)}
                  onKeyDown={handleKeyPress}
                />
                <label className='form-check-label text-dark fs-7 m-0 fw-normal'>Dashed Line</label>
              </div>
              <button
                className='btn border border-danger text-danger fs-8 btn-sm py-0 px-3 h-25px align-self-end'
                onClick={() => {
                  onDelete(index)
                  setEdit(false)
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      ) : (
        // Main View
        <div className='border rounded overflow-hidden ps-0 py-5 pe-5 d-flex align-items-center'>
          <Left height={15} className='mx-3' />
          <div className='d-flex flex-column gap-2 w-100'>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm p-0 h-25px w-25px align-self-end pencil-div'
              onClick={() => setEdit(true)}
            >
              <KTIcon iconName='pencil' className='fs-5 pencil-icon-style m-1' />
            </button>
            <div
              className={`separator border border-1 border-dark ${isDashed && 'border-dashed'}`}
            />
          </div>
        </div>
      )}
    </div>
  )
}
export default SectionDevider
