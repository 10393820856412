import React from 'react'
interface NumberFieldProps {
  elementData: any
  label?: string
  onChange?: any
}
const NumberFieldElement = ({elementData, label, onChange}: NumberFieldProps) => {
  return (
    <div className={`col-${elementData.col || 4}`}>
      <div className='d-flex flex-column'>
        {elementData.isLabel && (
          <p className='fs-7 fw-bold mt-0 mb-1'>{elementData?.label || 'Example Number Label'}</p>
        )}

        <div className='input-group input-group-sm'>
          <input
            type='number'
            className='form-control'
            placeholder='Insert Number'
            min='0'
            value={elementData.value}
            onChange={onChange}
            step='1'
          />
        </div>
      </div>
    </div>
  )
}
export {NumberFieldElement}
