import React, {DragEvent, useMemo, useRef, useState} from 'react'
import {KTIcon} from '../../../../../theme/helpers'
import SelectFieldIcon from '../../../../assets/icons/SelectFieldIcon'
import Left from '../../../../assets/icons/Left'
import {useFocus} from '../../../../../hooks/useFocus'

interface SelectFieldProps {
  index: number
  onDelete: (param: number) => void
  onDuplicate: (param: number) => void
  onSave: (data: any, index: number) => void
  values: any
  handleFormDragStart: (e: DragEvent<HTMLDivElement>) => void
  handleFormDragOver: (e: DragEvent<HTMLDivElement>) => void
  handleFormDragEnd: (e: DragEvent<HTMLDivElement>) => void
}

export const SelectField: React.FC<SelectFieldProps> = ({
  index,
  onDelete,
  onDuplicate,
  onSave,
  values,
  handleFormDragStart,
  handleFormDragOver,
  handleFormDragEnd,
}) => {
  const [edit, setEdit] = useState(false)
  const [isLabel, setIsLabel] = useState(values.isLabel)
  const [labelTitle, setLabelTitle] = useState(values.labelTitle)

  const [selectOpen, setSelectOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(values.selectedOption)
  const [selectOptions, setSelectOptions] = useState(values.selectOptions)

  const optionRef = useRef<HTMLInputElement | any>(null)
  const {inputRef: selectFieldRef, focusInput: selectFieldFocusInput} = useFocus()
  const {inputRef: optionFieldRef, focusInput: optionFieldFocusInput} = useFocus()

  const onOptionSelect = (option: {title: string}) => {
    setSelectedOption(option.title)
    setSelectOpen(false)
  }

  const addOption = () => {
    setSelectOptions([...selectOptions, {title: 'Option Title'}])
  }

  const removeOption = (index: number) => {
    const updatedData = selectOptions.filter((_: any, i: number) => i !== index)
    // selectOptions.splice(index, 1)
    setSelectOptions(updatedData)
  }

  const onEditOption = (index: number, value: string) => {
    const updatedData = selectOptions.map((title: any, i: number) => {
      if (i === index) {
        return {...title, title: value}
      }
      return title
    })
    setSelectOptions(updatedData)
  }

  const onSaveClick = () => {
    setEdit(false)
    onSave({values: {isLabel, labelTitle, selectedOption, selectOptions, col: values.col}}, index)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      onSaveClick()
    }
  }

  const onResize = () => {
    if (values.col === 12) {
      onSave(
        {
          values: {...values, col: 6},
        },
        index
      )
    } else if (values.col === 6) {
      onSave(
        {
          values: {...values, col: 4},
        },
        index
      )
    } else {
      onSave(
        {
          values: {...values, col: 12},
        },
        index
      )
    }
  }

  useMemo(() => {
    setIsLabel(values.isLabel)
    setLabelTitle(values.labelTitle)
    setSelectedOption(values.selectedOption)
    setSelectOptions(values.selectOptions)
  }, [values])

  return (
    <div
      className={`col-${values.col || 4}`}
      draggable
      onDragStart={handleFormDragStart}
      onDragOver={handleFormDragOver}
      onDragEnd={handleFormDragEnd}
    >
      {edit ? (
        // Edit View
        <div className='border rounded overflow-hidden'>
          {/* Top Section */}
          <div className='d-flex justify-content-between bg-gray-300 py-3 px-4'>
            <div className='d-flex align-items-center gap-2'>
              <div
                className='bg-dark d-flex align-items-center'
                style={{borderRadius: '3px', padding: '4px'}}
              >
                <SelectFieldIcon height={14} fill='none' stroke='white' />
              </div>

              <p className='m-0 fw-bold fs-7'>Select Field</p>
            </div>
            <div className='d-flex align-items-center gap-2'>
              <div
                className='d-flex align-items-center gap-1 cursor-pointer'
                onClick={() => onDuplicate(index)}
              >
                <KTIcon iconName='copy' className='fs-6 text-dark' />
                <p className='m-0 fs-8 fw-semibold'>Duplicate</p>
              </div>
              <span
                className='badge badge-primary fs-8 py-0 px-3 fw-semibold cursor-pointer h-25px rounded-1'
                onClick={onSaveClick}
              >
                Save
              </span>
            </div>
          </div>

          {/* Middle Section */}
          <div className='p-4 d-flex flex-column'>
            {isLabel && (
              <div
                className='input-group border border-primary px-3 py-2 w-175px mb-3 gap-3'
                style={{borderRadius: '3px'}}
              >
                <input
                  type='text'
                  ref={selectFieldRef}
                  className='form-control bg-transparent border-0 p-0 text-dark fs-6 fw-normal rounded-0'
                  value={labelTitle}
                  onChange={(e) => setLabelTitle(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <span
                  className='input-group-text bg-transparent border-0 p-0 cursor-pointer rounded-0'
                  onClick={() => selectFieldFocusInput()}
                >
                  <KTIcon iconName='pencil' className='fs-6 text-primary' />
                </span>
              </div>
            )}
            <div className='border rounded p-3 d-flex flex-column gap-3'>
              <div className='d-flex justify-content-between align-items-center'>
                <div
                  className='input-group border border-primary px-3 py-1 w-150px  gap-3'
                  style={{borderRadius: '3px'}}
                >
                  <input
                    type='text'
                    ref={optionFieldRef}
                    className='form-control bg-transparent border-0 p-0 text-dark fs-6 fw-normal rounded-0'
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    onKeyDown={handleKeyPress}
                  />
                  <span
                    className='input-group-text bg-transparent border-0 p-0 cursor-pointer rounded-0'
                    onClick={() => optionFieldFocusInput()}
                  >
                    <KTIcon iconName='pencil' className='fs-6 text-primary' />
                  </span>
                </div>
                <KTIcon iconName='up' className='fs-4 text-dark' />
              </div>
              <div>
                {selectOptions.map((option: any, index: number) => (
                  <div
                    className='input-group px-4 py-1 d-flex align-items-center gap-2'
                    style={{borderRadius: '3px'}}
                  >
                    <input
                      type='text'
                      className='form-control bg-transparent border-0 p-0 text-dark fs-6 fw-normal rounded-0'
                      value={option.title}
                      onChange={(e) => onEditOption(index, e.target.value)}
                      id={`optionId${index}`}
                      onKeyDown={handleKeyPress}
                    />
                    <span className='input-group-text bg-transparent border-0 p-0 rounded-0 d-flex align-items-center gap-1'>
                      <div
                        style={{marginBottom: '-4px'}}
                        className='cursor-pointer d-flex'
                        onClick={() => document.getElementById(`optionId${index}`)?.focus()}
                      >
                        <KTIcon iconName='pencil' className='fs-7 text-primary' />
                      </div>
                      <div
                        style={{marginBottom: '-4px'}}
                        className='cursor-pointer d-flex'
                        onClick={() => removeOption(index)}
                      >
                        <KTIcon iconName='cross-square' className='fs-4 text-primary m-0' />
                      </div>
                    </span>
                  </div>
                ))}
              </div>
              <div className='d-flex align-interpolation gap-1 cursor-pointer' onClick={addOption}>
                <KTIcon iconName='plus-circle' className='fs-3 text-primary' />
                <p className='text-primary fs-7 fw-normal m-0'>Add Option</p>
              </div>
            </div>
            <div className='separator my-3' />

            {/* Bottom Section */}
            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-align-items-center gap-1'>
                <input
                  className='form-check-input h-15px w-15px m-0'
                  style={{borderRadius: '3px'}}
                  type='checkbox'
                  checked={isLabel}
                  onChange={(e) => setIsLabel(e.target.checked)}
                  onKeyDown={handleKeyPress}
                />
                <label className='form-check-label text-dark fs-7 m-0 fw-normal'>Field Label</label>
              </div>
              <button
                className='btn border border-danger text-danger fs-8 btn-sm py-0 px-3 h-25px align-self-end'
                onClick={() => {
                  onDelete(index)
                  setEdit(false)
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      ) : (
        // Main View
        <div
          className='border rounded ps-0 py-5 pe-5 d-flex align-items-center grab'
          style={{margin: '0px 0px'}}
        >
          <Left height={15} className='mx-3' />

          <div className='d-flex flex-column gap-2 w-100'>
            <div
              className={`d-flex justify-content-${isLabel ? 'between' : 'end'} align-items-center`}
            >
              {isLabel && <h6 className='m-0 fw-medium'>{labelTitle}</h6>}
              <div className='d-flex align-items-center gap-2'>
                <button
                  className='btn align-self-end btn-icon btn-bg-light btn-active-color-primary btn-sm h-25px w-25px align-self-end pencil-div'
                  style={{padding: '1px 2px 0px 0px'}}
                  onClick={onResize}
                >
                  <KTIcon iconName='arrow-two-diagonals' className='fs-2 pencil-icon-style' />
                </button>
                <button
                  className='btn align-self-end btn-icon btn-bg-light btn-active-color-primary btn-sm p-0 h-25px w-25px align-self-end pencil-div'
                  onClick={() => {
                    setEdit(true)
                  }}
                >
                  <KTIcon iconName='pencil' className='fs-5 m-1 pencil-icon-style' />
                </button>
              </div>
            </div>
            <div className='position-relative'>
              <div
                className={`bg-gray-300 collapsed cursor-pointer border border-gray-300 px-3 d-flex justify-content-between align-items-center ${
                  selectOpen ? 'border-bottom-0 rounded-top' : 'rounded'
                }`}
                style={{height: '38px'}}
                data-bs-toggle='collapse'
                data-bs-target={`#select-options${index}`}
                onClick={() => setSelectOpen(!selectOpen)}
              >
                <h6 className='m-0 fs-6 fw-normal'>{selectedOption}</h6>
                <KTIcon iconName={`${selectOpen ? 'up' : 'down'}`} className='fs-4 text-dark' />
              </div>
              <div
                className='collapse position-absolute w-100 shadow-sm'
                id={`select-options${index}`}
              >
                <div
                  className='border border-gray-300 border-top-0 rounded-bottom px-3 h-100 bg-gray-300 position-relative'
                  style={{zIndex: 1}}
                >
                  {selectOptions.map((option: any, key: number) => (
                    <div
                      className='py-3 cursor-pointer'
                      data-bs-toggle='collapse'
                      data-bs-target={`#select-options${index}`}
                      key={key}
                      onClick={() => onOptionSelect(option)}
                    >
                      <h6 className='m-0 fs-6 fw-normal'>{option.title}</h6>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
