import React, {DragEvent, useState} from 'react'
import colors from '../../utils/colors'
import Left from '../assets/icons/Left'
interface CustomProps extends React.SVGProps<SVGSVGElement> {
  cirColorValue?: string
}
interface ReportElementCardProps {
  Icon: (props: CustomProps) => JSX.Element
  title: string
  onDragStart: (e: DragEvent<HTMLDivElement>) => void
}

export const ReportElementCard: React.FC<ReportElementCardProps> = ({title, Icon, onDragStart}) => {
  const [hovered, setHovered] = useState(false)
  return (
    <div
      className='border m-0 border-gray-200 p-4 rounded d-flex align-items-center justify-content-between report-element-button grab'
      draggable
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onDragStart={onDragStart}
    >
      <div className='d-flex align-items-center gap-2'>
        <div className='d-flex align-items-center bg-gray-200 p-2 rounded report-div-icon'>
          <Icon
            fill='none'
            id='radio-btn-circle'
            height={16}
            cirColorValue={hovered ? colors.progress.primary : '#F3F6F9'}
            className='left-icon'
          />
        </div>
        <h6 className='m-0'>{title}</h6>
      </div>
      <Left height={15} className='mx-3 right-icon' />
    </div>
  )
}
