import React, {useEffect, useMemo, useRef, useState} from 'react'
import DateFieldIcon from '../../../../assets/icons/DateFieldIcon'
interface DateProps {
  values: any
  onChange?: any
  index: any
}
const DateFieldElement = ({values, onChange, index}: any) => {
  const [inputType, setInputType] = useState<'text' | 'date'>(values.inputType || 'text')
  const [date, setDate] = useState(values.date)
  const datePickerRef = useRef<HTMLInputElement | any>(null)
  const [toggle, setToggle] = useState(true)

  const openDatePicker = () => {
    if (datePickerRef.current) {
      if (toggle) {
        setInputType('date')
        document.getElementById(`date-picker-report-input-${index}`)?.setAttribute('type', 'date')
        datePickerRef.current?.showPicker()
        setToggle(!toggle)
      }
      if (inputType === 'date') setToggle(!toggle)
    }
  }

  // useEffect(() => {
  //   if (inputType === 'date') datePickerRef.current.showPicker()
  // }, [inputType])

  useEffect(() => {
    if (date?.length === 0) {
      setInputType('text')
    } else {
      setInputType('date')
    }
  }, [date])

  useMemo(() => {
    setDate(values.date)
  }, [values.date])

  // useEffect(() => {
  //   if (inputType === 'date') {
  //     openDatePicker()
  //   }
  // }, [inputType])

  return (
    <div className={`col-${values.col || 4}`}>
      {values.isLabel && <p className='fs-7 fw-bold mt-0 mb-1'>{values.labelTitle}</p>}
      <div
        className='input-group border border-gray-300 rounded overflow-hidden'
        style={{height: '34.8px'}}
      >
        <input
          id={`date-picker-report-input-${index}`}
          ref={datePickerRef}
          type={inputType}
          className={`form-control bg-transparent border-0 p-0 fs-7 fw-medium fw-normal rounded-0 ms-3 custom-date-input py-2  ${
            inputType === 'date' ? 'text-dark' : 'text-gray-500'
          }`}
          // onFocus={openDatePicker}
          value={inputType === 'text' ? values.datePlaceholder : date}
          onClick={openDatePicker}
          onChange={(e) => {
            setDate(e.target.value)
            onChange(e.target.value)
            setToggle(!toggle)
          }}
        />
        <span
          className='bg-transparent input-group-text border-0 p-0 rounded-0 px-3 cursor-pointer'
          onClick={openDatePicker}
        >
          <DateFieldIcon fill='none' stroke='black' height={18} />
        </span>
      </div>
    </div>
  )
}
export {DateFieldElement}
