import React from 'react'

interface PaginationProps {
  totalPages: number
  currentPage: number
  onPageChange: (page: number) => void
}

export const Pagination: React.FC<PaginationProps> = ({currentPage, totalPages, onPageChange}) => {
  return (
    <div>
      <ul className='pagination'>
        <li
          className={`page-item previous ${currentPage === 1 ? 'disabled' : 'cursor-pointer'}`}
          onClick={() => {
            if (!(currentPage === 1)) {
              onPageChange(currentPage - 1)
            }
          }}
        >
          <span className='page-link'>
            <i className='previous'></i>
          </span>
        </li>
        {Array.from({length: totalPages}, (_, i) => {
          let pageNumber = i + 1
          return (
            <li
              key={pageNumber}
              className={`page-item cursor-pointer ${pageNumber === currentPage && 'active'}`}
              onClick={() => onPageChange(pageNumber)}
            >
              <span className='page-link'>{pageNumber}</span>
            </li>
          )
        })}
        <li
          className={`page-item next ${
            currentPage === (totalPages || 1) ? 'disabled' : 'cursor-pointer'
          }`}
          onClick={() => {
            if (!(currentPage === (totalPages || 1))) {
              onPageChange(currentPage + 1)
            }
          }}
        >
          <span className='page-link'>
            <i className='next'></i>
          </span>
        </li>
      </ul>
    </div>
  )
}
