import jwt_decode from 'jwt-decode'
import {HeadingPdfElement} from '../app/pages/reports/report-pdf-elements/HeadingPdfElement'
import {DividerPdfElement} from '../app/pages/reports/report-pdf-elements/DividerPdfElement'
import {NumberPdfElement} from '../app/pages/reports/report-pdf-elements/NumberPdfElement'
import {DatePdfElement} from '../app/pages/reports/report-pdf-elements/DatePdfElement'
import {SelectPdfElement} from '../app/pages/reports/report-pdf-elements/SelectPdfElement'
import {TextPdfElement} from '../app/pages/reports/report-pdf-elements/TextPdfElement'
import {RadioPdfElement} from '../app/pages/reports/report-pdf-elements/RadioPdfElement'
import {CheckBoxPdfElement} from '../app/pages/reports/report-pdf-elements/CheckBoxPdfElement'
import {TextAreaPdfElement} from '../app/pages/reports/report-pdf-elements/TextAreaPdfElement'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

// @ts-ignore
import * as html2pdf from 'html2pdf.js'

interface JwtPayload {
  sub: string
  iat: number
  exp: number
  // Add other properties as needed
}

const arrayMoveMutate = (array: any, from: any, to: any) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0])
}

const arrayMove = (array: any, from: any, to: any) => {
  array = array.slice()
  arrayMoveMutate(array, from, to)
  return array
}

export const retry = (fn: any, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: any) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error)
            return
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export const getDataFromLocal = (key: any) => localStorage.getItem(key)
export const setDataFromLocal = (key: any, data: any) => localStorage.setItem(key, data)
export const removeDataFromLocal = (key: any) => localStorage.getItem(key)
export const clearLocalStorage = () => localStorage.clear()

export const decodeToken = (token: string | null): JwtPayload | null => {
  if (!token) return null
  return jwt_decode(token) as JwtPayload
}

export const isTokenActivated = (token: string | null): boolean => {
  if (!token) return false

  const decoded: {exp?: number} = jwt_decode(token)
  return (decoded?.exp ?? 0) > Date.now() / 1000
}

export {arrayMove}

export const status = (status: string) => {
  switch (status) {
    case 'in_progress':
      return (
        <span className='badge text-primary fs-7 p-3' style={{backgroundColor: '#ECF8FF'}}>
          In Progress
        </span>
      )
    case 'approved':
      return (
        <span className='badge text-success fs-7 p-3' style={{backgroundColor: '#E8FFF3'}}>
          Approved
        </span>
      )
    case 'in_review':
      return (
        <span className='badge text-warning fs-7 p-3' style={{backgroundColor: '#FFF8DD'}}>
          In Review
        </span>
      )
    case 'requires_revision':
      return (
        <span className='badge text-danger fs-7 p-3' style={{backgroundColor: '#FFF5F8'}}>
          Require Revision
        </span>
      )
    case 'sent':
      return <span className='badge badge-success fs-7 p-3'>Sent</span>
    default:
      return (
        <span className='badge text-info fs-7 p-3' style={{backgroundColor: '#F8F5FF'}}>
          Not Started
        </span>
      )
  }
}

export const pdfBlobGenerator = async (element: any, filename: string) => {
  try {
    const options = {
      margin: 14,
      filename: filename,
      image: {type: 'jpeg', quality: 1.0},
      pageBreak: {
        mode: 'avoid-all',
        avoid: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'li'],
      },
      html2canvas: {
        scale: 2,
        logging: true,
        dpi: 192,
        letterRendering: true,
      },
      jsPDF: {unit: 'mm', format: 'letter', orientation: 'portrait', putTotalPages: true},
    }
    // await html2pdf().from(element).set(options).save()
    html2pdf()
      .from(element)
      .set(options)
      .toPdf()
      .get('pdf')
      .then(function (pdf: any) {
        var totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(100)
          pdf.text(
            `${i}`,
            pdf.internal.pageSize.getWidth() / 2,
            pdf.internal.pageSize.getHeight() - 8
          )
        }
      })
      .save()
    const pdf = await html2pdf()
      .from(element)
      .set(options)
      .toPdf()
      .get('pdf')
      .then(function (pdf: any) {
        var totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(100)
          pdf.text(
            `${i}`,
            pdf.internal.pageSize.getWidth() / 2,
            pdf.internal.pageSize.getHeight() - 8
          )
        }
      })
      .outputPdf('datauristring', {filename: filename})
    return pdf
  } catch (error) {
    // console.log('🚀 ~ file: Helpers.tsx:125 ~ pdfBlobGenerator ~ error:', error)
  }
}

export const pdfBase64Generator = async (element: any, filename: string) => {
  try {
    const options = {
      margin: 10,
      filename: filename,
      image: {type: 'jpeg', quality: 1.0},
      html2canvas: {scale: 2},
      jsPDF: {unit: 'mm', format: 'a4', orientation: 'portrait'},
    }
    const image = await html2pdf().from(element).set(options).outputImg('dataurl')
    const canvas = await html2canvas(element)

    const contentAspectRatio = canvas.width / canvas.height
    const pdf = new jsPDF('p', 'mm', 'a4')
    const pdfWidth = 210 // A4 width
    const pdfHeight = pdfWidth / contentAspectRatio
    pdf.addImage(image, 'JPEG', 0, 0, pdfWidth, pdfHeight)
    pdf.save(filename)
    return pdf.output('datauristring', {filename: filename})
    // Save the PDF to a file with a specific name
  } catch (error) {
    // console.log('error:', error)
  }
}

export const convertToPDF = (element: any, filename: string) => {
  // Create a new jsPDF instance
  const doc = new jsPDF()

  // Use jsPDF's html method to add HTML content to the PDF
  doc.html(element, {
    callback: function (pdf) {
      // Save or download the PDF file
      pdf.save(`${filename}.pdf`)
    },
  })
}

export const renderPdfElement = (type: string, elementData: any) => {
  switch (type) {
    case 'section-heading':
      return <HeadingPdfElement elementData={elementData} />
    case 'section-divider':
      return <DividerPdfElement elementData={elementData} />
    case 'number-field':
      return <NumberPdfElement elementData={elementData} />
    case 'date-field':
      return <DatePdfElement values={elementData.values} />
    case 'select-field':
      return <SelectPdfElement values={elementData.values} />
    case 'text-field':
      return <TextPdfElement elementData={elementData} />
    case 'radio-button':
      return <RadioPdfElement elementData={elementData} />
    case 'check-box':
      return <CheckBoxPdfElement values={elementData.values} />
    case 'text-area':
      return <TextAreaPdfElement values={elementData.values} />
    default:
      return null
  }
}
