import { Suspense } from 'react'
import { LayoutProvider, LayoutSplashScreen } from '../theme/layout/core'
import { MasterInit } from '../theme/layout/MasterInit'
import { AuthInit } from './modules/auth'
import Routing from '../routes'


const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
        <LayoutProvider>
          <AuthInit>
            <Routing />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
    </Suspense>
  )
}

export { App }
