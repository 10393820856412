import {Link, useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../helpers'
import {Search} from '../../../../partials'
import {useRoutes} from '../../../../../hooks/useRoutes'

const projects: ReadonlyArray<{title: string; link: string; icon: string}> = [
  {
    title: 'Report Builder',
    link: '/template-builder',
    icon: 'add-files',
  },
  {
    title: 'Matters',
    link: '/client-matters',
    icon: 'copy',
  },
]

const UpdateInformationTab = () => {
  const {sidebarRoutes} = useRoutes()
  const navigate = useNavigate()

  return (
    <div className='m-0 '>
      {/* begin::Toolbar */}
      <div className='d-flex mb-10'>
        {/* begin::Filter */}
        {/* <div className='flex-shrink-0 ms-2'> */}
        {/* begin::Menu toggle */}
        {/* <button
            type='button'
            className='btn btn-icon btn-bg-light btn-active-icon-primary btn-color-gray-400'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <KTIcon iconName='filter' className='fs-2' /> */}
        {/* </button> */}
        {/* end::Menu toggle */}

        {/* <Dropdown1 />
        </div> */}
        {/* end::Filter */}
      </div>
      {/* end::Toolbar */}

      {/*begin::Projects*/}
      <div className='m-0'>
        {/*begin::Heading*/}

        <h1 className='text-white fw-bold mb-6 mx-5 fs-2'>Report Manager</h1>
        {/*end::Heading*/}

        {/*begin::Items*/}
        <div className='mb-10'>
          {sidebarRoutes.map((p) => {
            if (p.id === 'activeReport') {
              return (
                <div key={p.id}>
                  <div className='custom-list d-flex flex-column px-5 py-4 gap-2'>
                    <h5 className='text-white'>Reports By Status</h5>
                    <ul className='d-flex flex-column gap-2 m-0'>
                      <li className='text-white'>
                        <p
                          className='my-0 ps-3 py-1 text-hover-success bg-hover-white rounded-2'
                          onClick={() => navigate('/active-report?report-status=sent')}
                        >
                          Sent
                        </p>
                      </li>
                      <li className='text-success'>
                        <p
                          className='my-0 ps-3 py-1 text-white bg-hover-success rounded-2'
                          onClick={() => navigate('/active-report?report-status=approved')}
                        >
                          Approved
                        </p>
                      </li>
                      <li className='not-started-color'>
                        <p
                          className='my-0 ps-3 py-1 text-white not-started-hover rounded-2'
                          onClick={() => navigate('/active-report?report-status=not_started')}
                        >
                          Not Started
                        </p>
                      </li>
                      <li className='text-primary'>
                        <p
                          className='my-0 ps-3 py-1 text-white bg-hover-primary rounded-2'
                          onClick={() => navigate('/active-report?report-status=in_progress')}
                        >
                          In Progress
                        </p>
                      </li>
                      <li className='text-warning'>
                        <p
                          className='my-0 ps-3 py-1 text-white bg-hover-warning rounded-2'
                          onClick={() => navigate('/active-report?report-status=in_review')}
                        >
                          In Review
                        </p>
                      </li>
                      <li className='text-danger'>
                        <p
                          className='my-0 ps-3 py-1 text-white bg-hover-danger rounded-2'
                          onClick={() => navigate('/active-report?report-status=requires_revision')}
                        >
                          Requires Revision
                        </p>
                      </li>
                    </ul>
                  </div>
                  <Link
                    key={p.name}
                    to={p.path}
                    className='custom-list d-flex align-items-center px-5'
                  >
                    {/*begin::Description*/}
                    <div className='d-flex align-items-center'>
                      {/*begin::Title*/}

                      <p className='fw-medium text-white m-0 fs-7 text-primary text-decoration-underline'>
                        {p.name}
                      </p>
                      <KTIcon iconName={'right'} className='fs-6 ml-1 mt-1 text-primary' />
                      {/*end::Title*/}
                    </div>
                    {/*begin::Description*/}
                  </Link>
                </div>
              )
            } else {
              return (
                <Link
                  key={p.name}
                  to={p.path}
                  className='custom-list d-flex align-items-center  px-5 py-4'
                >
                  <KTIcon iconName={p.icon || 'add-files'} className='fs-2 text-white' />
                  {/*begin::Description*/}
                  <div className='d-flex flex-column flex-grow-1 mx-2 px-1'>
                    {/*begin::Title*/}

                    <h5 className='custom-list-title fw-bold text-white m-0'>{p.name}</h5>
                    {/*end::Title*/}
                  </div>
                  {/*begin::Description*/}
                  <KTIcon iconName={'right'} className='fs-2 mx-1 ' />
                </Link>
              )
            }
          })}
        </div>
        {/*end::Items*/}
      </div>
      {/*end::Projects*/}
    </div>
  )
}

export {UpdateInformationTab}
