import React, {DragEvent, useEffect, useMemo, useState} from 'react'
import {KTIcon} from '../../../../../theme/helpers'
import {useEditor, EditorContent} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import {EditorMenuBar} from './EditorMenuBar'
import TextAreaIcon from '../../../../assets/icons/TextAreaIcon'
import Left from '../../../../assets/icons/Left'
import {useFocus} from '../../../../../hooks/useFocus'

interface TextAreaProps {
  index: number
  onDelete: (param: number) => void
  onDuplicate: (param: number) => void
  onSave: (data: any, index: number) => void
  values: any
  handleFormDragStart: (e: DragEvent<HTMLDivElement>) => void
  handleFormDragOver: (e: DragEvent<HTMLDivElement>) => void
  handleFormDragEnd: (e: DragEvent<HTMLDivElement>) => void
}

export const TextArea: React.FC<TextAreaProps> = ({
  index,
  onDelete,
  onDuplicate,
  onSave,
  values,
  handleFormDragStart,
  handleFormDragOver,
  handleFormDragEnd,
}) => {
  const [edit, setEdit] = useState(false)
  const [isLabel, setIsLabel] = useState(values.isLabel)
  const [isReadOnly, setIsReadOnly] = useState(values.isReadOnly)
  const [isEditable, setIsEditable] = useState(values.isEditable)
  const [labelTitle, setLabelTitle] = useState(values.labelTitle)
  const {inputRef: areaLabel, focusInput: areaLabelFocus} = useFocus()
  const [updateValue, setUpdateValue] = useState(true)

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    ],
    content: values.text,
    onFocus: () => {
      setUpdateValue(false)
    },
    onUpdate: ({editor}) => {
      onSave(
        {
          values: {...values, text: editor.getJSON()},
        },
        index
      )
    },
  })

  const onSaveClick = () => {
    setEdit(false)
    onSave(
      {values: {isLabel, labelTitle, text: values.text, isReadOnly, isEditable, col: values.col}},
      index
    )
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      onSaveClick()
    }
  }

  const onResize = () => {
    if (values.col === 12) {
      onSave(
        {
          values: {...values, col: 6},
        },
        index
      )
    } else {
      onSave(
        {
          values: {...values, col: 12},
        },
        index
      )
    }
  }

  const onUpdateValue = () => {
    editor?.commands.setContent(values.text)
  }

  useMemo(() => {
    setIsLabel(values.isLabel)
    setLabelTitle(values.labelTitle)
    setIsReadOnly(values.isReadOnly)
    setIsEditable(values.isEditable)
    updateValue && editor?.commands.setContent(values.text)
  }, [values])

  return (
    <div
      className={`col-${values.col || 6} text-area-div`}
      draggable
      onDragStart={(e: DragEvent<HTMLDivElement>) => {
        handleFormDragStart(e)
        // onUpdateValue()
        setUpdateValue(true)
      }}
      onDragOver={(e: DragEvent<HTMLDivElement>) => {
        handleFormDragOver(e)
        // onUpdateValue()
        setUpdateValue(true)
      }}
      onDragEnd={(e: DragEvent<HTMLDivElement>) => {
        handleFormDragEnd(e)
        // onUpdateValue()
        setUpdateValue(true)
      }}
    >
      {edit ? (
        // Edit View
        <div className='border rounded overflow-hidden'>
          {/* Top Section */}
          <div className='d-flex justify-content-between bg-gray-300 py-3 px-4'>
            <div className='d-flex align-items-center gap-2'>
              <div
                className='bg-dark d-flex align-items-center'
                style={{borderRadius: '3px', padding: '4px'}}
              >
                <TextAreaIcon height={14} fill='none' stroke='white' />
              </div>
              <p className='m-0 fw-bold fs-7'>Text Area</p>
            </div>
            <div className='d-flex align-items-center gap-2'>
              <div
                className='d-flex align-items-center gap-1 cursor-pointer'
                onClick={() => onDuplicate(index)}
              >
                <KTIcon iconName='copy' className='fs-6 text-dark' />
                <p className='m-0 fs-8 fw-semibold'>Duplicate</p>
              </div>
              <span
                className='badge badge-primary fs-8 py-0 px-3 fw-semibold cursor-pointer h-25px rounded-1'
                onClick={onSaveClick}
              >
                Save
              </span>
            </div>
          </div>

          {/* Middle Section */}
          <div className='p-4 d-flex flex-column'>
            {isLabel && (
              <div
                className='input-group border border-primary px-3 py-2 w-175px mb-3 gap-3'
                style={{borderRadius: '3px'}}
              >
                <input
                  type='text'
                  ref={areaLabel}
                  className='form-control bg-transparent border-0 p-0 text-dark fs-6 fw-normal rounded-0'
                  value={labelTitle}
                  onChange={(e) => setLabelTitle(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <span
                  className='input-group-text bg-transparent border-0 p-0 rounded-0 cursor-pointer'
                  onClick={() => areaLabelFocus()}
                >
                  <KTIcon iconName='pencil' className='fs-6 text-primary' />
                </span>
              </div>
            )}
            <div className='border rounded py-2 px-3 bg-gray-300'>
              <div
                className='border border-primary px-2 py-1 gap-3 d-flex justify-content-between'
                style={{borderRadius: '3px', cursor: 'auto'}}
              >
                <EditorContent editor={editor} />
                <span
                  className='cursor-pointer'
                  onClick={() => {
                    editor?.commands.focus()
                  }}
                >
                  <KTIcon iconName='pencil' className='fs-6 text-primary' />
                </span>
              </div>
            </div>

            <div className='separator my-3' />

            {/* Bottom Section */}
            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center gap-3'>
                <div className='d-flex align-items-center gap-1'>
                  <input
                    className='form-check-input m-0'
                    style={{borderRadius: '3px', height: '12px', width: '12px'}}
                    type='checkbox'
                    checked={isLabel}
                    onChange={(e) => setIsLabel(e.target.checked)}
                    onKeyDown={handleKeyPress}
                  />
                  <label className='form-check-label text-dark fs-7 m-0 fw-normal'>
                    Field Label
                  </label>
                </div>
                <div className='vr mx-1 bg-gray-400' />
                <div className='d-flex align-items-center gap-1'>
                  <input
                    className='form-check-input m-0'
                    style={{borderRadius: '3px', height: '12px', width: '12px'}}
                    type='checkbox'
                    checked={isReadOnly}
                    onChange={() => {
                      setIsReadOnly(true)
                      setIsEditable(false)
                    }}
                    onKeyDown={handleKeyPress}
                  />
                  <label className='form-check-label text-dark fs-7 m-0 fw-normal'>Read Only</label>
                </div>
                <div className='d-flex align-items-center gap-1'>
                  <input
                    className='form-check-input m-0'
                    style={{borderRadius: '3px', height: '12px', width: '12px'}}
                    type='checkbox'
                    checked={isEditable}
                    onChange={() => {
                      setIsEditable(true)
                      setIsReadOnly(false)
                    }}
                    onKeyDown={handleKeyPress}
                  />
                  <label className='form-check-label text-dark fs-7 m-0 fw-normal'>Editable</label>
                </div>
              </div>
              <button
                className='btn border border-danger text-danger fs-8 btn-sm py-0 px-3 h-25px align-self-end'
                onClick={() => {
                  onDelete(index)
                  setEdit(false)
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      ) : (
        // Main View
        <div
          className='border rounded ps-0 py-5 pe-5 d-flex align-items-center grab'
          style={{margin: '0px 0px'}}
        >
          <Left height={15} className='mx-3' />

          <div className='d-flex flex-column gap-3 w-100'>
            <div
              className={`d-flex justify-content-${isLabel ? 'between' : 'end'} align-items-center`}
            >
              {isLabel && <h6 className='m-0 fw-medium'>{labelTitle}</h6>}
              <div className='d-flex align-items-center gap-2'>
                <button
                  className='btn align-self-end btn-icon btn-bg-light btn-active-color-primary btn-sm h-25px w-25px align-self-end pencil-div'
                  style={{padding: '1px 2px 0px 0px'}}
                  onClick={onResize}
                >
                  <KTIcon iconName='arrow-two-diagonals' className='fs-2 pencil-icon-style' />
                </button>
                <button
                  className='btn align-self-end btn-icon btn-bg-light btn-active-color-primary btn-sm p-0 h-25px w-25px align-self-end pencil-div'
                  onClick={() => {
                    setEdit(true)
                  }}
                >
                  <KTIcon iconName='pencil' className='fs-5 m-1 pencil-icon-style' />
                </button>
              </div>
            </div>

            {/* Editor Menu Bar */}
            <EditorMenuBar index={index} editor={editor} />

            {/* Editor */}
            <div
              id='textEditor'
              className='border rounded py-2 px-3 bg-gray-300'
              style={{cursor: 'auto'}}
            >
              <EditorContent editor={editor} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}