import React from 'react'

interface HeadingProps {
  elementData: any
}
const HeadingPdfElement = ({elementData}: HeadingProps) => {
  return (
    <div style={{gridColumn: 'span 12'}}>
      <h3 style={{margin: 0}}>{elementData.label}</h3>
    </div>
  )
}

export {HeadingPdfElement}
