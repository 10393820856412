import {DragEvent, useEffect, useMemo, useRef, useState} from 'react'
import {useFocus} from '../../../../../hooks/useFocus'
import {KTIcon} from '../../../../../theme/helpers'
import Left from '../../../../assets/icons/Left'
import TextFieldIcon from '../../../../assets/icons/TextFieldIcon'

interface elementProps {
  handleFormDragStart: (e: DragEvent<HTMLDivElement>) => void
  handleFormDragOver: (e: DragEvent<HTMLDivElement>) => void
  handleFormDragEnd: (e: DragEvent<HTMLDivElement>) => void
  onSave: (param: any) => void
  onDuplicate: (param: number) => void
  index: number
  onDelete: (param: number) => void
  elementData: any
}

const TextField = ({
  handleFormDragStart,
  handleFormDragOver,
  handleFormDragEnd,
  onSave,
  onDuplicate,
  index,
  onDelete,
  elementData,
}: elementProps) => {
  const [edit, setEdit] = useState(false)
  const [isLabel, setLabel] = useState(elementData.isLabel)
  const [isText, setText] = useState(elementData.isText)
  const [isEmail, setEmail] = useState(elementData.isEmail)
  const [isPhone, setPhone] = useState(elementData.isPhone)
  const [title, setTitle] = useState(elementData.label)
  const [defaultValue, setDefaultValue] = useState(elementData.defaultValue)

  // const [width, setWidth] = useState(100)
  // const resizableRef = useRef<HTMLDivElement>(null)
  const parentRef = useRef<HTMLDivElement>(null)

  const {inputRef: fieldRef, focusInput: fieldFocusInput} = useFocus()
  const {inputRef: textDataRef, focusInput: textDataFocusInput} = useFocus()

  const handleSave = () => {
    onSave({
      label: title,
      isLabel: isLabel,
      isText: isText,
      isEmail: isEmail,
      isPhone: isPhone,
      defaultValue: defaultValue,
    })
    setEdit(false)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleSave()
    }
  }

  useMemo(() => {
    setLabel(elementData.isLabel)
    setText(elementData.isText)
    setEmail(elementData.isEmail)
    setPhone(elementData.isPhone)
    setTitle(elementData.label)
    setDefaultValue(elementData.defaultValue)
  }, [elementData])

  useEffect(() => {
    if (isPhone) {
      setDefaultValue('(xxx) xxx-xxxx')
    } else if (isEmail) {
      setDefaultValue('example@email.com')
    } else {
      setDefaultValue('Enter your text data')
    }
  }, [isPhone, isEmail, isText])

  // useEffect(() => {
  //   const observeResize = (entries: ResizeObserverEntry[]) => {
  //     for (let entry of entries) {
  //       const parentWidth = parentRef.current?.parentElement?.offsetWidth
  //       const childWidth = entry.target.clientWidth

  //       if (parentWidth && childWidth) {
  //         const percentageWidth = (childWidth / parentWidth) * 100
  //         let newWidth = 0

  //         if (percentageWidth === 100) {
  //           newWidth = 100
  //         } else if (percentageWidth === 50) {
  //           newWidth = 50
  //         } else if (percentageWidth === 33.33) {
  //           newWidth = 33.33
  //         } else {
  //           // For other widths, find the nearest valid width
  //           if (percentageWidth > 66) {
  //             newWidth = 100
  //           } else if (percentageWidth > 40) {
  //             newWidth = 50
  //           } else {
  //             newWidth = 33.33
  //           }
  //         }

  //         // Apply the width to the element
  //         // entry.target.style.width = `${newWidth}%`

  //         // Calculate column count based on the enforced width
  //         let newColumnCount: number
  //         if (newWidth === 100) {
  //           newColumnCount = 12
  //         } else if (newWidth === 50) {
  //           newColumnCount = 6
  //         } else {
  //           newColumnCount = 4
  //         }

  //         setWidth(newWidth)
  //         break
  //       }
  //     }
  //   }

  //   if (resizableRef.current) {
  //     const resizeObserver = new ResizeObserver(observeResize)
  //     resizeObserver.observe(resizableRef.current)

  //     return () => {
  //       resizeObserver.disconnect()
  //     }
  //   }
  // }, [])

  const onResize = () => {
    if (elementData.col === 12) {
      onSave({
        col: 6,
      })
    } else if (elementData.col === 6) {
      onSave({
        col: 4,
      })
    } else {
      onSave({
        col: 12,
      })
    }
  }

  // const handleResize = () => {
  //   if (resizableRef.current && parentRef.current) {
  //     resizableRef.current.style.width = `100%`
  //     parentRef.current.style.width = `${width}%`
  //   }
  // }

  // useEffect(() => {
  //   handleResize()
  // }, [width])

  // const [isResizing, setIsResizing] = useState(false)
  // const [customWidth, setCustomWidth] = useState(200) // Initial width of the resizable div
  // const [startX, setStartX] = useState(0)
  // const containerRef = useRef<HTMLDivElement>(null)
  // const edgeRef = useRef(null)

  // const startResize = (event: any) => {
  //   event.preventDefault()
  //   setIsResizing(true)
  //   setStartX(event.clientX)
  // }

  // const stopResize = () => {
  //   setIsResizing(false)
  // }

  // const resize = (event: any) => {
  //   if (isResizing && containerRef.current) {
  //     const containerRect = containerRef.current.getBoundingClientRect()
  //     const offsetX = event.clientX - startX
  //     const newWidth = customWidth + offsetX
  //     setCustomWidth(newWidth >= 50 ? newWidth : 50) // Limit minimum width
  //     setStartX(event.clientX)
  //   }
  // }

  // const handleMouseLeave = () => {
  //   if (isResizing) {
  //     stopResize()
  //   }
  // }

  return (
    <div
      className={`col-${elementData.col || 4}`}
      draggable
      onDragStart={handleFormDragStart}
      onDragOver={handleFormDragOver}
      onDragEnd={handleFormDragEnd}
      ref={parentRef}
    >
      {!edit ? (
        <div
          className='border rounded ps-0 py-5 pe-5 d-flex align-items-center grab'
          // ref={resizableRef}
          // style={{resize: 'horizontal', overflow: 'hidden', margin: '0px 0px'}}
          // onMouseUp={handleResize}
        >
          <Left height={15} className='mx-3' />

          <div className='d-flex flex-column gap-2 w-100'>
            <div
              className={`d-flex justify-content-${isLabel ? 'between' : 'end'} align-items-center`}
            >
              {isLabel && <h6 className='m-0 fw-medium'>{title}</h6>}
              <div className='d-flex align-items-center gap-2'>
                <button
                  className='btn align-self-end btn-icon btn-bg-light btn-active-color-primary btn-sm h-25px w-25px align-self-end pencil-div'
                  style={{padding: '1px 2px 0px 0px'}}
                  onClick={onResize}
                >
                  <KTIcon iconName='arrow-two-diagonals' className='fs-2 pencil-icon-style' />
                </button>
                <button
                  className='btn align-self-end btn-icon btn-bg-light btn-active-color-primary btn-sm p-0 h-25px w-25px align-self-end pencil-div'
                  onClick={() => {
                    setEdit(true)
                  }}
                >
                  <KTIcon iconName='pencil' className='fs-5 m-1 pencil-icon-style' />
                </button>
              </div>
            </div>
            <div
              className='input-group bg-gray-300 rounded overflow-hidden'
              style={{height: '38px'}}
            >
              <input
                type='text'
                className='form-control bg-transparent border-0 p-0 text-dark fs-6 fw-normal rounded-0 ms-3 custom-date-input'
                value={defaultValue}
                // onChange={(e) => setDefaultValue(e.target.value)}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className='border rounded overflow-hidden'>
          {/* Top Section */}
          <div className='d-flex justify-content-between bg-gray-300 py-3 px-4'>
            <div className='d-flex align-items-center gap-2'>
              <div
                className='bg-dark d-flex align-items-center'
                style={{borderRadius: '3px', padding: '4px'}}
              >
                <TextFieldIcon height={14} fill='none' stroke='white' />
              </div>

              <p className='m-0 fw-bold fs-7'>Text Field</p>
            </div>
            <div className='d-flex align-items-center gap-2'>
              <div
                className='d-flex align-items-center gap-1 cursor-pointer'
                onClick={() => onDuplicate(index)}
              >
                <KTIcon iconName='copy' className='fs-6 text-dark' />
                <p className='m-0 fs-8 fw-semibold'>Duplicate</p>
              </div>
              <span
                className='badge badge-primary fs-8 py-0 px-3 fw-semibold cursor-pointer h-25px rounded-1'
                onClick={handleSave}
              >
                Save
              </span>
            </div>
          </div>

          <div className='p-4 d-flex flex-column'>
            {isLabel && (
              <div
                className='input-group border border-primary px-3 py-2 w-175px mb-3 gap-3'
                style={{borderRadius: '3px'}}
              >
                <input
                  type='text'
                  ref={fieldRef}
                  className='form-control bg-transparent border-0 p-0 text-dark fs-6 fw-normal rounded-0'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <span
                  className='input-group-text bg-transparent cursor-pointer border-0 p-0 rounded-0'
                  onClick={() => fieldFocusInput()}
                >
                  <KTIcon iconName='pencil' className='fs-6 text-primary' />
                </span>
              </div>
            )}
            <div className='border rounded bg-gray-300 d-flex justify-content-between align-items-center overflow-hidden'>
              <div
                className='input-group border border-primary px-3 py-1 gap-3 ms-2 my-2'
                style={{borderRadius: '3px', width: '165px'}}
              >
                <input
                  type='text'
                  ref={textDataRef}
                  className='form-control bg-transparent border-0 p-0 text-dark fs-7 fw-normal rounded-0'
                  value={defaultValue}
                  onChange={(e) => setDefaultValue(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <span
                  className='input-group-text cursor-pointer bg-transparent border-0 p-0 rounded-0'
                  onClick={() => textDataFocusInput()}
                >
                  <KTIcon iconName='pencil' className='fs-6 text-primary' />
                </span>
              </div>
            </div>
            <div className='separator my-3' />

            {/* Bottom Section */}
            <div className='d-flex flex-column gap-3'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center gap-1'>
                  <input
                    className='form-check-input m-0'
                    style={{borderRadius: '3px', height: '12px', width: '12px'}}
                    type='checkbox'
                    checked={isText}
                    onChange={(e) => {
                      setText(true)
                      setPhone(false)
                      setEmail(false)
                    }}
                    onKeyDown={handleKeyPress}
                  />
                  <label className='form-check-label text-dark fs-8 m-0 fw-normal'>Text</label>
                </div>
                <div className='d-flex align-items-center gap-1'>
                  <input
                    className='form-check-input m-0'
                    style={{borderRadius: '3px', height: '12px', width: '12px'}}
                    type='checkbox'
                    checked={isPhone}
                    onChange={(e) => {
                      setPhone(true)
                      setEmail(false)
                      setText(false)
                    }}
                    onKeyDown={handleKeyPress}
                  />
                  <label className='form-check-label text-dark fs-8 m-0 fw-normal'>Phone</label>
                </div>
                <div className='d-flex align-items-center gap-1'>
                  <input
                    className='form-check-input m-0'
                    style={{borderRadius: '3px', height: '12px', width: '12px'}}
                    type='checkbox'
                    checked={isEmail}
                    onChange={(e) => {
                      setEmail(true)
                      setPhone(false)
                      setText(false)
                    }}
                    onKeyDown={handleKeyPress}
                  />
                  <label className='form-check-label text-dark fs-8 m-0 fw-normal'>Email</label>
                </div>
              </div>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center gap-1'>
                  <input
                    className='form-check-input m-0'
                    style={{borderRadius: '3px', height: '12px', width: '12px'}}
                    type='checkbox'
                    checked={isLabel}
                    onChange={(e) => setLabel(e.target.checked)}
                    onKeyDown={handleKeyPress}
                  />
                  <label className='form-check-label text-dark fs-8 m-0 fw-normal'>
                    Field Label
                  </label>
                </div>
                <button
                  className='btn border border-danger text-danger fs-8 btn-sm py-0 px-3 h-25px align-self-end'
                  onClick={() => {
                    onDelete(index)
                    setEdit(false)
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TextField
