import {useState} from 'react'
import {OverlayTrigger} from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import {KTIcon} from '../../../../theme/helpers'
import {MatterDataType} from '../../../modules/auth'
import {DeleteMatterModal} from '../DeleteMatterModal'

type CardType = {
  item: MatterDataType
  handleDeleteMatter: any
}

type ClientItemType = {
  name: string
  _id: string
}

const CardComponent = ({item, handleDeleteMatter}: CardType) => {
  const [collapse, setCollapse] = useState(true)
  const [deleteClientMatterModal, setDeleteClientMatterModal] = useState(false)
  const [selectedMatter, setSelectedMatter] = useState<ClientItemType>({
    name: '',
    _id: '',
  })

  const onDeleteMatterClick = (matter: ClientItemType) => {
    setSelectedMatter(matter)
    setDeleteClientMatterModal(true)
  }

  return (
    <div
      key={item.client_id}
      className='col-sm-12 col-md-6 col-xl-3'
      style={{height: `${collapse && '100%'}`}}
    >
      {/* Modals */}
      <DeleteMatterModal
        open={deleteClientMatterModal}
        setOpen={setDeleteClientMatterModal}
        matterName={selectedMatter.name}
        handleDelete={() => handleDeleteMatter(selectedMatter._id, setDeleteClientMatterModal)}
      />

      <div
        className={`card rounded p-5 shadow-none border border-gray-300`}
        style={{maxHeight: `${!collapse && '400px'}`}}
      >
        <span className='card-label fw-bold fs-3 mb-1 rounded'>{item.client_name}</span>
        <span className='fw-semibold text-muted d-block fs-7'>
          {item.matters?.length} Client matters
        </span>
        <div className='separator border-3 my-3 border-gray-300' />
        <div className={`h-100 ${collapse ? 'overflow-hidden' : 'overflow-auto'}`}>
          {item.matters
            ?.slice(0, collapse ? 4 : item.matters?.length)
            .map((clientItem: ClientItemType) => (
              <div key={clientItem._id} className='d-flex pb-3 gap-3 justify-content-between'>
                <div>
                  <span className='fw-semibold fs-7'>{clientItem.name}</span>
                  {/* <span className='fw-semibold text-muted d-block fs-9'>Date Added 5/12/2023</span> */}
                </div>
                <OverlayTrigger
                  key={'tooltip-delete-matters'}
                  placement={'top'}
                  overlay={
                    <Tooltip id={`tooltip-delete-matter`} style={{position: 'fixed'}}>
                      Delete Matter
                    </Tooltip>
                  }
                >
                  {/* <span className='d-inline-block'> */}
                  <button
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm p-3'
                    onClick={() => onDeleteMatterClick(clientItem)}
                  >
                    <KTIcon iconName='trash' className='fs-3 text-primary' />
                  </button>
                  {/* </span> */}
                </OverlayTrigger>
              </div>
            ))}
        </div>
        <div>
          <div
            onClick={() => setCollapse(!collapse)}
            className='d-flex align-items-center gap-2 cursor-pointer mt-2'
          >
            <p className='m-0 text-primary fw-semibold'>Show {collapse ? 'more' : 'less'}</p>
            <KTIcon
              iconName={`${collapse ? 'down' : 'up'}`}
              className='text-primary fs-6 pt-1 fw-semibold'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardComponent
