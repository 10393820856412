import React, {useState} from 'react'
interface RadioElementProps {
  elementData: any
  onChange?: any
}

const RadioPdfElement = ({elementData, onChange}: RadioElementProps) => {
  const [option, setOption] = useState([...elementData?.options])

  const onSelectRadio = (item: any, index: number, isChecked: boolean) => {
    const updatedOptions = option.map((optionsItem: any, indexOp: number) => {
      if (indexOp === index) {
        return {...optionsItem, isChecked: isChecked}
      } else {
        return {...optionsItem, isChecked: false}
      }
    })
    setOption(updatedOptions)
    onChange(updatedOptions)
  }
  return (
    <div style={{gridColumn: `span ${elementData.col || 6}`}}>
      {elementData.isLabel && <p style={{fontWeight: 'bold', margin: 0}}>{elementData.label}</p>}

      <div style={{display: 'flex', gap: '8px', flexWrap: 'wrap'}}>
        {option.length > 0 &&
          option.map((option: any, index: number) => {
            return (
              <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <input
                  type='radio'
                  checked={option?.isChecked}
                  onChange={(e: any) => onSelectRadio(option, index, e.target.checked)}
                />
                <span>{option.optionName}</span>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export {RadioPdfElement}
