import { combineReducers } from '@reduxjs/toolkit'
import LoginSlice from './LoginSlice'
import ProfileDetailsSlice from './ProfileDetailsSlice'
import AttorneySlice from './AttorneySlice'
import MattersSlice from './MattersSlice'
import ClientSlice from './ClientSlice'
import FrequencySlice from './FrequencySlice'
import TemplateSlice from './TemplateSlice'
import ReportSlice from './ReportSlice'

export const reducers = combineReducers({
  auth: LoginSlice,
  profile: ProfileDetailsSlice,
  attorney: AttorneySlice,
  clientMatters: MattersSlice,
  clientSlice: ClientSlice,
  frequencySlice: FrequencySlice,
  templates: TemplateSlice,
  report: ReportSlice,
})
