/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {AsideTabs} from './AsideTabs'
import {AsideFooter} from './AsideFooter'
import {TabsBase} from './Tabs/_TabsBase'
import UpdateInfo from '../../../assets/image/updateinfo.png'
import {Logo} from '../../../../app/assets/icons/Logo'
const AsideDefault = () => {
  const {config} = useLayout()
  const {classes} = useLayout()
  const [link, setLink] = useState<string>('')
  const [asideIsActive, setAsideIsActive] = useState<string>('')
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/dashboard') {
      
      setLink('home')
    } else if (location.pathname === '/view-attorney') {
      setLink('user')
     
    } else if (location.pathname === '/profile') {
      setLink('')
    } else {
      setLink('projects')
      
    }

    setAsideIsActive('false')
    
  }, [location.pathname])

  useEffect(() => {
    if (asideIsActive === 'true') {
      document.body.removeAttribute('data-kt-aside-minimize')
    } else if (asideIsActive === 'false') {
      document.body.setAttribute('data-kt-aside-minimize', 'on')
    } else {
    }
  }, [asideIsActive])

  return (
    <div
      id='kt_aside'
      className={clsx('aside aside-extended', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='false'
      data-kt-drawer-width='auto'
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      {/* begin::Primary */}
      <div
        className='aside-primary d-flex flex-column align-items-lg-center flex-row-auto border-0'
        style={{backgroundColor: '#181818'}}
      >
        {/* begin::Logo */}
        <div
          className='aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-5'
          id='kt_aside_logo'
        >
          {/* <Link to='/pricing'> */}
          {/* <img src={Logo} alt="logo" className="h-50px w-auto"></img> */}
          {/* <img src={toAbsoluteUrl('/media/logos/demo7.svg')} alt='logo' className='h-35px' /> */}
          <Logo height={45} width={45} />
          {/* </Link> */}
        </div>
        {/* end::Logo */}
        {/* begin::Nav */}
        <div
          className='aside-nav d-flex flex-column align-items-center flex-column-fluid w-100 pt-5 pt-lg-0 '
          id='kt_aside_nav'
          style={{backgroundColor: '#181818'}}
        >
          <AsideTabs link={link} setLink={setLink} asideIsActive={asideIsActive} setAsideIsActive={setAsideIsActive} />
        </div>
        {/* end::Nav */}

        <AsideFooter setAsideIsActive={setAsideIsActive} />
      </div>
      {/* end::Primary */}
      {config.aside.secondaryDisplay && (
        <>
          {/* begin::Secondary */}
          <div
            className='aside-secondary d-flex flex-row-fluid m-0'
            style={{backgroundColor: '#2c3033'}}
          >
            {/* begin::Workspace */}
            <div className='aside-workspace my-5 p-5' id='kt_aside_wordspace'>
              <TabsBase link={link} />
            </div>
            {/* end::Workspace */}
          </div>
          {/* end::Secondary */}
          {/* begin::Aside Toggle */}
          <button
            id='kt_aside_toggle'
            className={clsx(
              'btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex',
              classes.asideToggle.join(' ')
            )}
            // data-kt-toggle='true'
            // data-kt-toggle-state='active'
            // data-kt-toggle-target='body'
            // data-kt-toggle-name='aside-minimize'
            style={{marginBottom: '1.35rem'}}
            onClick={() => {
              if (asideIsActive === 'true') {
                setAsideIsActive('false')
              } else {
                setAsideIsActive('true')
              }
              if(link === 'home' && asideIsActive === 'false')
              setLink('projects')
            }}
          >
            <div
              className='d-flex'
              style={{rotate: `${asideIsActive === 'false' ? '180deg' : '0deg'}`}}
            >
              <KTIcon iconName='arrow-left' className={`fs-2`} />
            </div>
          </button>
          {/* end::Aside Toggle */}
        </>
      )}
    </div>
  )
}

export {AsideDefault}
