import { ReactNode, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { RightToolbar } from '../partials/layout/RightToolbar'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider } from './core'
import {
  ActivityDrawer,
  DrawerMessenger,
  InviteUsers,
  ThemeModeProvider,
  UpgradePlan,
} from '../partials'
import { MenuComponent } from '../assets/ts/components'

type Props = {
  children?: ReactNode
}

const MasterLayout = ({ children }: Props) => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <ScrollTop />
        <div className='d-flex flex-column flex-root'>
          {/* begin::Page */}
          <div className='page d-flex flex-row flex-column-fluid'>
            <AsideDefault />
            {/* begin::Wrapper */}
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
              {/* <HeaderWrapper /> */}

              {/* begin::Content */}
              <div id='kt_content' className='content d-flex flex-column flex-column-fluid bg-white'>
                <Content>{children}</Content>
              </div>
              {/* end::Content */}
              {/* <Footer /> */}
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Page */}
        </div>

        {/* begin:: Drawers */}
        {/* <ActivityDrawer /> */}
        {/* <RightToolbar /> */}
        {/* <DrawerMessenger /> */}
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        {/* <InviteUsers /> */}
        {/* <UpgradePlan /> */}
        {/* end:: Modals */}
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export { MasterLayout }
