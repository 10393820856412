import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../theme/helpers'
import Button from '../../../../components/Button'
import SavedTempateIcon from '../../../../assets/icons/SavedTemplate'
import {useNavigate} from 'react-router-dom'

interface TemplateUpdatedModalProp {
  open: boolean
  setOpen: Function
}
const TemplateUpdatedModal = ({open, setOpen}: TemplateUpdatedModalProp) => {
  const navigate = useNavigate()
  const onClose = () => {
    setOpen(false)
  }
  return (
    <Modal show={open} centered onHide={onClose}>
      <div
        className='modal-content gap-8 rounded-3 d-flex justify-content-center'
        style={{padding: '30px'}}
      >
        <div className='modal-header border-0 p-0 flex-column align-items-center gap-3'>
          <SavedTempateIcon height={60} width={60} />
          <div className='d-flex flex-column gap-3 align-items-center'>
            <h1 className='m-0'>Template Updated!</h1>
            <p className='m-0 text-muted text-center'>
              The template was updated successfully, please <br /> return to the dashboard.
            </p>
          </div>
        </div>

        <div className='modal-footer border-0 p-0 justify-content-center'>
          <Button
            text='Go Back to Templates'
            className='btn-sm'
            // loading={loading}
            props={{type: 'button', onClick: () => navigate('/client-templates')}}
          />
        </div>
      </div>
    </Modal>
  )
}
export {TemplateUpdatedModal}
