import {SVGProps} from 'react'

const ArchiveTickIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 22 26' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clip-path='url(#clip0_234_42285)'>
        <path
          opacity='0.3'
          d='M18.5969 25.2035L12.1449 21.6129C11.7983 21.4198 11.4058 21.3183 11.0063 21.3183C10.6069 21.3183 10.2143 21.4198 9.86774 21.6129L3.41576 25.2035C3.0704 25.3973 2.67874 25.4995 2.27996 25.5C1.88118 25.5005 1.48926 25.3992 1.14341 25.2062C0.797569 25.0132 0.509926 24.7353 0.309264 24.4004C0.108602 24.0654 0.00195696 23.6851 0 23.2975V5.97189C0.00335033 4.51953 0.599287 3.12776 1.65706 2.10193C2.71484 1.07611 4.14808 0.499996 5.64232 0.5H16.3703C17.8624 0.503248 19.2924 1.08079 20.3474 2.10627C21.4025 3.13174 21.9967 4.52166 22 5.97189V23.2975C21.9968 23.6833 21.8899 24.0616 21.6899 24.3949C21.4899 24.7281 21.2037 25.0048 20.8598 25.1974C20.5159 25.39 20.1261 25.4919 19.7292 25.493C19.3322 25.494 18.9419 25.3942 18.5969 25.2035V25.2035Z'
          fill='#EEF6FF'
        />
      </g>
      <defs>
        <clipPath id='clip0_234_42285'>
          <rect width='22' height='25' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArchiveTickIcon
