import {memo} from 'react'
import {KTIcon} from '../../../../../theme/helpers'
import {EditProps} from './NumberField.propTypes'
import Left from '../../../../assets/icons/Left'

const EditView = memo(({onEditButton, state, handleNumberChange, onResize}: EditProps) => (
  <div
    className='border rounded ps-0 py-5 pe-5 d-flex align-items-center grab'
    style={{margin: '0px 0px'}}
  >
    <Left height={15} className='mx-3' />

    <div className='d-flex flex-column gap-2 w-100'>
      <div
        className={`d-flex justify-content-${state.isLabel ? 'between' : 'end'} align-items-center`}
      >
        {state.isLabel && <h6 className='m-0 fw-medium'>{state.title}</h6>}
        <div className='d-flex align-items-center gap-2'>
          <button
            className='btn align-self-end btn-icon btn-bg-light btn-active-color-primary btn-sm h-25px w-25px align-self-end pencil-div'
            style={{padding: '1px 2px 0px 0px'}}
            onClick={onResize}
          >
            <KTIcon iconName='arrow-two-diagonals' className='fs-2 pencil-icon-style' />
          </button>
          <button
            className='btn align-self-end btn-icon btn-bg-light btn-active-color-primary btn-sm p-0 h-25px w-25px align-self-end pencil-div'
            onClick={onEditButton}
          >
            <KTIcon iconName='pencil' className='fs-5 m-1 pencil-icon-style' />
          </button>
        </div>
      </div>
      <div className='input-group bg-gray-300 rounded overflow-hidden' style={{height: '38px'}}>
        <input
          type='text'
          className='form-control bg-transparent border-0 p-0 text-dark fs-6 fw-normal rounded-0 ms-3 custom-date-input'
          value={state.number || 'Insert Number'}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onEditButton()
            }
          }}
        />
      </div>
    </div>
  </div>
))

export default EditView
