import React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import { useRoutes } from '../hooks/useRoutes';
import { isTokenActivated } from '../utils/Helpers';

const Routing = ({...props}) => {

    const { routes, auth } = useRoutes()

  return <Routes {...props}>
        { routes?.map(({id, ...data}) => <Route key={id} index {...data} />) }
        <Route path='*' element={<Navigate replace to={isTokenActivated(auth?.result?.token) ? '/dashboard' : '/login'} />} />
  </Routes>
}

export default Routing
