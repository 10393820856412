import { FC, Fragment } from 'react'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import { useLayout } from '../../../core/LayoutProvider'
import { usePageData } from '../../../core/PageData'
import { Props } from '../../../../partials'

interface DefaultTitleProp {
  breadcrumbTitle?: string,
  onBackPress: Function
}

const DefaultTitle: FC<DefaultTitleProp> = ({ breadcrumbTitle, onBackPress }) => {
  const { pageTitle, pageDescription, pageBreadcrumbs } = usePageData()
  const { config, classes } = useLayout()
  const location = useLocation()
  return (
    <div
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
      className={clsx(
        'page-title d-flex flex-column align-items-start justify-content-center flex-wrap gap-8',
        classes.pageTitle.join(' ')
      )}
    >
      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <>
            <ul className='breadcrumb fw-bold fs-base m-0'>
              {
                location.pathname !== '/dashboard' && <span onClick={() => onBackPress()} className='badge badge-secondary p-3 gap-2' style={{ cursor: 'pointer' }}>Back</span>
              }
              {Array.from(pageBreadcrumbs)
                .filter((t) => !t.isSeparator && t.path === `/${location.pathname.split('/')[1]}`)
                .map((item, index) => (
                  <Fragment key={`${item.path}${index}`}>
                    {item.isActive ? (
                      <li className='breadcrumb-item text-dark'>{item.title}</li>
                    ) : (
                      <li className='breadcrumb-item text-muted'>
                        <span className='text-muted'>{item.title}</span>
                      </li>
                    )}
                  </Fragment>
                ))}
              <li className='breadcrumb-item text-dark'>{breadcrumbTitle || pageTitle}</li>
            </ul>
          </>
        )}

      {/* begin::Heading */}
      <h1 className='text-dark fw-bolder fs-2qx mt-0 mx-0'>
        {pageTitle && pageTitle}
        {pageDescription && (
          <span className='text-muted fs-6 fw-normal ms-1'>{pageDescription}</span>
        )}
      </h1>
      {/* end::Heading */}
    </div>
  )
}

export { DefaultTitle }
