import { useRef } from "react";

type InputRefType = React.RefObject<HTMLInputElement>;

// Custom hook for managing focus
const useFocus = (): { inputRef: InputRefType; focusInput: () => void } => {
  const inputRef = useRef<HTMLInputElement>(null);

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return { inputRef, focusInput };
};

export {useFocus}