import React from 'react'

interface TextFieldElementProps {
  elementData: any
  onChangeText?: any
}
const TextFieldElement = ({elementData, onChangeText}: TextFieldElementProps) => {
  return (
    <div className={`col-${elementData.col || 4}`}>
      <div className='d-flex flex-column'>
        {elementData.isLabel && <p className='fs-7 fw-bold mt-0 mb-1'>{elementData.label}</p>}

        <div className='input-group input-group-sm'>
          <input
            className='form-control'
            value={elementData?.value || ''}
            placeholder={elementData.defaultValue}
            onChange={onChangeText}
          />
        </div>
      </div>
    </div>
  )
}
export {TextFieldElement}
