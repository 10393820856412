import React, { useEffect, useState } from 'react'
import { PageTitle } from '../../../theme/layout/core'
import { useAppDispatch, useAppSelector } from '../../redux/redux-hook/hooks'
import { fetchprofileDetails } from '../../redux/reducer/ProfileDetailsSlice'
import { dashboardBreadCrumbs } from '../../../utils/constants'
import { DefaultTitle } from '../../../theme/layout/components/header/page-title/DefaultTitle'
import { KTIcon } from '../../../theme/helpers'
import Button from '../../components/Button'
import moment from 'moment'
import { sendForgotMail } from '../../modules/auth/core/_requests'
import { useNavigate } from 'react-router-dom'

const Profile = () => {
  const dispatch = useAppDispatch()
  const { result } = useAppSelector((state) => state.auth.user.data) as any
  const [changePasswordLoading, setChangePasswordLoading] = useState(false)
  const navigate = useNavigate()
  const [passwordAlert, setPasswordAlert] = useState(false)

  const onPasswordChange = async () => {
    try {
      setChangePasswordLoading(true)
      const res = await sendForgotMail({ email: result.email })
      setChangePasswordLoading(false)
      
      setPasswordAlert(true)
      setTimeout(() => setPasswordAlert(false), 2500)
    } catch (error) {
      setChangePasswordLoading(false)
      
    }
  }

  useEffect(() => {
    dispatch(fetchprofileDetails(result?.token))
  }, [dispatch, result?.token])

  return (
    <div className='vh-100' style={{ padding: '50px 0px' }}>
      {/* Page Head */}
      <div>
        <PageTitle breadcrumbs={dashboardBreadCrumbs}>Attorney Profile</PageTitle>
        <DefaultTitle onBackPress={() => {
          navigate(-1)
        }} />
        <div className='row' style={{ marginTop: '30px' }}>
          <div className='col-4'>
            <div
              className='card h-100 border d-flex justify-content-between shadow-sm'
              style={{ padding: '30px' }}
            >
              <div className='d-flex flex-column gap-3'>
                <div className='d-flex align-items-center gap-3'>
                  <h2 className='fs-bold m-0'>{result.first_name + ' ' + result.last_name}</h2>
                  <span className='fs-7 badge badge-light-primary p-2'>
                    {result.role
                      .split('_')
                      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')}
                  </span>
                </div>
                <div className='d-flex align-items-center gap-2'>
                  <KTIcon iconName='send' className='fs-5 text-gray-700' />
                  <h6 className='m-0 text-gray-700'>{result?.email}</h6>
                </div>
                <div className='d-flex align-items-center gap-2'>
                  <KTIcon iconName='book' className='fs-5 text-gray-700' />
                  <h6 className='m-0 text-gray-700'>#{result.bar_number}</h6>
                </div>
              </div>
              <span className='fs-7 fs-bold text-muted'>
                Account Created {moment(result.created_date).format('MM/DD/YYYY')}
              </span>
            </div>
          </div>
          <div className='col-8'>
            <div className='card h-100 border shadow-sm' style={{ padding: '30px' }}>
              <div className='border border-top-0 border-left-0 border-right-0 border-bottom-dashed border-bottom-1 border-gray-300 d-flex flex-column gap-2 pb-4 mb-4'>
                <h2 className='m-0'>Attorney Details</h2>
                <span className='m-0 fs-7 text-gray-700'>
                  Contact your admin to change the information displayed in Attorney Details.
                </span>
              </div>
              <div className='d-flex justify-content-start gap-12 flex-wrap'>
                <div className='d-flex gap-5'>
                  <div className='d-flex flex-column gap-3'>
                    <p className='m-0 fw-semibold fs-6'>Full Name</p>
                    <p className='m-0 fw-semibold fs-6'>Email</p>
                  </div>
                  <div className='d-flex flex-column gap-3'>
                    <p className='m-0 fs-6'>{result.first_name + ' ' + result.last_name}</p>
                    <p className='m-0 fs-6'>{result.email}</p>
                  </div>
                </div>
                <div className='d-flex gap-5'>
                  <div className='d-flex flex-column gap-3'>
                    <p className='m-0 fw-semibold fs-6'>Bar Number</p>
                    <p className='m-0 fw-semibold fs-6'>State</p>
                  </div>
                  <div className='d-flex flex-column gap-3'>
                    <p className='m-0 fs-6'>{result.bar_number}</p>
                    <p className='m-0 fs-6'>{result.states.join(', ')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12' style={{ marginTop: '20px' }}>
            <div className='card h-100 border shadow-sm' style={{ padding: '30px' }}>
              <div
                className='border border-top-0  border-left-0 border-right-0 border-bottom-solid border-bottom-1'
                style={{ margin: '0px -30px' }}
              >
                <h2 className='mb-5' style={{ marginLeft: '30px' }}>
                  Manage Account
                </h2>
              </div>
              <div className='border border-top-0  border-left-0 border-right-0 border-bottom-dashed border-bottom-1 border-gray-300 d-flex justify-content-between align-items-center py-4 mb-4'>
                <div className='d-flex flex-column gap-4'>
                  <h4 className='m-0'>Email Address</h4>
                  <h6 className='fs-7 m-0 text-gray-600'>{result?.email}</h6>
                </div>
                {/* <div className='d-flex align-items-center gap-5'>
                  <Button text='Change Email' className='btn-secondary py-0 h-40px px-4' />
                </div> */}
              </div>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex flex-column gap-4'>
                  <h4 className='m-0'>Password</h4>
                  <h6 className='fs-7 m-0 text-gray-600'>************</h6>
                </div>
                <div className='d-flex align-items-center gap-5'>
                  {passwordAlert && (
                    <div className='alert alert-success border-success d-flex align-items-center p-3 m-0 border-dashed'>
                      <span className='svg-icon svg-icon-2hx svg-icon-success me-3'>
                        <KTIcon iconName='shield-tick' className='fs-3x text-success' />
                      </span>
                      <div className='d-flex flex-column'>
                        <h6 className='mb-1'>Success!</h6>
                        <span className='fs-7'>A link was sent to your email.</span>
                      </div>
                    </div>
                  )}
                  <Button
                    text='Reset Password'
                    className='btn-secondary py-0 h-40px px-4'
                    loading={changePasswordLoading}
                    props={{ disabled: changePasswordLoading, onClick: onPasswordChange }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export { Profile }
