import * as React from 'react'
import {SVGProps} from 'react'
const SectionHeadingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect x='1' y='1' width='12' height='12' rx='2' stroke={props.stroke} />
    <rect
      x='3.57153'
      y='3.57104'
      width='6.85714'
      height='6.85714'
      rx='1'
      stroke={props.stroke}
      strokeWidth='0.75'
    />
    <path
      d='M5.28589 5.71326H8.71446'
      stroke={props.stroke}
      strokeWidth='0.75'
      strokeLinecap='round'
    />
  </svg>
)
export default SectionHeadingIcon
