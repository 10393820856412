import {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import SearchComponent from './SearchComponent'
import {
  callCreateMatter,
  callDeleteMatter,
  fetchClientMatters,
} from '../../redux/reducer/MattersSlice'
import {RootStateTypes} from '../../modules/auth'
import CardComponent from './CardComponent'
import {PageTitle} from '../../../theme/layout/core'
import {DefaultTitle} from '../../../theme/layout/components/header/page-title/DefaultTitle'
import {dashboardBreadCrumbs} from '../../../utils/constants'
import Button from '../../components/Button'
import {CreateMatterModal} from './CreateMatterModal'
import {useAppSelector} from '../../redux/redux-hook/hooks'
import {callAllClient} from '../../redux/reducer/ClientSlice'
import {CustomSelect} from '../../components/CustomSelect'
import {Pagination} from '../../components/Pagination'
import {useNavigate} from 'react-router-dom'

const Matters = () => {
  const [mattersParam, setMattersParam] = useState({
    list_all: 1,
    client_id: '',
    search: '',
    page: 1,
    limit: 10,
  })
  const dispatch = useDispatch()
  const token = useSelector((state: RootStateTypes) => state.auth.user.data.result.token)
  const clients = useAppSelector((state) => state.clientSlice.allClients.client)

  const [createClientMatterModal, setCreateClientMatterModal] = useState(false)

  const {data: clientMattersState, loading} = useSelector(
    (state: RootStateTypes) => state.clientMatters
  )
  const navigate = useNavigate()
  const handleSearch = (e: any) => {
    const {value} = e.target
    setMattersParam({...mattersParam, search: value})
  }

  const getClientMatters = useCallback(() => {
    dispatch(fetchClientMatters({...mattersParam, token}))
  }, [dispatch, mattersParam, token])

  const onDeleteClientMatter = (id: string, setModalOpen: any) => {
    dispatch(callDeleteMatter({id, token})).then((res: any) => {
      if (res.payload.data?.success) {
        getClientMatters()
        setModalOpen(false)
      }
    })
  }

  const onCreateClientMatter = (matter: any) => {
    dispatch(callCreateMatter({...matter, token})).then((res: any) => {
      if (res.payload.data?.success) {
        getClientMatters()
        setCreateClientMatterModal(false)
      }
    })
  }

  const onFilterClient = (value: any) => {
    setMattersParam({...mattersParam, client_id: value._id, list_all: value._id ? 0 : 1})
  }

  const onPageChange = (page: number) => {
    setMattersParam({...mattersParam, page: page})
  }

  useEffect(() => {
    dispatch(callAllClient({token}))
    getClientMatters()
  }, [dispatch, getClientMatters, token])

  return (
    <div className='vh-100' style={{padding: '50px 0px'}}>
      {/* Modals */}
      <CreateMatterModal
        open={createClientMatterModal}
        setOpen={setCreateClientMatterModal}
        onCreateMatter={onCreateClientMatter}
      />

      {/* Page Head */}
      <div>
        <PageTitle breadcrumbs={dashboardBreadCrumbs}>Client matters</PageTitle>
        <DefaultTitle
          onBackPress={() => {
            navigate(-1)
          }}
        />
        <hr className='text-gray-500 mb-0' style={{marginTop: '25px'}} />
        <div className=' d-flex justify-content-between' style={{padding: '20px 0px'}}>
          <div className='d-flex gap-3'>
            <SearchComponent
              handleSearch={handleSearch}
              loading={loading && mattersParam.search.length !== 0}
            />
          </div>
          <div className='d-flex gap-3'>
            <CustomSelect
              className='w-175px h-45px'
              name='filterLocation'
              value={mattersParam.client_id}
              placeHolder='Filter Location'
              options={[...clients, {_id: '', name: 'Reset Filter'}] || []}
              onChange={onFilterClient}
            />
            <Button text='New Matter' props={{onClick: () => setCreateClientMatterModal(true)}} />
          </div>
        </div>
      </div>
      <div style={{height: 'calc(100% - 252px)'}}>
        <div className='h-100 overflow-x-hidden overflow-y-auto position-relative'>
          <div className='row row-gap-6'>
            {clientMattersState?.result?.data?.map((item, key) => (
              <CardComponent key={key} item={item} handleDeleteMatter={onDeleteClientMatter} />
            ))}
          </div>
          {clientMattersState.result.data?.length === 0 && (
            <div
              className='position-absolute top-0 left-0 right-0 h-100 d-flex justify-content-center align-items-center w-100'
            >
              <h1 className='m-0 fs-2x text-gray-400'>No Data Found</h1>
            </div>
          )}
        </div>
      </div>
      <div className='h-auto d-flex justify-content-end' style={{padding: '20px 0px'}}>
        <Pagination
          currentPage={mattersParam.page}
          totalPages={clientMattersState.result.total_page}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  )
}

export default Matters
