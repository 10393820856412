import {Modal} from 'react-bootstrap'
import Button from '../../../../components/Button'
import {KTIcon} from '../../../../../theme/helpers'
import ArchiveTickIcon from '../../../../assets/icons/ArchiveTickIcon'

interface DialogBoxProps {
  showDialog: boolean
  cancelNavigation: any
  confirmNavigation: any
}

const DialogBox: React.FC<DialogBoxProps> = ({showDialog, cancelNavigation, confirmNavigation}) => {
  return (
    <Modal show={showDialog} centered>
      <div
        className='modal-content gap-8 rounded-3 d-flex justify-content-center'
        style={{padding: '30px'}}
      >
        <div className='modal-header border-0 p-0 flex-column align-items-center gap-3'>
          <div className='bg-primary p-4 rounded-circle'>
            <ArchiveTickIcon fill='none' height={30} width={30} />
          </div>
          {/* <SavedTempateIcon height={60} width={60} /> */}
          <div className='d-flex flex-column gap-3 align-items-center'>
            <h1 className='m-0'>Leave This Page ?</h1>
            <p className='m-0 text-muted text-center'>Please save the changes before going back.</p>
          </div>
        </div>

        <div className='modal-footer border-0 p-0 justify-content-center'>
          <Button className='btn-sm' text='No' props={{onClick: cancelNavigation}} />
          <Button className='btn-sm btn-danger' text='Yes' props={{onClick: confirmNavigation}} />
        </div>
      </div>
    </Modal>
    // <Modal show={showDialog}>
    //     <Modal.Header>
    //         <Modal.Title>Warning</Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body>
    //         <b>There are some changes?</b>
    //         <br /> Are you sure you want to navigate!!!!
    //     </Modal.Body>
    //     <Modal.Footer>
    //         <Button variant="primary" onClick={cancelNavigation}>
    //             No
    //         </Button>
    //         <Button variant="danger" onClick={confirmNavigation}>
    //             Yes
    //         </Button>
    //     </Modal.Footer>
    // </Modal>
  )
}
export default DialogBox
