import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../theme/helpers'
import Button from '../../../../components/Button'
import SavedTempateIcon from '../../../../assets/icons/SavedTemplate'
import {useNavigate} from 'react-router-dom'

interface CompleteSaveModalProp {
  open: boolean
  setOpen: Function
  setReviewReportModalOpen: any
}
const CompleteSaveModal = ({open, setOpen, setReviewReportModalOpen}: CompleteSaveModalProp) => {
  const navigate = useNavigate()
  const onClose = () => {
    setOpen(false)
  }
  return (
    <Modal show={open} centered onHide={onClose}>
      <div
        className='modal-content gap-8 rounded-3 d-flex justify-content-center'
        style={{padding: '30px'}}
      >
        <div className='modal-header border-0 p-0 flex-column align-items-center gap-3'>
          {/* <KTIcon
                        iconName='file'
                        className='fs-2hx text-light bg-primary rounded-circle p-4'
                    /> */}
          <SavedTempateIcon height={60} width={60} />
          <div className='d-flex flex-column gap-3 align-items-center'>
            <h1 className='m-0'>Template Saved!</h1>
            <p className='m-0 text-muted text-center'>
              Your template was saved successfully, return <br /> to the dashboard or create another
              report.
            </p>
          </div>
        </div>

        <div className='modal-footer border-0 p-0 justify-content-center'>
          <Button
            text='Dashboard'
            className='btn-secondary btn-sm'
            props={{
              onClick: () => {
                setOpen(false)
                navigate('/dashboard')
              },
            }}
          />
          <Button
            text='Create Another'
            className='btn-sm'
            // loading={loading}
            props={{type: 'button', onClick: onClose}}
          />
          <Button
            text='Assign Report'
            className='btn-sm'
            // loading={loading}
            props={{
              type: 'button',
              onClick: () => {
                setReviewReportModalOpen(true)
                onClose()
              },
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
export {CompleteSaveModal}
