import React, {DragEvent, Fragment, useEffect, useMemo, useState} from 'react'
import {KTIcon} from '../../../theme/helpers'
import {PageTitle} from '../../../theme/layout/core'
import {DefaultTitle} from '../../../theme/layout/components/header/page-title/DefaultTitle'
import {
  dashboardBreadCrumbs,
  reportInputElements,
  reportLayoutElements,
} from '../../../utils/constants'
import {ReportElementCard} from '../../widgets/ReportElementCard'

import SectionDivider from './dropable-component/section-divider'
import {SectionHeading} from './dropable-component/section-heading'
import DateField from './dropable-component/date-field'
import NumberField from './dropable-component/number-field'
import TextField from './dropable-component/text-field'
import {SelectField} from './dropable-component/select-field'
import CheckBoxField from './dropable-component/check-box'
import RadioButtonField from './dropable-component/radio-button'
import {TextArea} from './dropable-component/text-area'
import {useAppDispatch, useAppSelector} from '../../redux/redux-hook/hooks'
import {createTemplate, updateTemplate} from '../../modules/auth/core/_requests'
import {CompleteSaveModal} from './Modal/saved-complete-modal'
import {ReviewReportModal} from './Modal/review-report-modal'
import {AssignReportCompleteModal} from './Modal/assign-report-completed-modal'
import {ReviewTemplateModal} from './Modal/review-template-modal'
import {useSelector} from 'react-redux'
import {RootStateTypes} from '../../modules/auth'
import {useNavigate, useParams} from 'react-router-dom'
import {callGetTemplateById, clearTemplateDetails} from '../../redux/reducer/TemplateSlice'
import {TemplateUpdatedModal} from './Modal/template-updated-modal'
import {GoBackAlertModal} from './Modal/back-alert-modal'
import {useCallbackPrompt} from '../../../hooks/useCallBackPropmpt'
import DialogBox from './Modal/browser-back-aletr'
import {useFocus} from '../../../hooks/useFocus'

type ReportInputElementsType = {
  title: string
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  type?: string
}

const TemplateBuilder = () => {
  const {template_details} = useAppSelector((state) => state.templates.template_detail) as any

  const [formElements, setFormElements] = useState<ReportInputElementsType[]>([])
  const [updatedFormElements, setUpdatedFormElements] = useState<ReportInputElementsType[]>([])
  const [draggedElement, setDraggedElement] = useState(null)
  const authUser = useSelector((state: RootStateTypes) => state.auth.user.data)
  const dispatch = useAppDispatch()
  const [completeSave, setCompleteSave] = useState(false)
  const [assignReportComplete, setAssignReportComplete] = useState(false)
  const [templateData, setTemplateData] = useState({})
  const [reviewTemplateModalOpen, setReviewTemplateModalOpen] = useState(false)
  const [reviewReportModalOpen, setReviewReportModalOpen] = useState(false)
  const [templateUpdatedModalOpen, setTemplateUpdatedModalOpen] = useState(false)
  const [goBackModalVisible, setGoBackModalVisible] = useState(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog)
  const [saveTemplateLoading, setSaveTemplateLoading] = useState(false)
  // const history = useHistory()
  const {inputRef: titleRef, focusInput: titleFocusInput} = useFocus()
  const {inputRef: descriptionRef, focusInput: descriptionFocusInput} = useFocus()
  const [reportFormData, setReportFormData] = useState({
    title: 'Untitled Report',
    description: 'Add Description',
  })

  const navigate = useNavigate()

  const {templateId} = useParams()

  const onStartDrag = (e: DragEvent<HTMLDivElement>, element: ReportInputElementsType) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(element))
  }

  const handleFormDragStart = (e: DragEvent<HTMLDivElement>, index: any) => {
    setDraggedElement(index)
  }

  const handleFormDragOver = (e: DragEvent<HTMLDivElement>, index: any) => {
    e.preventDefault()
    if (draggedElement === null || draggedElement === index) {
      return
    }
    const updatedElements = [...updatedFormElements]
    const draggedElementData = updatedElements[draggedElement]
    updatedElements.splice(draggedElement, 1)
    updatedElements.splice(index, 0, draggedElementData)
    setUpdatedFormElements(updatedElements)
    setDraggedElement(index) // Up
  }

  const handleFormDragEnd = (e: DragEvent<HTMLDivElement>, index: any) => {
    e.preventDefault()
    // if (draggedElement === null || draggedElement === index) {
    //   return
    // }
    // const updatedElements = [...formElements]
    // const draggedElementData = updatedElements[draggedElement]
    // updatedElements.splice(draggedElement, 1)
    // updatedElements.splice(index, 0, draggedElementData)
    setFormElements(updatedFormElements)
    // setDraggedElement(index) // Up
    setDraggedElement(null)
  }

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (e.dataTransfer.getData('text/plain') !== '') {
      const elementData = JSON.parse(e.dataTransfer.getData('text/plain'))
      setFormElements([...formElements, elementData])
    }
  }

  const onDeleteElement = (index: number) => {
    const newFormElements = formElements.filter((_, i) => i !== index)
    setFormElements(newFormElements)
  }

  const onDuplicateElement = (indexToDuplicate: number) => {
    setFormElements((prevFormElements) => {
      return prevFormElements.flatMap((element, index) => {
        if (index === indexToDuplicate) {
          return [element, element] // Duplicate the element
        } else {
          return [element]
        }
      })
    })
  }

  const onSaveElement = (data: any, index: any) => {
    const newFormElements = formElements.map((element, i) => {
      if (i === index) {
        return {...element, ...data}
      }
      return element
    })
    // console.log(data, 'datata')
    // let form_data = formElements
    // let formObject = form_data[index]
    // console.log('formObject: ', formObject)
    // let finalData = {
    //   ...formObject,
    //   ...data,
    // }
    // form_data[index] = finalData
    // console.log('finalData: ', form_data)
    setFormElements(newFormElements)
  }

  const handleSaveTemplate = (value: any) => {
    // console.log('value: ', value)
    let params = {
      name: value.templateName,
      description: value.description,
      client_id: value.location,
      claim_number: value.claimNumber,
      file_number: value.fileNumber,
      frequency_id: value.frequency,
      template_json: formElements,
      token: authUser?.result?.token,
    }
    setSaveTemplateLoading(true)
    createTemplate(params)
      .then((res) => {
        setTemplateData(res.data.result)
        setReviewTemplateModalOpen(false)
        setFormElements([])
        setShowDialog(false)
        setCompleteSave(true)
        setSaveTemplateLoading(false)
      })
      .catch((error: any) => {
        setSaveTemplateLoading(false)
        console.log('Error', error)
      })
  }

  useMemo(() => {
    setFormElements(formElements)
  }, [formElements])

  useMemo(() => {
    setUpdatedFormElements(formElements)
  }, [formElements])

  useEffect(() => {
    if (formElements.length !== 0) {
      setShowDialog(true)
    } else {
      setShowDialog(false)
    }
  }, [formElements])

  const renderElement = (type: string | undefined, index: number, elementData: any) => {
    switch (type) {
      case 'section-heading':
        return (
          <SectionHeading
            index={index}
            onDelete={onDeleteElement}
            onDuplicate={onDuplicateElement}
            handleFormDragStart={(e: DragEvent<HTMLDivElement>) => handleFormDragStart(e, index)}
            handleFormDragOver={(e: DragEvent<HTMLDivElement>) => handleFormDragOver(e, index)}
            handleFormDragEnd={(e: DragEvent<HTMLDivElement>) => handleFormDragEnd(e, index)}
            onSave={(data: any) => onSaveElement(data, index)}
            elementData={elementData}
          />
        )
      case 'section-divider':
        return (
          <SectionDivider
            index={index}
            onDelete={onDeleteElement}
            onDuplicate={onDuplicateElement}
            handleFormDragStart={(e: DragEvent<HTMLDivElement>) => handleFormDragStart(e, index)}
            handleFormDragOver={(e: DragEvent<HTMLDivElement>) => handleFormDragOver(e, index)}
            handleFormDragEnd={(e: DragEvent<HTMLDivElement>) => handleFormDragEnd(e, index)}
            onSave={(data: any) => onSaveElement(data, index)}
            elementData={elementData}
          />
        )
      case 'date-field':
        return (
          <DateField
            index={index}
            onDelete={onDeleteElement}
            onDuplicate={onDuplicateElement}
            onSave={onSaveElement}
            values={elementData.values}
            handleFormDragStart={(e: DragEvent<HTMLDivElement>) => handleFormDragStart(e, index)}
            handleFormDragOver={(e: DragEvent<HTMLDivElement>) => handleFormDragOver(e, index)}
            handleFormDragEnd={(e: DragEvent<HTMLDivElement>) => handleFormDragEnd(e, index)}
          />
        )
      case 'number-field':
        return (
          <NumberField
            handleFormDragStart={(e: DragEvent<HTMLDivElement>) => handleFormDragStart(e, index)}
            handleFormDragOver={(e: DragEvent<HTMLDivElement>) => handleFormDragOver(e, index)}
            handleFormDragEnd={(e: DragEvent<HTMLDivElement>) => handleFormDragEnd(e, index)}
            index={index}
            onDelete={onDeleteElement}
            onDuplicate={onDuplicateElement}
            onSave={(data: any) => onSaveElement(data, index)}
            elementData={elementData}
          />
        )
      case 'select-field':
        return (
          <SelectField
            index={index}
            onDelete={onDeleteElement}
            onDuplicate={onDuplicateElement}
            onSave={onSaveElement}
            values={elementData.values}
            handleFormDragStart={(e: DragEvent<HTMLDivElement>) => handleFormDragStart(e, index)}
            handleFormDragOver={(e: DragEvent<HTMLDivElement>) => handleFormDragOver(e, index)}
            handleFormDragEnd={(e: DragEvent<HTMLDivElement>) => handleFormDragEnd(e, index)}
          />
        )
      case 'text-field':
        return (
          <TextField
            handleFormDragStart={(e: DragEvent<HTMLDivElement>) => handleFormDragStart(e, index)}
            handleFormDragOver={(e: DragEvent<HTMLDivElement>) => handleFormDragOver(e, index)}
            handleFormDragEnd={(e: DragEvent<HTMLDivElement>) => handleFormDragEnd(e, index)}
            onSave={(data: any) => onSaveElement(data, index)}
            onDuplicate={onDuplicateElement}
            index={index}
            onDelete={onDeleteElement}
            elementData={elementData}
          />
        )
      case 'check-box':
        return (
          <CheckBoxField
            index={index}
            onDelete={onDeleteElement}
            onDuplicate={onDuplicateElement}
            onSave={onSaveElement}
            values={elementData.values}
            handleFormDragStart={(e: DragEvent<HTMLDivElement>) => handleFormDragStart(e, index)}
            handleFormDragOver={(e: DragEvent<HTMLDivElement>) => handleFormDragOver(e, index)}
            handleFormDragEnd={(e: DragEvent<HTMLDivElement>) => handleFormDragEnd(e, index)}
          />
        )
      case 'radio-button':
        return (
          <RadioButtonField
            handleFormDragStart={(e: any) => handleFormDragStart(e, index)}
            handleFormDragOver={(e: any) => handleFormDragOver(e, index)}
            handleFormDragEnd={(e: DragEvent<HTMLDivElement>) => handleFormDragEnd(e, index)}
            index={index}
            onDelete={onDeleteElement}
            onDuplicate={onDuplicateElement}
            onSave={(data: any) => onSaveElement(data, index)}
            elementData={elementData}
          />
        )
      case 'text-area':
        return (
          <TextArea
            index={index}
            onDelete={onDeleteElement}
            onDuplicate={onDuplicateElement}
            onSave={onSaveElement}
            values={elementData.values}
            handleFormDragStart={(e: DragEvent<HTMLDivElement>) => handleFormDragStart(e, index)}
            handleFormDragOver={(e: DragEvent<HTMLDivElement>) => handleFormDragOver(e, index)}
            handleFormDragEnd={(e: DragEvent<HTMLDivElement>) => handleFormDragEnd(e, index)}
          />
        )
      default:
        return null
    }
  }

  const onSaveAndUpdatedTemplate = async () => {
    if (templateId) {
      try {
        const updateValues = {
          id: templateId,
          template_json: formElements,
          token: authUser?.result?.token,
        }
        await updateTemplate(updateValues)
        setShowDialog(false)
        setTemplateUpdatedModalOpen(true)
      } catch (error) {
        console.log('Error:', error)
      }
    } else {
      setReviewTemplateModalOpen(true)
    }
  }

  useEffect(() => {
    if (templateId)
      dispatch(
        callGetTemplateById({
          report_id: templateId,
          token: authUser.result.token,
        })
      )
  }, [authUser.result.token, dispatch, templateId])

  useEffect(() => {
    setFormElements(template_details.template_json || [])
  }, [dispatch, template_details])

  useEffect(() => {
    return () => {
      dispatch(clearTemplateDetails())
    }
  }, [dispatch, templateId])

  // useEffect(() => {
  //   // window.addEventListener('beforeunload', handleBackButton);
  //   // window.addEventListener
  //   window.onbeforeunload = handleBackButton
  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     // window.removeEventListener('beforeunload', handleBackButton);
  //     window.onbeforeunload = null;
  //   };
  // })
  // const handleBackButton = (event: any) => {
  //   console.log("Called Event");
  //   event.returnValue = 'hihhi';
  //   if (window.confirm('hihhi')) {
  //     event.returnValue = null; // Allow the user to leave the page.
  //   }
  //   if (templateId) {
  //     onSaveAndUpdatedTemplate()
  //   } else {
  //     setGoBackModalVisible(false)
  //     setReviewTemplateModalOpen(true)
  //   }

  // }
  return (
    <div className='vh-100' style={{padding: '50px 0px'}}>
      {/* Modal */}
      <CompleteSaveModal
        open={completeSave}
        setOpen={setCompleteSave}
        setReviewReportModalOpen={setReviewReportModalOpen}
      />
      <ReviewTemplateModal
        open={reviewTemplateModalOpen}
        setOpen={setReviewTemplateModalOpen}
        onSave={handleSaveTemplate}
        reportFormData={reportFormData}
        loading={saveTemplateLoading}
      />
      <ReviewReportModal
        open={reviewReportModalOpen}
        setOpen={setReviewReportModalOpen}
        setAssignReportComplete={setAssignReportComplete}
        templateData={templateData}
      />
      <AssignReportCompleteModal open={assignReportComplete} setOpen={setAssignReportComplete} />
      <TemplateUpdatedModal open={templateUpdatedModalOpen} setOpen={setTemplateUpdatedModalOpen} />
      <GoBackAlertModal
        open={goBackModalVisible}
        setOpen={setGoBackModalVisible}
        onSaveChange={() => {
          //  navigate('/dashboard')
          if (templateId) {
            onSaveAndUpdatedTemplate()
          } else {
            setGoBackModalVisible(false)
            setReviewTemplateModalOpen(true)
          }
        }}
        // onCloseEvent={cancelNavigation}
      />
      <DialogBox
        // @ts-ignore
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      {/* Page Head */}
      <div>
        <PageTitle breadcrumbs={dashboardBreadCrumbs}>Report Builder</PageTitle>
        <DefaultTitle
          onBackPress={() => {
            navigate(-1)
          }}
        />
        <hr className='text-gray-500 mb-0' style={{marginTop: '25px'}} />
        <div className='d-flex w-auto align-items-center gap-5' style={{padding: '20px 0px'}}>
          <div className='d-flex gap-3 w-100'>
            {templateId ? (
              <div className='d-flex flex-column gap-2 mw-300px'>
                <h3 className='fs-3 fs-bold m-0'>{template_details.name}</h3>
                <p
                  className='m-0 fs-8'
                  style={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {template_details.description}
                </p>
              </div>
            ) : (
              <div className='d-flex flex-column gap-2 w-auto mw-800px overflow-hidden'>
                <div className='d-flex align-items-center gap-2 position-relative'>
                  <span
                    id='hidden-title-text'
                    className='fs-2 fw-bold position-absolute'
                    style={{zIndex: -1, opacity: 0}}
                  >
                    {reportFormData.title}
                  </span>
                  <input
                    type='text'
                    ref={titleRef}
                    id='report-title-text'
                    className='form-control bg-transparent border-0 p-0 text-dark fs-2 fw-bold rounded-0 opacity-100'
                    value={reportFormData.title}
                    style={{width: '165px', minWidth: '165px'}}
                    onChange={(e) => {
                      setReportFormData({...reportFormData, title: e.target.value})
                      const getHiddenTitle = document.getElementById(
                        'hidden-title-text'
                      ) as HTMLElement
                      getHiddenTitle.textContent = e.target.value
                      const width = getHiddenTitle.offsetWidth as any
                      const getTitle = document.getElementById('report-title-text') as HTMLElement
                      getTitle.style.width = width + 'px'
                    }}
                    onBlur={() => {
                      if (reportFormData.title.trim().length <= 0) {
                        setReportFormData({...reportFormData, title: 'Untitled Report'})
                      }
                    }}
                  />
                  <button
                    className='btn align-self-end btn-icon btn-bg-light btn-active-color-primary btn-sm p-0 h-25px w-25px align-self-end pencil-div'
                    onClick={() => titleFocusInput()}
                  >
                    <KTIcon iconName='pencil' className='fs-6 pencil-icon-style' />
                  </button>
                </div>
                <div className='d-flex align-items-center gap-2 position-relative'>
                  <span
                    id='hidden-description-text'
                    className='fs-7 fw-normal position-absolute'
                    style={{zIndex: -1, opacity: 0}}
                  >
                    {reportFormData.description}
                  </span>
                  <input
                    type='text'
                    id='report-description-text'
                    className='form-control bg-transparent border-0 p-0 text-dark fs-7 fw-normal rounded-0'
                    style={{width: '110px', minWidth: '110px'}}
                    ref={descriptionRef}
                    value={reportFormData.description}
                    onChange={(e) => {
                      setReportFormData({...reportFormData, description: e.target.value})
                      const getHiddenDescription = document.getElementById(
                        'hidden-description-text'
                      ) as HTMLElement
                      getHiddenDescription.textContent = e.target.value
                      const width = getHiddenDescription.offsetWidth as any
                      const getDescription = document.getElementById(
                        'report-description-text'
                      ) as HTMLElement
                      getDescription.style.width = width + 'px'
                    }}
                    onBlur={() => {
                      if (reportFormData.description.trim().length <= 0) {
                        setReportFormData({...reportFormData, description: 'Add Description'})
                      }
                    }}
                  />
                  <button
                    className='btn align-self-end btn-icon btn-bg-light btn-active-color-primary btn-sm p-0 h-25px w-25px align-self-end pencil-div'
                    onClick={() => descriptionFocusInput()}
                  >
                    <KTIcon iconName='pencil' className='fs-8 pencil-icon-style' />
                  </button>
                </div>
              </div>
            )}
          </div>
          <button
            className='btn bg-white border border-primary text-primary save-template-btn text-nowrap'
            onClick={onSaveAndUpdatedTemplate}
          >
            {!templateId ? 'Save Template' : 'Update Template'}
          </button>
        </div>
      </div>

      <div style={{height: 'calc(100% - 205px)'}}>
        <div className='row h-100'>
          <div className='col-9 h-100'>
            <div
              className='bg-gray-200 h-100 rounded-3 border-solid overflow-auto'
              style={{padding: '20px'}}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <form
                className='bg-white rounded-3'
                style={{padding: '20px'}}
                onSubmit={(e) => e.preventDefault()}
              >
                {formElements && formElements.length > 0 ? (
                  <div className='row gx-3 gy-4'>
                    {formElements.map((element: ReportInputElementsType, index: number) => {
                      return (
                        <Fragment key={index}>
                          {renderElement(element.type, index, element)}
                        </Fragment>
                      )
                    })}
                  </div>
                ) : (
                  <div className='rounded-3 h-200px border border-gray-200 h-100 d-flex flex-column align-items-center justify-content-center gap-3'>
                    <KTIcon
                      iconName='abstract-14'
                      className='text-white bg-primary p-3 rounded-circle fs-2'
                    />
                    <h1 className='m-0'>Your Report is Empty</h1>
                    <p className='m-0'>Drag a report element to get started</p>
                  </div>
                )}
              </form>
            </div>
          </div>
          <div className='col-3 h-100'>
            <div
              className='h-100 rounded-3 border border-gray-300 overflow-auto'
              style={{padding: '20px'}}
            >
              <h3 className='m-0'>Report Elements</h3>
              <p className='mx-0 my-3 fs-8 text-gray-500 fst-bold fw-medium'>INPUTS</p>
              <div
                className='d-flex flex-column gap-2'
                onDragStart={() => {
                  const elements = document.getElementsByClassName(
                    'text-area-div'
                  ) as HTMLCollectionOf<HTMLElement>
                  for (let i = 0; i < elements.length; i++) {
                    elements[i].style.pointerEvents = 'none'
                  }
                }}
                onDragEnd={() => {
                  const elements = document.getElementsByClassName(
                    'text-area-div'
                  ) as HTMLCollectionOf<HTMLElement>
                  for (let i = 0; i < elements.length; i++) {
                    elements[i].style.pointerEvents = 'auto'
                  }
                }}
              >
                {reportInputElements.map((element, key) => {
                  return (
                    <ReportElementCard
                      key={key}
                      {...element}
                      onDragStart={(e: DragEvent<HTMLDivElement>) => onStartDrag(e, element)}
                    />
                  )
                })}
              </div>
              <p className='mx-0 fs-8 my-3 text-gray-500 fst-bold fw-medium'>LAYOUT</p>
              <div className='d-flex flex-column gap-2'>
                {reportLayoutElements.map((element, key) => {
                  return (
                    <ReportElementCard
                      key={key}
                      {...element}
                      onDragStart={(e: DragEvent<HTMLDivElement>) => onStartDrag(e, element)}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {TemplateBuilder}
