import React, {useMemo, useState} from 'react'
import {KTIcon} from '../../../../../theme/helpers'

interface SelectFelidProps {
  values: any
  index: number
  onChanage?: any
}
const SelectFieldElement = ({values, index, onChanage}: SelectFelidProps) => {
  const [selectOpen, setSelectOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(values.selectedOption)
  const [selectOptions, setSelectOptions] = useState(values.selectOptions)

  const onOptionSelect = (option: {title: string}) => {
    setSelectedOption(option.title)
    setSelectOpen(false)
    onChanage(option?.title)
  }

  useMemo(() => {
    setSelectedOption(values.selectedOption)
  }, [values.selectedOption])

  return (
    <div className={`col-${values.col || 4}`}>
      {values.isLabel && <p className='fs-7 fw-bold mt-0 mb-1'>{values.labelTitle}</p>}

      <div className='position-relative'>
        <div
          className={`bg-gray-200 collapsed cursor-pointer border border-gray-300 px-3 d-flex justify-content-between align-items-center ${
            selectOpen ? 'border-bottom-0 rounded-top' : 'rounded'
          }`}
          style={{height: '34.8px'}}
          data-bs-toggle='collapse'
          data-bs-target={`#select-options${index}`}
          onClick={() => setSelectOpen(!selectOpen)}
        >
          <h6 className='m-0 fs-7 fw-medium'>{selectedOption}</h6>
          <KTIcon iconName={`${selectOpen ? 'up' : 'down'}`} className='fs-4 text-dark' />
        </div>
        <div
          className='collapse position-absolute w-100'
          id={`select-options${index}`}
          style={{zIndex: 1}}
        >
          <div className='border border-gray-300 border-top-0 rounded-bottom px-3 h-100 bg-gray-200 opacity-100'>
            {selectOptions.map((option: any, key: number) => (
              <div
                className='py-3 cursor-pointer'
                data-bs-toggle='collapse'
                data-bs-target={`#select-options${index}`}
                key={key}
                onClick={() => onOptionSelect(option)}
              >
                <h6 className='m-0 fs-7 fw-medium'>{option.title}</h6>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export {SelectFieldElement}
