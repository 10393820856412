import React, {useState} from 'react'

const CheckBoxPdfElement = ({values, index: mainIndex, checkBoxOnChange}: any) => {
  const [checkboxOptions, setCheckboxOptions] = useState([...values.checkboxOptions])

  const onEditOption = (index: number, value: {title: string; isChecked: boolean}) => {
    const updatedData = checkboxOptions.map((option: any, i: number) => {
      if (i === index) {
        return {title: value.title, isChecked: value.isChecked}
      }
      return option
    })
    setCheckboxOptions(updatedData)
    checkBoxOnChange(mainIndex, updatedData)
  }

  return (
    <div style={{gridColumn: `span ${values.col || 6}`}}>
      {values?.isLabel && <p style={{fontWeight: 'bold', margin: 0}}>{values.labelTitle}</p>}

      <div style={{display: 'flex', gap: '8px', flexWrap: 'wrap'}}>
        {checkboxOptions.map((option: any, index: number) => (
          <div style={{display: 'flex', alignItems: 'center', gap: '4px'}} key={index}>
            <input
              type='checkbox'
              style={{borderRadius: '3px'}}
              checked={option.isChecked}
              onChange={(e) =>
                onEditOption(index, {title: option.title, isChecked: e.target.checked})
              }
            />
            <label>{option.title}</label>
          </div>
        ))}
      </div>
    </div>
  )
}
export {CheckBoxPdfElement}
