import React, {useEffect, useMemo, useState} from 'react'

const CheckBoxElement = ({values, index: mainIndex, checkBoxOnChange}: any) => {
  const [checkboxOptions, setCheckboxOptions] = useState([...values.checkboxOptions])

  const onEditOption = (index: number, value: {title: string; isChecked: boolean}) => {
    const updatedData = checkboxOptions.map((option: any, i: number) => {
      if (i === index) {
        return {title: value.title, isChecked: value.isChecked}
      }
      return option
    })
    setCheckboxOptions(updatedData)
    checkBoxOnChange(mainIndex, updatedData)
  }

  useMemo(() => {
    setCheckboxOptions(values.checkboxOptions)
  }, [values.checkboxOptions])

  return (
    <div className={`col-${values.col || 6}`}>
      {values?.isLabel && <p className='fs-7 fw-bold mt-0 mb-1'>{values.labelTitle}</p>}

      <div className='d-flex align-align-items-center flex-wrap gap-3'>
        {checkboxOptions.map((option: any, index: number) => (
          <div className='d-flex align-items-center gap-2' key={index}>
            <input
              type='checkbox'
              className='form-check-input h-15px w-15px'
              style={{borderRadius: '3px'}}
              checked={option.isChecked}
              onChange={(e) =>
                onEditOption(index, {title: option.title, isChecked: e.target.checked})
              }
            />
            <label className='fs-7 fw-medium'>{option.title}</label>
          </div>
        ))}
      </div>
    </div>
  )
}
export {CheckBoxElement}
