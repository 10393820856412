import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getClients, getPublicClients } from '../../modules/auth/core/_requests'
import { ClientParamModal } from '../../modules/auth'


export const callAllClient = createAsyncThunk(
    'clientSlice/callAllClient',
    async (params: ClientParamModal, { rejectWithValue }) => {
        // console.log('params: ', params);
        try {

            return await getClients(params)
        } catch (error) {
            console.log('error: ', error);

            return rejectWithValue(error)
        }
    }
) as any

export const callPrivateClient = createAsyncThunk(
    'clientSlice/callPrivateClient',
    async (params: ClientParamModal, { rejectWithValue }) => {
        try {
            return await getClients(params)
        } catch (error) {
            return rejectWithValue(error)
        }
    }
) as any

export const callPublicClient = createAsyncThunk(
    'clientSlice/callPublicClient',
    async (params: any, { rejectWithValue }) => {
        // console.log('params: ', params);
        try {
            return await getPublicClients(params)
        } catch (error) {
            return rejectWithValue(error)
        }
    }
) as any


const initialState = {
    allClients: {
        loading: false, client: [], error: null
    },
    privateClient: {
        loading: false, client: [], error: null
    },
    publicClient: {
        loading: false, client: [], error: null
    }
}

const ClientSlice = createSlice({
    name: 'clientSlice',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {

        builder
            .addCase(callAllClient.pending, (state) => {
                state.allClients.loading = true
            })
            .addCase(callAllClient.fulfilled, (state, action) => {
                // console.log("action", action);
                state.allClients.loading = false
                state.allClients.client = action.payload.data.result
            })
            .addCase(callAllClient.rejected, (state, action) => {
                state.allClients.loading = false
            })

            .addCase(callPrivateClient.pending, (state) => {
                state.privateClient.loading = true
            })
            .addCase(callPrivateClient.fulfilled, (state, action) => {
                // console.log("action", action);
                state.privateClient.loading = false
                // state.clients.client
            })
            .addCase(callPrivateClient.rejected, (state, action) => {
                state.privateClient.loading = false
            })

            .addCase(callPublicClient.pending, (state) => {
                state.publicClient.loading = true
            })
            .addCase(callPublicClient.fulfilled, (state, action) => {
                // console.log("action", action);
                state.publicClient.loading = false
                state.publicClient.client = action.payload.data.result
            })
            .addCase(callPublicClient.rejected, (state, action) => {
                state.publicClient.loading = false
            })

    },
})

export default ClientSlice.reducer
