import React, {DragEvent, useMemo, useState} from 'react'
import {KTIcon} from '../../../../../theme/helpers'
import RadioButtonIcon from '../../../../assets/icons/RadioButtonIcon'
import Left from '../../../../assets/icons/Left'
import {useFocus} from '../../../../../hooks/useFocus'

interface elementProps {
  handleFormDragStart: (e: DragEvent<HTMLDivElement>) => void
  handleFormDragOver: (e: DragEvent<HTMLDivElement>) => void
  handleFormDragEnd: (e: DragEvent<HTMLDivElement>) => void
  onSave: (param: any) => void
  onDuplicate: (param: number) => void
  index: number
  onDelete: (param: number) => void
  elementData: any
}
const RadioButtonField = ({
  handleFormDragStart,
  handleFormDragOver,
  handleFormDragEnd,
  onDuplicate,
  onDelete,
  index,
  onSave,
  elementData,
}: elementProps) => {
  const [edit, setEdit] = useState(false)
  const [isLabel, setLabel] = useState(elementData.isLabel)
  const [options, setOptions] = useState(elementData.options || []) as any
  const [title, setTitle] = useState(elementData.label)

  const {inputRef: radioFieldLabel, focusInput: radioFieldLabelFocusInput} = useFocus()
  const {inputRef: radioBtnLabel, focusInput: radioBtnLabelFocusInput} = useFocus()

  const onAddOption = () => {
    setOptions([...options, {isChecked: false, optionName: 'Name'}])
  }
  const onSelectRadio = (item: any, index: number) => {
    const updatedData = options.map((optionsItem: any, indexOp: number) => {
      if (indexOp === index) {
        return {...optionsItem, isChecked: true}
      } else {
        return {...optionsItem, isChecked: false}
      }
    })
    setOptions(updatedData)
  }

  useMemo(() => {
    setLabel(elementData.isLabel)
    setTitle(elementData.label)
    setOptions(elementData.options)
  }, [elementData])

  const handleSave = () => {
    let param = {
      isLabel: isLabel,
      options: options,
      label: title,
    }
    onSave(param)
    setEdit(false)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleSave()
    }
  }

  const onResize = () => {
    if (elementData.col === 12) {
      onSave({col: 6})
    } else {
      onSave({col: 12})
    }
  }

  return (
    <div
      className={`col-${elementData.col || 6}`}
      draggable
      onDragStart={handleFormDragStart}
      onDragOver={handleFormDragOver}
      onDragEnd={handleFormDragEnd}
    >
      {edit ? (
        // Edit View
        <div className='border rounded overflow-hidden'>
          {/* Top Section */}
          <div className='d-flex justify-content-between bg-gray-300 py-3 px-4'>
            <div className='d-flex align-items-center gap-2'>
              <div
                className='bg-dark d-flex align-items-center'
                style={{borderRadius: '3px', padding: '4px'}}
              >
                <RadioButtonIcon height={14} fill='none' stroke='white' />
              </div>

              <p className='m-0 fw-bold fs-7'>Radio Button</p>
            </div>
            <div className='d-flex align-items-center gap-2'>
              <div
                className='d-flex align-items-center gap-1 cursor-pointer'
                onClick={() => onDuplicate(index)}
              >
                <KTIcon iconName='copy' className='fs-6 text-dark' />
                <p className='m-0 fs-8 fw-semibold'>Duplicate</p>
              </div>
              <span
                className='badge badge-primary fs-8 py-0 px-3 fw-semibold cursor-pointer h-25px rounded-1'
                onClick={handleSave}
              >
                Save
              </span>
            </div>
          </div>

          {/* Middle Section */}
          <div className='p-4 d-flex flex-column'>
            {isLabel && (
              <div
                className='input-group border border-primary px-3 py-2 w-175px mb-3 gap-3'
                style={{borderRadius: '3px'}}
              >
                <input
                  type='text'
                  ref={radioFieldLabel}
                  className='form-control bg-transparent border-0 p-0 text-dark fs-6 fw-normal rounded-0'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <span
                  className='input-group-text bg-transparent border-0 p-0 rounded-0 cursor-pointer'
                  onClick={() => radioFieldLabelFocusInput()}
                >
                  <KTIcon iconName='pencil' className='fs-6 text-primary' />
                </span>
              </div>
            )}
            <div
              className='border border-primary p-3 d-flex flex-column gap-3'
              style={{borderRadius: '3px'}}
            >
              <div className='d-flex align-align-items-center flex-wrap gap-3'>
                {options.map((option: any, index: number) => (
                  <div className='d-flex align-items-center gap-1'>
                    <input
                      type='radio'
                      className='form-radio-input h-15px w-15px'
                      style={{borderRadius: '3px'}}
                      checked={option.isChecked}
                      onChange={(e) => onSelectRadio(option, index)}
                      onKeyDown={handleKeyPress}
                    />
                    <input
                      type='text'
                      id={`child-radio-btn-label-${index}`}
                      className='form-control bg-transparent border-0 p-0 text-dark fs-6 fw-normal rounded-0 align-self-start mw-60px'
                      value={option.optionName}
                      onChange={(e) => {
                        const updatedOptions = [...options]
                        updatedOptions[index] = {
                          ...updatedOptions[index],
                          optionName: e.target.value,
                        }
                        setOptions(updatedOptions)
                      }}
                      onBlur={() => {
                        if (option.optionName.length === 0) {
                          if (index < 2) {
                            options[index].optionName = 'Name'
                            setOptions([...options])
                          } else {
                            setOptions(options.filter((_: any, i: number) => i !== index))
                          }
                        }
                      }}
                      onKeyDown={handleKeyPress}
                    />
                    <span
                      className='cursor-pointer'
                      onClick={() =>
                        document.getElementById(`child-radio-btn-label-${index}`)?.focus()
                      }
                    >
                      <KTIcon iconName='pencil' className='fs-7 text-primary' />
                    </span>
                  </div>
                ))}
                <div
                  className='d-flex align-interpolation gap-1 cursor-pointer'
                  onClick={onAddOption}
                >
                  <KTIcon iconName='plus-circle' className='fs-3 text-primary' />
                  <p className='text-primary fs-7 fw-normal m-0'>Add Option</p>
                </div>
              </div>
            </div>

            <div className='separator my-3' />

            {/* Bottom Section */}
            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-align-items-center gap-1'>
                <input
                  className='form-check-input h-15px w-15px m-0'
                  style={{borderRadius: '3px'}}
                  type='checkbox'
                  checked={isLabel}
                  onChange={(e) => setLabel(e.target.checked)}
                  onKeyDown={handleKeyPress}
                />
                <label className='form-check-label text-dark fs-7 m-0 fw-normal'>Field Label</label>
              </div>
              <button
                className='btn border border-danger text-danger fs-8 btn-sm py-0 px-3 h-25px align-self-end'
                onClick={() => {
                  onDelete(index)
                  setEdit(false)
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      ) : (
        // Main View
        <div
          className='border rounded ps-0 py-5 pe-5 d-flex align-items-center grab'
          style={{margin: '0px 0px'}}
        >
          <Left height={15} className='mx-3' />

          <div className='d-flex flex-column gap-2 w-100'>
            <div
              className={`d-flex justify-content-${isLabel ? 'between' : 'end'} align-items-center`}
            >
              {isLabel && <h6 className='m-0 fw-medium'>{title}</h6>}
              <div className='d-flex align-items-center gap-2'>
                <button
                  className='btn align-self-end btn-icon btn-bg-light btn-active-color-primary btn-sm h-25px w-25px align-self-end pencil-div'
                  style={{padding: '1px 2px 0px 0px'}}
                  onClick={onResize}
                >
                  <KTIcon iconName='arrow-two-diagonals' className='fs-2 pencil-icon-style' />
                </button>
                <button
                  className='btn align-self-end btn-icon btn-bg-light btn-active-color-primary btn-sm p-0 h-25px w-25px align-self-end pencil-div'
                  onClick={() => {
                    setEdit(true)
                  }}
                >
                  <KTIcon iconName='pencil' className='fs-5 m-1 pencil-icon-style' />
                </button>
              </div>
            </div>
            <div className='d-flex align-align-items-center flex-wrap gap-3'>
              {options.map((option: any, index: number) => (
                <div className='d-flex align-items-center gap-2'>
                  <input
                    type='radio'
                    className='form-radio-input h-15px w-15px'
                    style={{borderRadius: '3px'}}
                    checked={option.isChecked}
                    onChange={(e) => onSelectRadio(option, index)}
                    onKeyDown={handleKeyPress}
                  />
                  <label>{option.optionName}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default RadioButtonField
