import { SVGProps } from 'react'

interface CustomProps extends SVGProps<SVGSVGElement> {
  cirColorValue?:string
}


const RadioButtonIcon = (props: CustomProps) => {
  const { cirColorValue, ...restProps } = props;

  const circolorvalue = cirColorValue ? cirColorValue : '#F3F6F9';

  return (
    <svg {...restProps} viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'>
      <rect x='1' y='1' width='12' height='12' rx='2' stroke={props.stroke} />
      <circle cx='7' cy='7' r='1.75' fill='#2C3033' />
      <circle cx='7' cy='7' r='3' fill={circolorvalue} />
    </svg>
  );
};

export default RadioButtonIcon;

