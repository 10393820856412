import {Link} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../../helpers'
import {Dropdown1, Search} from '../../../../partials'
import {useAppDispatch, useAppSelector} from '../../../../../app/redux/redux-hook/hooks'
import {useEffect} from 'react'
import {useRoutes} from '../../../../../hooks/useRoutes'

const projects: ReadonlyArray<{title: string; link: string; icon: string}> = [
  {
    title: 'Create Attorney',
    link: '/view-attorney?create-attorney=true',
    icon: 'user',
  },
  {
    title: 'View Attorney',
    link: '/view-attorney',
    icon: 'profile-user',
  },
]

const UserTab = () => {
  const {sidebarTwoRoutes} = useRoutes()
  return (
    <div className='m-0 '>
      {/* begin::Toolbar */}
      <div className='d-flex mb-10'>
        {/* begin::Filter */}
        {/* <div className='flex-shrink-0 ms-2'> */}
        {/* begin::Menu toggle */}
        {/* <button
            type='button'
            className='btn btn-icon btn-bg-light btn-active-icon-primary btn-color-gray-400'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <KTIcon iconName='filter' className='fs-2' /> */}
        {/* </button> */}
        {/* end::Menu toggle */}

        {/* <Dropdown1 />
        </div> */}
        {/* end::Filter */}
      </div>
      {/* end::Toolbar */}

      {/*begin::Projects*/}
      <div className='m-0'>
        {/*begin::Heading*/}

        <h1 className='text-white fw-bold mb-6 mx-5 fs-2'>Attorney Manager</h1>
        {/*end::Heading*/}

        {/*begin::Items*/}
        <div className='mb-10'>
          {sidebarTwoRoutes.map((p) => (
            <Link
              key={p.name}
              to={p.path}
              className='custom-list d-flex align-items-center px-5 py-4'
            >
              <KTIcon iconName={p.icon || 'user'} className='fs-2 text-white' />
              {/*begin::Description*/}
              <div className='d-flex flex-column flex-grow-1 mx-2 px-1'>
                {/*begin::Title*/}

                <h5 className='custom-list-title fw-bold text-white m-0'>{p.name}</h5>
                {/*end::Title*/}
              </div>
              {/*begin::Description*/}
              <KTIcon iconName={'right'} className='fs-2 mx-1' />
            </Link>
          ))}
        </div>
        {/*end::Items*/}
      </div>
      {/*end::Projects*/}
    </div>
  )
}

export {UserTab}
