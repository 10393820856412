import {memo, useState} from 'react'
import {KTIcon} from '../../../../../theme/helpers'
import {DisplayViewProps} from './NumberField.propTypes'
import NumberFieldIcon from '../../../../assets/icons/NumberFieldIcon'
import {useFocus} from '../../../../../hooks/useFocus'

const EditViewComponent = memo(
  ({
    saveButton,
    state,
    onLabelChange,
    onNumberChange,
    onDuplicate,
    onDelete,
    onChangeLabelText,
  }: DisplayViewProps) => {
    const [localState, setLocalState] = useState({
      isLabelEdit: false,
      isInputEdit: true,
    })
    const {inputRef: numberRef, focusInput: numberInputRef} = useFocus()
    const {inputRef: insertNumberRef, focusInput: insertNumberInputRef} = useFocus()

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === 'Enter') {
        saveButton()
      }
    }

    return (
      <div className='border rounded overflow-hidden'>
        {/* Top Section */}
        <div className='d-flex justify-content-between bg-gray-300 py-3 px-4'>
          <div className='d-flex align-items-center gap-2'>
            <div
              className='bg-dark d-flex align-items-center'
              style={{borderRadius: '3px', padding: '4px'}}
            >
              <NumberFieldIcon height={14} fill='none' stroke='white' />
            </div>

            <p className='m-0 fw-bold fs-7'>Number Field</p>
          </div>
          <div className='d-flex align-items-center gap-2'>
            <div className='d-flex align-items-center gap-1 cursor-pointer' onClick={onDuplicate}>
              <KTIcon iconName='copy' className='fs-6 text-dark' />
              <p className='m-0 fs-8 fw-semibold'>Duplicate</p>
            </div>
            <span
              className='badge badge-primary fs-8 py-0 px-3 fw-semibold cursor-pointer h-25px rounded-1'
              onClick={saveButton}
            >
              Save
            </span>
          </div>
        </div>

        {/* Middle Section */}
        <div className='p-4 d-flex flex-column'>
          {state.isLabel && (
            <div
              className='input-group border border-primary px-3 py-2 w-175px mb-3 gap-3'
              style={{borderRadius: '3px'}}
            >
              <input
                type='text'
                ref={numberRef}
                className='form-control bg-transparent border-0 p-0 text-dark fs-6 fw-normal rounded-0'
                value={state.title}
                onChange={onChangeLabelText}
                onKeyDown={handleKeyPress}
              />
              <span
                className='input-group-text bg-transparent border-0 p-0 rounded-0 cursor-pointer'
                onClick={() => numberInputRef()}
              >
                <KTIcon iconName='pencil' className='fs-6 text-primary' />
              </span>
            </div>
          )}
          <div className='border rounded bg-gray-300 d-flex justify-content-between align-items-center overflow-hidden'>
            <div
              className='input-group border border-primary px-3 py-1 gap-3 mx-2 my-2 w-175px'
              style={{borderRadius: '3px'}}
            >
              <input
                className='border border-0 bg-transparent form-control p-0 rounded-0'
                type='number'
                ref={insertNumberRef}
                value={state.number}
                onChange={(e) => onNumberChange(e.target.value)}
                onKeyDown={handleKeyPress}
                placeholder='Insert Number'
              />

              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary input-group-text bg-transparent btn-sm'
                style={{height: '25px', width: '25px'}}
                onClick={() => {
                  setLocalState((prevState) => ({
                    ...prevState,
                    isInputEdit: !prevState.isInputEdit,
                  }))
                  insertNumberInputRef()
                }}
              >
                <KTIcon iconName='pencil' className='fs-8 text-primary ml-2' />
              </button>
            </div>
          </div>
          <div className='separator my-3' />

          {/* Bottom Section */}
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex align-align-items-center gap-1'>
              <input
                className='form-check-input h-15px w-15px m-0'
                style={{borderRadius: '3px'}}
                type='checkbox'
                checked={state.isLabel}
                onChange={onLabelChange}
                onKeyDown={handleKeyPress}
              />
              <label className='form-check-label text-dark fs-7 m-0 fw-normal'>Field Label</label>
            </div>
            <button
              className='btn border border-danger text-danger fs-8 btn-sm py-0 px-3 h-25px align-self-end'
              onClick={onDelete}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    )
  }
)

export default EditViewComponent
