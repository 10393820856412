import React, {useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../theme/helpers'
import Button from '../../../../components/Button'
import ArchiveTickIcon from '../../../../assets/icons/ArchiveTickIcon'
import InputBox from '../../../../components/InputBox'
import {CustomSelect} from '../../../../components/CustomSelect'
import {clients, reportFrequency, state} from '../../../../../utils/constants'
import DateFieldIcon from '../../../../assets/icons/DateFieldIcon'
import {useFormik} from 'formik'
import {reviewTemplateValidationSchema} from '../../../../../utils/validations'
import {useAppDispatch, useAppSelector} from '../../../../redux/redux-hook/hooks'
import {callAllClient} from '../../../../redux/reducer/ClientSlice'
import {useSelector} from 'react-redux'
import {RootStateTypes} from '../../../../modules/auth'
import {callFrequency} from '../../../../redux/reducer/FrequencySlice'

interface ReviewTemplateModalProps {
  open: boolean
  setOpen: Function
  onSave: (param: any) => void
  reportFormData: any
  loading: boolean
}

export const ReviewTemplateModal: React.FC<ReviewTemplateModalProps> = ({
  open,
  setOpen,
  onSave,
  reportFormData,
  loading,
}) => {
  const dispatch = useAppDispatch()

  const authUser = useSelector((state: RootStateTypes) => state.auth.user.data)
  const frequency = useAppSelector((state) => state.frequencySlice.frequency.frequencies)
  const Allclient = useAppSelector((state) => state.clientSlice.allClients.client)
  const onClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    let param = {token: authUser?.result?.token}
    dispatch(callFrequency())
    dispatch(callAllClient(param))
  }, [])

  const {
    handleChange,
    handleSubmit,
    touched,
    handleBlur,
    errors,
    values,
    resetForm,
    setFieldValue,
  }: any = useFormik({
    initialValues: {
      templateName: reportFormData.title,
      description: reportFormData.description,
      location: '',
      frequency: '',
    },
    validationSchema: reviewTemplateValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      
      onSave(values)
      // const {firstName, lastName, barNumber, email, accountRole} = values
      // const data: createAttorneyModal = {
      //   first_name: firstName,
      //   last_name: lastName,
      //   states: ['64c0ced7e8e816c54f44eca6'],
      //   bar_number: barNumber,
      //   email,
      //   role: accountRole,
      // }
      // dispatch(callCreateAttorney(data)).then((res: any) => {
      //   if (res.payload.data.success) {
      //     onClose()
      //   }
      // })
    },
  })

  const templateLocationOnChange = (value: {_id: string; name: string}) => {
    setFieldValue('location', value._id)
  }

  const reportFrequencyOnChange = (value: any) => {
    // console.log('Frequency Onchange', value)
    setFieldValue('frequency', value._id)
  }

  useEffect(() => {
    if (!open) resetForm()
  }, [open, resetForm])

  useEffect(() => {
    //   token: string,
    // only_clients?: number,
    // isAll?: boolean
    // console.log("auth User", authUser.result?.token);
    let param = {
      token: authUser?.result?.token,
      isAll: true,
    }
    dispatch(callAllClient(param))
    dispatch(callFrequency({token: authUser?.result?.token}))
  }, [open])
  return (
    <Modal show={open} centered className='modal-lg' onHide={onClose}>
      <div className='modal-content gap-8 rounded-3' style={{padding: '40px'}}>
        <div className='modal-header border-0 p-0 flex-column align-items-start gap-3'>
          {/* <KTIcon iconName='folder' className='fs-2hx text-light bg-primary rounded-circle p-4' /> */}
          <div className='bg-primary p-4 rounded-circle'>
            <ArchiveTickIcon fill='none' height={30} width={30} />
          </div>
          <div className='d-flex flex-column gap-3'>
            <h1 className='m-0'>Review Your Template</h1>
            <p className='m-0'>
              Confirm the template details, choose a date frequency, and then select where you would
              like to save it.
            </p>
          </div>
        </div>
        <div className='modal-body border rounded' style={{padding: '30px'}}>
          <form className='d-flex flex-column mw-100' onSubmit={(e) => e.preventDefault()}>
            <div className='mw-100'>
              <div
                className='d-flex gap-3 align-items-end position-relative w-100 overflow-hidden'
                style={{maxWidth: 'calc(100% - 25px)'}}
              >
                <span
                  id='template-name-hidden'
                  className='position-absolute opacity-0 '
                  style={{zIndex: -1, opacity: 0, fontSize: '1.1rem', fontWeight: 500}}
                >
                  {values.templateName}
                </span>
                <InputBox
                  label='TEMPLATE NAME'
                  labelClassName='fs-8 text-gray-500'
                  className='p-0 border-0 rounded-0'
                  props={{
                    id: 'template-name-field',
                    type: 'text',
                    name: 'templateName',
                    value: values.templateName,
                    style: {width: '165px', minWidth: '165px'},
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      const getHiddenTemplateName = document.getElementById(
                        'template-name-hidden'
                      ) as HTMLElement
                      getHiddenTemplateName.textContent = e.target.value
                      const width = getHiddenTemplateName.offsetWidth as any
                      
                      const getTemplateName = document.getElementById(
                        'template-name-field'
                      ) as HTMLElement
                      getTemplateName.style.width = width + 'px'
                      setFieldValue('templateName', e.target.value)
                    },
                    onBlur: handleBlur,
                  }}
                />
                <div style={{display: 'flex', marginRight: '50px'}}>
                  <KTIcon iconName='pencil' className='fs-6 text-primary mb-2' />
                </div>
              </div>
              {Boolean(touched.templateName && errors.templateName) && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{touched.templateName && errors.templateName}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='separator my-4' />
            <div>
              <label className='form-label fw-bolder fs-8 text-gray-500'>DESCRIPTION</label>
              <div className='d-flex justify-content-between gap-2 border border-primary p-2 rounded'>
                <textarea
                  className='form-control p-0 border-0 rounded-0'
                  id='exampleFormControlTextarea1'
                  rows={4}
                  name='description'
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <KTIcon iconName='pencil' className='fs-6 text-primary mb-2' />
              </div>
              {Boolean(touched.description && errors.description) && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{touched.description && errors.description}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='separator my-4' />
            <div>
              <label className='form-label fw-bolder fs-8 text-gray-500'>
                LOCATION AND FREQUENCY
              </label>
              <div className='d-flex gap-3'>
                <div className='min-w-225px'>
                  <CustomSelect
                    name='location'
                    placeHolder='Template Location'
                    options={Allclient}
                    value={values.location}
                    onChange={templateLocationOnChange}
                    error={touched.location && errors.location}
                  />
                </div>
                <div className='min-w-225px'>
                  <CustomSelect
                    name='frequency'
                    placeHolder='Report Frequency'
                    options={frequency}
                    value={values.frequency}
                    onChange={reportFrequencyOnChange}
                    icon={<DateFieldIcon fill='none' height={16} stroke='black' />}
                    error={touched.frequency && errors.frequency}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className='modal-footer border-0 p-0 justify-content-start'>
          <Button text='Cancel' className='btn-secondary' props={{onClick: onClose}} />
          <Button
            text='Save Template'
            loading={loading}
            props={{type: 'button', onClick: handleSubmit, disabled: loading}}
          />
        </div>
      </div>
    </Modal>
  )
}
