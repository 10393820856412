import React, {Fragment, useEffect, useMemo, useState} from 'react'
import {PageTitle} from '../../../theme/layout/core'
import {DefaultTitle} from '../../../theme/layout/components/header/page-title/DefaultTitle'
import {dashboardBreadCrumbs} from '../../../utils/constants'
import Button from '../../components/Button'
import {useNavigate, useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../redux/redux-hook/hooks'
import {SectionHeadingElement} from '../reports/repport-element/section-heading-element'
import {SectionDividerElement} from '../reports/repport-element/section-devider-element'
import {NumberFieldElement} from '../reports/repport-element/number-field-element'
import {DateFieldElement} from '../reports/repport-element/date-field-element'
import {TextFieldElement} from '../reports/repport-element/text-field-element'
import {RadioButtonElement} from '../reports/repport-element/radio-button-element'
import {CheckBoxElement} from '../reports/repport-element/check-box-field-element'
import {TextAreaElement} from '../reports/repport-element/text-area-element'
import {SelectFieldElement} from '../reports/repport-element/select-field-element'
import {RootStateTypes} from '../../modules/auth'
import {useSelector} from 'react-redux'
import {status} from '../../../utils/Helpers'
import {callGetTemplateById, clearTemplateDetails} from '../../redux/reducer/TemplateSlice'
import {AssignReportModal} from './Modals/AssignReportModal'
import {AssignReportCompleteModal} from './Modals/AssignReportCompleteModal'
import {assignReport} from '../../modules/auth/core/_requests'

const CreateReport = () => {
  const dispatch = useAppDispatch()
  const location = useParams()
  const {token, role, _id} = useSelector((state: RootStateTypes) => state.auth.user.data.result)
  const {template_details} = useAppSelector((state) => state.templates.template_detail) as any
  // console.log('template_details: ', template_details)
  const [formElements, setFormElements] = useState<any>([])

  const [assignReportModalOpen, setAssignReportModalOpen] = useState(false)
  const [assignReportCompleteModalOpen, setAssignReportCompleteModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const renderElement = (type: string | undefined, index: number, elementData: any) => {
    switch (type) {
      case 'section-heading':
        return <SectionHeadingElement elementData={elementData} />
      case 'section-divider':
        return <SectionDividerElement elementData={elementData} />
      case 'number-field':
        return (
          <NumberFieldElement
            elementData={elementData}
            onChange={(e: any) => onTextFieldChange(e, index)}
          />
        )
      case 'date-field':
        return (
          <DateFieldElement
            index={index}
            values={elementData.values}
            onChange={(e: any) => onDateChange(e, index)}
          />
        )
      case 'select-field':
        return (
          <SelectFieldElement
            values={elementData.values}
            index={index}
            onChanage={(e: any) => {
              onSelectionchange(e, index)
            }}
          />
        )
      case 'text-field':
        return (
          <TextFieldElement
            elementData={elementData}
            onChangeText={(e: any) => onTextFieldChange(e, index)}
          />
        )
      case 'radio-button':
        return (
          <RadioButtonElement
            elementData={elementData}
            onChange={(options: any) => {
              onRadioOptionChange(options, index)
            }}
          />
        )
      case 'check-box':
        return (
          <CheckBoxElement
            index={index}
            values={elementData.values}
            checkBoxOnChange={checkBoxOnChange}
          />
        )
      case 'text-area':
        return (
          <TextAreaElement
            index={index}
            values={elementData.values}
            textAreaOnChange={textAreaOnChange}
          />
        )
      default:
        return null
    }
  }

  const onTextFieldChange = (e: any, index: number) => {
    let data = formElements[index]
    let updatedData = {
      ...data,
      value: e.target.value,
    }
    formElements[index] = updatedData
    setFormElements([...formElements])
  }

  const onDateChange = (date: any, index: number) => {
    let data = formElements[index]
    let updatedData = {
      ...data,
      values: {
        ...data.values,
        date: date,
      },
    }
    formElements[index] = updatedData
    setFormElements([...formElements])
  }

  const onSelectionchange = (selected: string, index: number) => {
    let data = formElements[index]
    let updatedData = {
      ...data,
      values: {
        ...data.values,
        selectedOption: selected,
      },
    }
    formElements[index] = updatedData
    setFormElements([...formElements])
  }

  const onRadioOptionChange = (option: any, index: number) => {
    let data = formElements[index]
    let updatedData = {
      ...data,
      values: {
        ...data.values,
        options: option,
      },
    }
    formElements[index] = updatedData
    setFormElements([...formElements])
  }

  const checkBoxOnChange = (index: number, value: any) => {
    const updatedData = formElements.map((element: any, i: number) => {
      if (i === index) {
        return {...element, values: {...element.values, checkboxOptions: value}}
      }
      return element
    })
    setFormElements(updatedData)
  }

  const textAreaOnChange = (index: number, value: any) => {
    const updatedData = formElements.map((element: any, i: number) => {
      if (i === index) {
        return {...element, values: {...element.values, text: value}}
      }
      return element
    })
    setFormElements(updatedData)
  }

  const renderButton = (status: string) => {
    switch (status) {
      case 'attorney':
        return (
          <div className='d-flex align-items-center gap-1'>
            <Button
              text='Create Report'
              className='btn btn-sm fs-8 px-3'
              loading={loading}
              props={{onClick: () => createReport(), disabled: loading}}
            />
          </div>
        )
      default:
        return (
          <div className='d-flex align-items-center gap-1'>
            <Button
              text='Assign Report'
              className='btn btn-sm fs-8 px-3'
              props={{onClick: () => setAssignReportModalOpen(true)}}
            />
          </div>
        )
    }
  }

  useMemo(() => {
    if (template_details?.template_json) setFormElements([...template_details?.template_json])
  }, [template_details])

  useEffect(() => {
    if (location) {
      dispatch(
        callGetTemplateById({
          token,
          report_id: location?.reportId,
        })
      )
    }
    return () => {
      setFormElements([])
      dispatch(clearTemplateDetails())
    }
  }, [])
  const createReport = async () => {
    const newValue = {
      name: template_details?.name,
      description: template_details.description,
      // client_id: values.client,
      attorney_id: _id,
      // supervising_attorney_id: values.supervisor,
      // matter_id: values.matter,
      // due_date: values.dueDate,
      report_json: template_details.template_json,
      template_id: template_details._id,
      token: token,
      created_by: 'attorney',
    } as any
    try {
      
      setLoading(true)
      const res = await assignReport(newValue)
      setLoading(false)
      setAssignReportCompleteModalOpen(true)
    } catch (error) {
      console.log('Error', error)
    }
  }

  return (
    <div className='vh-100' style={{padding: '50px 0px'}}>
      {/* Modal */}
      <AssignReportModal
        open={assignReportModalOpen}
        setOpen={setAssignReportModalOpen}
        setAssignReportComplete={setAssignReportCompleteModalOpen}
        templateData={template_details}
      />
      <AssignReportCompleteModal
        open={assignReportCompleteModalOpen}
        setOpen={setAssignReportCompleteModalOpen}
      />

      {/* Page Head */}
      <div>
        <PageTitle breadcrumbs={dashboardBreadCrumbs}>Create Report</PageTitle>
        <DefaultTitle
          breadcrumbTitle='Client Template'
          onBackPress={() => {
            navigate(-1)
          }}
        />
        <hr className='text-gray-500 mb-0' style={{marginTop: '25px'}} />

        {/* Header */}
        <div className='row gy-1' style={{padding: '20px 0px'}}>
          <div className='col-3'>
            <div className='d-flex flex-column align-items-start justify-content-center gap-2 h-100'>
              {status('Not Started')}
              <h4 className='m-0'>{template_details?.name}</h4>
              <p
                className='m-0 fs-8'
                style={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {template_details?.description}
              </p>
            </div>
          </div>
          <div className='col-6'>
            <div className='row gy-1 h-100'>
              <div className='col-4 h-100'>
                <div className='row gy-1 h-100'>
                  <div className='col'>
                    <div className='d-flex flex-column align-items-start justify-content-end gap-3 h-100'>
                      <label className='m-0 fs-8 text-gray-500'>CLIENT</label>
                      <div className='w-100'>
                        <h5 className='m-0'>{template_details?.client?.name}</h5>
                        <p
                          className='m-0 fs-7'
                          style={{
                            display: 'block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                          }}
                        >
                          {template_details?.client?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-8 h-100'>
                <div className='row gy-1 h-100'>
                  {/* <div className='col-6'>
                    <div className='d-flex flex-column align-items-start justify-content-end gap-3 h-100'>
                      <label className='m-0 fs-8 text-gray-500'>DUE DATE</label>
                      <div>
                        <h5 className='m-0'>{template_details?.due_date || ''}</h5>
                      </div>
                    </div>
                  </div> */}
                  <div className='col-6'>
                    <div className='d-flex flex-column align-items-start justify-content-end gap-3 h-100'>
                      <label className='m-0 fs-8 text-gray-500'>REPORT FREQUENCY</label>
                      <div>
                        <h5 className='m-0'>{template_details?.frequency?.name} Days</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-3'>
            <div className='d-flex flex-column align-items-end justify-content-between gap-5 h-100'>
              {renderButton(role)}
            </div>
          </div>
        </div>
      </div>

      <div style={{height: 'calc(100% - 250px)'}}>
        <div className='row h-100'>
          <div className='col-12 h-100'>
            <div
              className='bg-gray-200 h-100 rounded-3 border-solid overflow-auto'
              style={{padding: '20px'}}
            >
              <div
                className='bg-white rounded-3'
                style={{padding: '30px'}}
                onSubmit={(e) => e.preventDefault()}
              >
                <div className='row gx-9 gy-7'>
                  {formElements?.map((element: any, index: number) => {
                    return (
                      <Fragment key={index}>{renderElement(element.type, index, element)}</Fragment>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {CreateReport}
