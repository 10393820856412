import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import {EditorContent, useEditor} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import {useEffect, useState} from 'react'

export const TextAreaElement = ({values, index, textAreaOnChange}: any) => {
  // const [text, setText] = useState(values.text)

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    ],
    content: values.text,
    onUpdate: ({editor}) => {
      // setText(editor.getJSON())
      textAreaOnChange(index, editor.getJSON())
    },
  })

  // useEffect(() => {
  //   if (values.text !== text) {
  //     setText(values.text)
  //     editor?.chain().setContent(values.text).focus().run()
  //   }
  // }, [values.text, text, editor])

  return (
    <div className={`col-${values.col || 12}`}>
      {values.isLabel && <p className='fs-7 fw-bold mt-0 mb-1'>{values.labelTitle}</p>}
      {values.isEditable ? (
        <div className='input-group input-group-sm'>
          <div className='form-control'>
            <EditorContent editor={editor} />
          </div>
        </div>
      ) : (
        <div dangerouslySetInnerHTML={{__html: editor?.getHTML() || ''}} />
      )}
    </div>
  )
}