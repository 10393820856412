import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserByToken } from '../../modules/auth/core/_requests';



// interface priceAction {
//     type: string;
// }
export const fetchprofileDetails = createAsyncThunk(
    'profileDetailsSlice/fetchprofileDetails',
    async (token: string) => {
        try {
            let res = await getUserByToken(token);
            // console.log("Response", res);

            // return res.data.result
        } catch (error) {
            return error
            //   throw new Error(error.message);
        }
    },
) as any;


const initialState = {
    profile: []
};

const ProfileDetailsSlice = createSlice({
    name: "profileDetailsSlice",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchprofileDetails.fulfilled, (state: any, action: any) => {
            // console.log("action", action.payload);
            state.profile = action.payload

        });
    },


});

export default ProfileDetailsSlice.reducer;

