import React, {Dispatch, KeyboardEventHandler, memo, SetStateAction} from 'react'

import CreatableSelect from 'react-select/creatable'

const components = {
  DropdownIndicator: null,
}

interface Option {
   label: string
   value: string
}

interface CreatableMultiSelectTextInputProps {
  placeholder: string;
  multiEmailValue: readonly Option[];
  setMultiEmailValue:Dispatch<SetStateAction< readonly Option[]>>;
}

const createOption = (label: string) => ({
  label,
  value: label,
})

const CreatableMultiSelectTextInput = ({placeholder,multiEmailValue,setMultiEmailValue}: CreatableMultiSelectTextInputProps) => {
  const [inputValue, setInputValue] = React.useState('')
  
  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setMultiEmailValue((prev) => [...prev, createOption(inputValue)])
        setInputValue('')
        event.preventDefault()
    }
  }

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={(newValue) => setMultiEmailValue(newValue)}
      onInputChange={(newValue) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      theme={(theme: any) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: '#EEF6FF',
          primary: '#b5b5c3',
          danger: '#F1416C',
        },
      })}
      styles={{
        control: (baseStyles: any, state: any) => {
          // console.log('baseStyles', baseStyles)
          // console.log('state', state)
          return {
            ...baseStyles,
            borderWidth: '1px',
            minHeight: '39px',
            boxShadow: 'none',
            borderColor: state.isFocused ? '#b5b5c3' : '#e1e3ea',
            borderRadius: '0.475rem',
          }
        },
      }}
      placeholder={placeholder}
      value={multiEmailValue}
    />
  )
}

export default memo(CreatableMultiSelectTextInput)
