import React, {DragEvent, useMemo, useState} from 'react'
import {KTIcon} from '../../../../../theme/helpers'
import SectionHeadingIcon from '../../../../assets/icons/SectionHeadingIcon'
import Left from '../../../../assets/icons/Left'
import {useFocus} from '../../../../../hooks/useFocus'

interface SectionHeadingProps {
  index: number
  onDelete: (param: number) => void
  onDuplicate: (param: number) => void
  handleFormDragStart: (e: DragEvent<HTMLDivElement>) => void
  handleFormDragOver: (e: DragEvent<HTMLDivElement>) => void
  handleFormDragEnd: (e: DragEvent<HTMLDivElement>) => void
  onSave: (param: any) => void
  elementData: any
}

export const SectionHeading: React.FC<SectionHeadingProps> = ({
  index,
  onDelete,
  onDuplicate,
  handleFormDragStart,
  handleFormDragOver,
  handleFormDragEnd,
  onSave,
  elementData,
}) => {
  const [edit, setEdit] = useState(false)
  const [heading, setHeading] = useState(elementData.label)

  const {inputRef: sectionRef, focusInput: sectionFocusInput} = useFocus()

  useMemo(() => {
    setEdit(elementData.isEdit)
    setHeading(elementData.label)
  }, [elementData])

  const handleSave = () => {
    let params = {
      label: heading,
    }
    onSave(params)
    setEdit(false)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleSave()
    }
  }

  return (
    <div
      className='col-12'
      draggable
      onDragStart={handleFormDragStart}
      onDragOver={handleFormDragOver}
      onDragEnd={handleFormDragEnd}
    >
      {edit ? (
        // Edit View
        <div className='border rounded overflow-hidden'>
          <div className='d-flex justify-content-between bg-gray-300 py-3 px-4'>
            <div className='d-flex align-items-center gap-2'>
              <div
                className='bg-dark d-flex align-items-center'
                style={{borderRadius: '3px', padding: '3px'}}
              >
                <SectionHeadingIcon height={16} fill='none' stroke='white' />
              </div>

              <p className='m-0 fw-bold fs-7'>Section Heading</p>
            </div>
            <div className='d-flex align-items-center gap-2'>
              <div
                className='d-flex align-items-center gap-1 cursor-pointer'
                onClick={() => onDuplicate(index)}
              >
                <KTIcon iconName='copy' className='fs-6 text-dark' />
                <p className='m-0 fs-8 fw-semibold'>Duplicate</p>
              </div>
              <span
                className='badge badge-primary fs-8 py-0 px-3 fw-semibold cursor-pointer h-25px rounded-1'
                onClick={() => handleSave()}
              >
                Save
              </span>
            </div>
          </div>
          <div className='p-4 d-flex flex-column'>
            <div
              style={{position: 'relative'}}
              className='bg-gray-300 border rounded py-2 px-3 d-flex justify-content-start'
            >
              <span
                style={{opacity: 0, position: 'absolute'}}
                className='fs-6 fw-normal'
                id={`hiddenText-${index}`}
              ></span>
              <div
                className='d-flex input-group border border-primary px-3 py-2 gap-3 w-auto mw-100'
                style={{borderRadius: '3px'}}
              >
                <input
                  type='text'
                  id={`textInput-${index}`}
                  ref={sectionRef}
                  className='form-control bg-transparent border-0 p-0 text-dark fs-6 fw-normal rounded-0'
                  style={{minWidth: '195px', maxWidth: 'calc(100% - 25px)'}}
                  value={heading}
                  onChange={(e) => {
                    const textTag = document.getElementById(`hiddenText-${index}`) as HTMLElement
                    textTag.textContent = e.target.value
                    const width = textTag.offsetWidth as any
                    const testDiv = document.getElementById(`textInput-${index}`) as HTMLElement
                    testDiv.style.width = width + 'px'
                    setHeading(e.target.value)
                  }}
                  onKeyDown={handleKeyPress}
                />
                <span
                  className='input-group-text bg-transparent border-0 p-0 rounded-0 cursor-pointer'
                  onClick={() => sectionFocusInput()}
                >
                  <KTIcon iconName='pencil' className='fs-4 text-primary' />
                </span>
              </div>
            </div>
            <div className='separator my-3' />
            <button
              className='btn border border-danger text-danger fs-8 btn-sm py-0 px-3 h-25px align-self-end'
              onClick={() => {
                onDelete(index)
                setEdit(false)
              }}
            >
              Delete
            </button>
          </div>
        </div>
      ) : (
        // Main View
        <div className='border rounded overflow-hidden ps-0 py-5 pe-5 d-flex align-items-center'>
          <Left height={15} className='mx-3' />
          <div className='d-flex flex-column gap-2 w-100'>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm p-0 h-25px w-25px align-self-end pencil-div'
              onClick={() => setEdit(true)}
            >
              <KTIcon iconName='pencil' className='fs-5 pencil-icon-style m-1' />
            </button>
            <div className='bg-gray-300 border rounded py-3 px-4 d-flex justify-content-start'>
              <h6 className='m-0 fw-normal'>{heading}</h6>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
