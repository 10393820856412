import React, {useState} from 'react'
import {KTIcon} from '../../../../../theme/helpers'
import {Editor} from '@tiptap/react'

interface EditorMenuBarProps {
  index: number
  editor: Editor | null
}

export const EditorMenuBar: React.FC<EditorMenuBarProps> = ({index, editor}) => {
  const [selectOpen, setSelectOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState('Text style')
  const [selectOptions, setSelectOptions] = useState([
    {title: 'Heading 1', value: {level: 1}},
    {title: 'Heading 2', value: {level: 2}},
    {title: 'Heading 3', value: {level: 3}},
    {title: 'Heading 4', value: {level: 4}},
    {title: 'Heading 5', value: {level: 5}},
    {title: 'Heading 6', value: {level: 6}},
    {title: 'Paragraph', value: 'paragraph'},
  ])

  const onOptionSelect = (option: {title: string; value: any}) => {
    if (option.value === 'paragraph') {
      editor?.chain().focus().setParagraph().run()
    } else {
      editor?.chain().focus().toggleHeading(option.value).run()
    }
    setSelectedOption(option.title)
    setSelectOpen(false)
  }

  return (
    <div className='border rounded py-2 px-3 d-flex align-items-center justify-content-around'>
      {/* Text style Select */}
      <div className='position-relative'>
        <div
          className={`collapsed cursor-pointer d-flex justify-content-between align-items-center gap-2 py-1`}
          data-bs-toggle='collapse'
          data-bs-target={`#select-options${index}`}
          onClick={() => setSelectOpen(!selectOpen)}
        >
          <h6 className='m-0 fs-7 fw-normal'>{selectedOption}</h6>
          <KTIcon iconName={`${selectOpen ? 'up' : 'down'}`} className='fs-4 text-dark' />
        </div>
        <div
          className='collapse position-absolute w-100'
          id={`select-options${index}`}
          style={{zIndex: 25}}
        >
          <div className='h-100 bg-white px-2 shadow-sm'>
            {selectOptions.map((option, key) => (
              <div
                className='py-2 cursor-pointer'
                data-bs-toggle='collapse'
                data-bs-target={`#select-options${index}`}
                key={key}
                onClick={() => onOptionSelect(option)}
              >
                <h6 className='m-0 fs-7 fw-normal'>{option.title}</h6>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='vr mx-2 bg-gray-400' />

      {/* Text Style Buttons */}
      <div
        className='d-flex align-items-center cursor-pointer'
        onClick={() => editor?.chain().focus().toggleBold().run()}
      >
        <KTIcon
          iconType='outline'
          iconName='text-bold'
          className={`fs-2 m-0 rounded ${
            editor?.isActive('bold') ? 'text-white  bg-dark' : 'text-dark'
          }`}
        />
      </div>
      <div
        className='d-flex align-items-center cursor-pointer'
        onClick={() => editor?.chain().focus().toggleItalic().run()}
      >
        <KTIcon
          iconType='outline'
          iconName='text-italic'
          className={`fs-2 m-0 rounded ${
            editor?.isActive('italic') ? 'text-white  bg-dark' : 'text-dark'
          }`}
        />
      </div>
      <div
        className='d-flex align-items-center cursor-pointer'
        onClick={() => editor?.chain().focus().toggleUnderline().run()}
      >
        <KTIcon
          iconType='outline'
          iconName='text-underline'
          className={`fs-2 m-0 rounded ${
            editor?.isActive('underline') ? 'text-white  bg-dark' : 'text-dark'
          }`}
        />
      </div>
      <div
        className='d-flex align-items-center cursor-pointer'
        onClick={() => editor?.chain().focus().toggleStrike().run()}
      >
        <KTIcon
          iconType='outline'
          iconName='text-strikethrough'
          className={`fs-2 m-0 rounded ${
            editor?.isActive('strike') ? 'text-white  bg-dark' : 'text-dark'
          }`}
        />
      </div>

      <div className='vr mx-2 bg-gray-400' />

      {/* Text Formatting Buttons */}
      <div
        className='d-flex align-items-center cursor-pointer'
        onClick={() => editor?.chain().focus().setTextAlign('left').run()}
      >
        <KTIcon
          iconType='outline'
          iconName='text-align-left'
          className={`fs-5 m-0 rounded p-1 ${
            editor?.isActive({textAlign: 'left'}) ? 'text-white  bg-dark' : 'text-dark'
          }`}
        />
      </div>
      <div
        className='d-flex align-items-center cursor-pointer'
        onClick={() => editor?.chain().focus().setTextAlign('center').run()}
      >
        <KTIcon
          iconType='outline'
          iconName='text-align-center'
          className={`fs-5 m-0 rounded p-1 ${
            editor?.isActive({textAlign: 'center'}) ? 'text-white  bg-dark' : 'text-dark'
          }`}
        />
      </div>
      <div
        className='d-flex align-items-center cursor-pointer'
        onClick={() => editor?.chain().focus().setTextAlign('justify').run()}
      >
        <KTIcon
          iconType='outline'
          iconName='text-align-justify-center'
          className={`fs-5 m-0 rounded p-1 ${
            editor?.isActive({textAlign: 'justify'}) ? 'text-white  bg-dark' : 'text-dark'
          }`}
        />
      </div>
      <div
        className='d-flex align-items-center cursor-pointer'
        onClick={() => editor?.chain().focus().setTextAlign('right').run()}
      >
        <KTIcon
          iconType='outline'
          iconName='text-align-right'
          className={`fs-5 m-0 rounded p-1 ${
            editor?.isActive({textAlign: 'right'}) ? 'text-white  bg-dark' : 'text-dark'
          }`}
        />
      </div>
    </div>
  )
}
