import React, {useState} from 'react'
import {KTIcon} from '../../../theme/helpers'

type option = {
  _id: string
  name: string
}

interface SelectProps {
  label?: string
  labelClassName?: string
  placeHolder: string
  name: string
  value?: string
  options: option[]
  onChange: (option: option) => void
  icon?: any
  error?: any
  className?: string
  clientSelect?: boolean
}

export const CustomSelect: React.FC<SelectProps> = ({
  label,
  labelClassName,
  placeHolder,
  name,
  value,
  options,
  onChange,
  icon,
  error,
  className,
  clientSelect,
}) => {
  const [selectOpen, setSelectOpen] = useState(false)

  return (
    <div>
      {label && (
        <label className={`form-label fw-bolder text-dark fs-6 mb-2 ${labelClassName}`}>
          {label}
        </label>
      )}
      <div className={`position-relative ${selectOpen && 'shadow-sm'}`}>
        <div
          className={`collapsed cursor-pointer border px-3 d-flex justify-content-between align-items-center ${
            selectOpen
              ? 'border-bottom-0 rounded-top bg-white border-gray-300'
              : 'rounded bg-gray-200 border-gray-200'
          }  ${className}`}
          style={{height: '38px'}}
          data-bs-toggle='collapse'
          data-bs-target={`#${name}-select`}
          onClick={() => setSelectOpen(!selectOpen)}
        >
          <h6
            className='m-0 fs-6 fw-normal'
            style={{
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%',
            }}
          >
            {value ? options.find((option) => option._id === value)?.name : placeHolder}
          </h6>
          {icon ? (
            icon
          ) : (
            <KTIcon iconName={`${selectOpen ? 'up' : 'down'}`} className='fs-4 text-dark' />
          )}
        </div>
        <div
          className='collapse position-absolute w-100'
          id={`${name}-select`}
          style={{zIndex: 10}}
        >
          <div
            className={`border border-gray-300 border-top-0 rounded-bottom px-3 overflow-y-auto mh-200px bg-white ${
              selectOpen && 'shadow-sm'
            }`}
          >
            {options.map((option: any, key: number) => (
              <div
                className='py-3 cursor-pointer'
                data-bs-toggle='collapse'
                data-bs-target={`#${name}-select`}
                key={key}
                onClick={() => {
                  onChange(option)
                  setSelectOpen(false)
                }}
              >
                <h6
                  className='m-0 fs-6 fw-normal'
                  style={{
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '100%',
                  }}
                >
                  {option.name}{' '}
                </h6>
                {clientSelect && <span className='fs-9 text-muted'>{option.email}</span>}
              </div>
            ))}
          </div>
        </div>
      </div>
      {Boolean(error) && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{error}</span>
          </div>
        </div>
      )}
    </div>
  )
}
