import {SVGProps} from 'react'

const TextAreaIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 12 12' {...props} xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='11' height='11' rx='1.5' stroke={props.stroke} />
      <path
        d='M10 7.00073L7 10.0007'
        stroke={props.stroke}
        strokeWidth='0.5'
        strokeLinecap='round'
      />
      <path d='M10 9L9 10' stroke={props.stroke} strokeWidth='0.5' strokeLinecap='round' />
      <path d='M3 3L3 6' stroke={props.stroke} strokeLinecap='round' />
    </svg>
  )
}

export default TextAreaIcon
