import {OverlayTrigger} from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import {PageTitle} from '../../../theme/layout/core'
import {DefaultTitle} from '../../../theme/layout/components/header/page-title/DefaultTitle'
import {ROLES, dashboardBreadCrumbs, fakeTemplateDataHead} from '../../../utils/constants'
import Button from '../../components/Button'
import {useNavigate} from 'react-router'
import SearchBar from '../../components/SearchBar'
import {CustomSelect} from '../../components/CustomSelect'
import {useEffect, useState} from 'react'
import {KTIcon} from '../../../theme/helpers'
import {useAppDispatch, useAppSelector} from '../../redux/redux-hook/hooks'
import {callAllClient} from '../../redux/reducer/ClientSlice'
import {useSelector} from 'react-redux'
import {RootStateTypes} from '../../modules/auth'
import {callGetTemplate} from '../../redux/reducer/TemplateSlice'
import moment from 'moment'
import {Pagination} from '../../components/Pagination'

const ClientTemplates = () => {
  const navigate = useNavigate()
  const [params, setParams] = useState({
    search: '',
    limit: 10,
    page: 1,
    client_id: '',
  })
  const dispatch = useAppDispatch()
  const {token, role} = useSelector((state: RootStateTypes) => state.auth.user.data.result)
  const clients = useAppSelector((state) => state.clientSlice.allClients.client)
  const {loading, data} = useAppSelector((state) => state.templates.templates)

  const onSearch = (e: any) => {
    const {value} = e.target
    setParams({...params, search: value})
  }

  const onFilterClient = (value: any) => {
    setParams({...params, client_id: value._id})
  }

  const onPageChange = (page: number) => {
    setParams({...params, page: page})
  }

  const onCopyTemplate = (id: string) => {
    navigate(`/report/${id}`)
  }

  const onEditTemplate = (id: string) => {
    navigate(`/template-builder/${id}`)
  }

  useEffect(() => {
    dispatch(callGetTemplate({...params, token}))
  }, [dispatch, params, token])

  useEffect(() => {
    dispatch(callAllClient({token}))
  }, [dispatch, token])

  return (
    // <div className='vh-100' style={{ padding: '50px 0px' }}>
    <div className='min-vh-100' style={{padding: '50px 0px'}}>
      {/* Page Head */}
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Client Templates</PageTitle>
      <DefaultTitle
        onBackPress={() => {
          navigate(-1)
        }}
      />
      {/* <div
        className='card shadow-sm border'
        style={{height: 'calc(100% - 120px)', padding: '30px 0px', marginTop: '33px'}}
      > */}
      <div
        className='card shadow-sm border'
        style={{minHeight: 'calc(100vh - 232px)', padding: '30px 0px', marginTop: '33px'}}
      >
        {/* begin::Header */}
        <div className='h-auto' style={{padding: '0px 30px 20px 30px'}}>
          <div className='w-100 d-flex justify-content-between align-items-center p-0 m-0 gap-4 flex-wrap'>
            <SearchBar
              placeholder='Search Templates'
              handleSearch={onSearch}
              loading={loading && params.search.length !== 0}
            />
            <div className='d-flex gap-4 flex-wrap justify-content-between align-items-center'>
              <div>
                <CustomSelect
                  className='w-175px h-45px'
                  name='filterLocation'
                  value={params.client_id}
                  placeHolder='Filter Location'
                  options={[...clients, {_id: '', name: 'Reset Filter'}] || []}
                  onChange={onFilterClient}
                />
              </div>
              {role === ROLES.ADMIN && (
                <Button
                  text='New Template'
                  className='h-45px'
                  props={{onClick: () => navigate('/template-builder')}}
                />
              )}
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body overflow-auto position-relative' style={{padding: '0px 30px'}}>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead className='' style={{position: 'sticky', top: 0, zIndex: 2}}>
                <tr className='fw-bold text-muted'>
                  {fakeTemplateDataHead.map((columnName, key) => {
                    return (
                      <th
                        key={key}
                        className={`${columnName === 'REPORT NAME' && 'min-w-325px'} ${
                          columnName === 'ACTIONS' && 'text-end'
                        }`}
                      >
                        {columnName}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {data.data.map((data: any, key: number) => {
                  return (
                    <tr key={key}>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data.name}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data.client.name}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data?.createdAt ? moment(data?.createdAt).format('MM/DD/YYYY') : 'N/A'}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center justify-content-end py-2 gap-3'>
                          <OverlayTrigger
                            key={'tooltip-assign-report'}
                            placement={'top'}
                            overlay={
                              <Tooltip id={`tooltip-assign-report`} style={{position: 'fixed'}}>
                                Assign Report
                              </Tooltip>
                            }
                          >
                            <div
                              className='bg-primary-subtle d-flex p-2 rounded cursor-pointer'
                              onClick={() => onCopyTemplate(data._id)}
                            >
                              <KTIcon iconName='copy' className='text-primary fs-4' />
                            </div>
                          </OverlayTrigger>
                          {role === ROLES.ADMIN && (
                            <OverlayTrigger
                              key={'tooltip-edit-Template'}
                              placement={'top'}
                              overlay={
                                <Tooltip id={`tooltip-edit-Template`} style={{position: 'fixed'}}>
                                  Click to edit template
                                </Tooltip>
                              }
                            >
                              <div
                                className='bg-primary-subtle d-flex p-2 rounded cursor-pointer'
                                onClick={() => onEditTemplate(data._id)}
                              >
                                <KTIcon iconName='pencil' className='text-gray-700 fs-4' />
                              </div>
                            </OverlayTrigger>
                          )}

                          {/* <div
                            className='bg-primary-subtle d-flex p-2 rounded cursor-pointer'
                            onClick={() => navigate(`/report/${key}`)}
                          >
                            <KTIcon iconName='eye' className='text-gray-700 fs-4' />
                          </div> */}
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          {data.data.length === 0 && (
            <div
              className='position-absolute top-0 left-0 right-0 h-100 d-flex justify-content-center align-items-center'
              style={{width: 'calc(100% - 60px)'}}
            >
              <h1 className='m-0 fs-2x text-gray-400'>No Data Found</h1>
            </div>
          )}
        </div>
        <div className='h-auto d-flex justify-content-end' style={{padding: '20px 30px 0px 30px'}}>
          <Pagination
            currentPage={params.page}
            totalPages={data.total_page}
            onPageChange={onPageChange}
          />
        </div>
        {/* begin::Body */}
      </div>
    </div>
  )
}

export default ClientTemplates
