import {DragEvent, useEffect, useMemo, useRef, useState} from 'react'
import {useFocus} from '../../../../../hooks/useFocus'
import {KTIcon} from '../../../../../theme/helpers'
import DateFieldIcon from '../../../../assets/icons/DateFieldIcon'
import Left from '../../../../assets/icons/Left'

interface DateFieldProps {
  index: number
  onDelete: (param: number) => void
  onDuplicate: (param: number) => void
  onSave: (data: any, index: number) => void
  values: any
  handleFormDragStart: (e: DragEvent<HTMLDivElement>) => void
  handleFormDragOver: (e: DragEvent<HTMLDivElement>) => void
  handleFormDragEnd: (e: DragEvent<HTMLDivElement>) => void
}

const DateField: React.FC<DateFieldProps> = ({
  index,
  onDelete,
  onDuplicate,
  onSave,
  values,
  handleFormDragStart,
  handleFormDragOver,
  handleFormDragEnd,
}) => {
  const [edit, setEdit] = useState(false)

  const [isLabel, setIsLabel] = useState(values.isLabel)
  const [labelTitle, setLabelTitle] = useState(values.labelTitle)
  const [datePlaceholder, setDatePlaceholder] = useState(values.datePlaceholder)
  const [inputType, setInputType] = useState<'text' | 'date'>(values.inputType)
  const [date, setDate] = useState(values.date)
  const datePickerRef = useRef<HTMLInputElement | any>(null)
  const [toggle, setToggle] = useState(true)
  const {inputRef: fieldRef, focusInput: fieldFocusInput} = useFocus()
  const {inputRef: dateLabelRef, focusInput: dateLabelFocusInput} = useFocus()

  const openDatePicker = () => {
    if (datePickerRef.current) {
      if (toggle) {
        setInputType('date')
        document
          .getElementById(`date-picker-template-builder-input-${index}`)
          ?.setAttribute('type', 'date')
        datePickerRef.current?.showPicker()
        setToggle(!toggle)
      }
      if (inputType === 'date') setToggle(!toggle)
    }
  }

  const onSaveClick = () => {
    setEdit(false)
    onSave(
      {values: {isLabel, labelTitle, datePlaceholder, inputType, date, col: values.col}},
      index
    )
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      onSaveClick()
    }
  }

  const onResize = () => {
    if (values.col === 12) {
      onSave(
        {
          values: {...values, col: 6},
        },
        index
      )
    } else if (values.col === 6) {
      onSave(
        {
          values: {...values, col: 4},
        },
        index
      )
    } else {
      onSave(
        {
          values: {...values, col: 12},
        },
        index
      )
    }
  }

  useMemo(() => {
    setIsLabel(values.isLabel)
    setLabelTitle(values.labelTitle)
    setDatePlaceholder(values.datePlaceholder)
    setInputType(values.inputType)
    setDate(values.date)
  }, [values])

  useEffect(() => {
    if (date.length === 0) {
      setInputType('text')
    }
  }, [date, edit])

  // useEffect(() => {
  //   if (inputType === 'date') {
  //     openDatePicker()
  //   }
  // }, [inputType])

  return (
    <div
      className={`col-${values.col || 4}`}
      draggable
      onDragStart={handleFormDragStart}
      onDragOver={handleFormDragOver}
      onDragEnd={handleFormDragEnd}
    >
      {edit ? (
        // Edit View
        <div className='border rounded overflow-hidden'>
          {/* Top Section */}
          <div className='d-flex justify-content-between bg-gray-300 py-3 px-4'>
            <div className='d-flex align-items-center gap-2'>
              <div
                className='bg-dark d-flex align-items-center'
                style={{borderRadius: '3px', padding: '4px'}}
              >
                <DateFieldIcon height={14} fill='none' stroke='white' />
              </div>

              <p className='m-0 fw-bold fs-7'>Date Field</p>
            </div>
            <div className='d-flex align-items-center gap-2'>
              <div
                className='d-flex align-items-center gap-1 cursor-pointer'
                onClick={() => onDuplicate(index)}
              >
                <KTIcon iconName='copy' className='fs-6 text-dark' />
                <p className='m-0 fs-8 fw-semibold'>Duplicate</p>
              </div>
              <span
                className='badge badge-primary fs-8 py-0 px-3 fw-semibold cursor-pointer h-25px rounded-1'
                onClick={onSaveClick}
              >
                Save
              </span>
            </div>
          </div>

          {/* Middle Section */}
          <div className='p-4 d-flex flex-column'>
            {isLabel && (
              <div
                className='input-group border border-primary px-3 py-2 w-175px mb-3 gap-3'
                style={{borderRadius: '3px'}}
              >
                <input
                  type='text'
                  ref={fieldRef}
                  className='form-control bg-transparent border-0 p-0 text-dark fs-6 fw-normal rounded-0'
                  value={labelTitle}
                  onChange={(e) => setLabelTitle(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <span
                  className='input-group-text bg-transparent border-0 p-0 cursor-pointer rounded-0'
                  onClick={() => fieldFocusInput()}
                >
                  <KTIcon iconName='pencil' className='fs-6 text-primary' />
                </span>
              </div>
            )}
            <div className='border rounded bg-gray-300 d-flex justify-content-between align-items-center overflow-hidden'>
              <div
                className='input-group border border-primary px-3 py-1 w-125px gap-3 ms-2 my-2'
                style={{borderRadius: '3px'}}
              >
                <input
                  type='text'
                  ref={dateLabelRef}
                  className='form-control bg-transparent border-0 p-0 text-dark fs-6 fw-normal rounded-0'
                  value={datePlaceholder}
                  onChange={(e) => setDatePlaceholder(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <span
                  className='input-group-text bg-transparent border-0 p-0 cursor-pointer rounded-0'
                  onClick={() => dateLabelFocusInput()}
                >
                  <KTIcon iconName='pencil' className='fs-6 text-primary' />
                </span>
              </div>
              <div
                className='d-flex align-items-center justify-content-center bg-gray-400 px-3'
                style={{height: '44.45px'}}
              >
                <DateFieldIcon fill='none' stroke='black' height={18} />
              </div>
            </div>
            <div className='separator my-3' />

            {/* Bottom Section */}
            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-align-items-center gap-1'>
                <input
                  className='form-check-input h-15px w-15px m-0'
                  style={{borderRadius: '3px'}}
                  type='checkbox'
                  checked={isLabel}
                  onChange={(e) => setIsLabel(e.target.checked)}
                  onKeyDown={handleKeyPress}
                />
                <label className='form-check-label text-dark fs-7 m-0 fw-normal'>Field Label</label>
              </div>
              <button
                className='btn border border-danger text-danger fs-8 btn-sm py-0 px-3 h-25px align-self-end'
                onClick={() => {
                  onDelete(index)
                  setEdit(false)
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      ) : (
        // Main View
        <div
          className='border rounded ps-0 py-5 pe-5 d-flex align-items-center grab'
          style={{margin: '0px 0px'}}
        >
          <Left height={15} className='mx-3' />

          <div className='d-flex flex-column gap-2 w-100'>
            <div
              className={`d-flex justify-content-${isLabel ? 'between' : 'end'} align-items-center`}
            >
              {isLabel && <h6 className='m-0 fw-medium'>{labelTitle}</h6>}
              <div className='d-flex align-items-center gap-2'>
                <button
                  className='btn align-self-end btn-icon btn-bg-light btn-active-color-primary btn-sm h-25px w-25px align-self-end pencil-div'
                  style={{padding: '1px 2px 0px 0px'}}
                  onClick={onResize}
                >
                  <KTIcon iconName='arrow-two-diagonals' className='fs-2 pencil-icon-style' />
                </button>
                <button
                  className='btn align-self-end btn-icon btn-bg-light btn-active-color-primary btn-sm p-0 h-25px w-25px align-self-end pencil-div'
                  onClick={() => {
                    setEdit(true)
                  }}
                >
                  <KTIcon iconName='pencil' className='fs-5 m-1 pencil-icon-style' />
                </button>
              </div>
            </div>
            <div
              className='input-group bg-gray-300 rounded overflow-hidden'
              style={{height: '38px'}}
            >
              <input
                id={`date-picker-template-builder-input-${index}`}
                ref={datePickerRef}
                type={inputType}
                className='form-control bg-transparent border-0 p-0 text-dark fs-6 fw-normal rounded-0 ms-3 custom-date-input'
                onClick={openDatePicker}
                value={inputType === 'text' ? datePlaceholder : date}
                onChange={(e) => {
                  setDate(e.target.value)
                  setToggle(!toggle)
                }}
                onKeyDown={handleKeyPress}
              />
              <span
                className='input-group-text bg-gray-400 border-0 p-0 rounded-0 px-3 cursor-pointer'
                onClick={openDatePicker}
              >
                <DateFieldIcon fill='none' stroke='black' height={18} />
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default DateField
