import {OverlayTrigger} from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import {PageTitle} from '../../../theme/layout/core'
import {DefaultTitle} from '../../../theme/layout/components/header/page-title/DefaultTitle'
import {
  dashboardBreadCrumbs,
  fakeActiveReportDataHead,
  reportStatus,
} from '../../../utils/constants'
import {useNavigate} from 'react-router'
import SearchBar from '../../components/SearchBar'
import {CustomSelect} from '../../components/CustomSelect'
import {Fragment, useEffect, useState} from 'react'
import {KTIcon, useDebounce} from '../../../theme/helpers'
import {useAppDispatch, useAppSelector} from '../../redux/redux-hook/hooks'
import {callAllClient} from '../../redux/reducer/ClientSlice'
import {useSelector} from 'react-redux'
import {RootStateTypes} from '../../modules/auth'
import moment from 'moment'
import {callGetReport} from '../../redux/reducer/ReportSlice'
import DateFieldIcon from '../../assets/icons/DateFieldIcon'
import {callFrequency} from '../../redux/reducer/FrequencySlice'
import {status} from '../../../utils/Helpers'
import {Pagination} from '../../components/Pagination'
import {useLocation} from 'react-router-dom'
import {fetchClientMatters} from '../../redux/reducer/MattersSlice'
import {current} from '@reduxjs/toolkit'

const ActiveReports = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const report_status = queryParams.get('report-status')

  const [params, setParams] = useState({
    search: '',
    limit: 10,
    page: 1,
    status: report_status || '',
    client_id: '',
    date_filter: '',
    due_date: '',
    attorney_id: '',
    supervising_attorney_id: '',
    matter_id: '',
    order: 'asc',
    sort: '',
  })

  const [sortField, setSortField] = useState('')
  const [order, setOrder] = useState('asc')

  const [searchText, setSearchText] = useState('')
  const debouncedValue = useDebounce(searchText, 200) // Debounce the inputValue with a 300ms delay

  const dispatch = useAppDispatch()
  const {token, _id, role} = useSelector((state: RootStateTypes) => state.auth.user.data.result)
  const clients = useAppSelector((state) => state.clientSlice.allClients.client)
  const frequency = useAppSelector((state) => state.frequencySlice.frequency.frequencies)
  const matter = useAppSelector((state) => state.clientMatters.data.result.data)
  const {loading, data}: any = useAppSelector((state) => state.report.report)

  const onSearch = (e: any) => {
    setSearchText(e.target.value)
  }
  const onFilterStatus = (value: any) => {
    setParams({...params, status: value._id})
  }
  const onFilterClient = (value: any) => {
    setParams({...params, client_id: value._id, matter_id: ''})
    dispatch(
      fetchClientMatters({
        list_all: 0,
        client_id: value._id,
        search: '',
        page: '',
        limit: '',
        token: token,
      })
    )
  }
  const onFilterDate = (value: any) => {
    setParams({...params, date_filter: value._id})
  }
  const onMatter = (value: any) => {
    setParams({...params, matter_id: value._id})
  }

  const onPageChange = (page: number) => {
    setParams({...params, page: page})
  }

  const handleSortingChange = (accessor: string) => {
    const sortOrder = accessor === sortField && order === 'asc' ? 'desc' : 'asc'
    setParams({...params, order: sortOrder, sort: accessor})
    setSortField(accessor)
    setOrder(sortOrder)
  }

  useEffect(() => {
    setParams({...params, search: debouncedValue || ''})
  }, [debouncedValue])

  useEffect(() => {
    setParams({...params, status: report_status || ''})
  }, [report_status])

  useEffect(() => {
    if (role === 'attorney') {
      dispatch(callGetReport({token, ...params, attorney_id: _id}))
    } else if (role === 'supervising_attorney') {
      dispatch(callGetReport({token, ...params, supervising_attorney_id: _id}))
    } else {
      dispatch(callGetReport({token, ...params}))
    }
  }, [_id, dispatch, params, role, token])

  useEffect(() => {
    dispatch(callFrequency({token}))
    dispatch(callAllClient({token}))
    dispatch(
      fetchClientMatters({
        list_all: 0,
        client_id: '',
        search: '',
        page: '',
        limit: '',
        token: token,
      })
    )
  }, [dispatch, token])

  return (
    // <div className='vh-100' style={{ padding: '50px 0px' }}>
    <div className='min-vh-100' style={{padding: '50px 0px'}}>
      {/* Page Head */}
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Active Report</PageTitle>
      <DefaultTitle
        onBackPress={() => {
          navigate(-1)
        }}
      />
      {/* <div
        className='card shadow-sm border'
        style={{height: 'calc(100% - 120px)', padding: '30px 0px', marginTop: '33px'}}
      > */}
      <div
        className='card shadow-sm border'
        style={{minHeight: 'calc(100vh - 232px)', padding: '30px 0px', marginTop: '33px'}}
      >
        {/* begin::Header */}
        <div className='h-auto' style={{padding: '0px 20px 20px 20px'}}>
          <div className='w-100 d-flex justify-content-between align-items-center p-0 m-0 gap-4 flex-wrap'>
            <SearchBar
              placeholder='Search Templates'
              handleSearch={onSearch}
              loading={loading && params.search.length !== 0}
            />
            <div className='d-flex gap-4 flex-wrap justify-content-between align-items-center'>
              <CustomSelect
                className='w-175px h-45px'
                name='filterStatus'
                value={params.status}
                placeHolder='Filter Status'
                options={reportStatus}
                onChange={onFilterStatus}
              />
              <CustomSelect
                className='w-175px h-45px'
                name='filterClient'
                value={params.client_id}
                placeHolder='Filter Client'
                options={[...clients, {_id: '', name: 'Reset Filter'}] || []}
                onChange={onFilterClient}
              />
              <CustomSelect
                className='w-175px h-45px'
                name='filterMatter'
                value={params.matter_id}
                placeHolder='Filter Matter'
                options={[...(matter[0]?.matters || []), {_id: '', name: 'Reset Filter'}] || []}
                onChange={onMatter}
              />
              <CustomSelect
                className='w-175px h-45px'
                name='date'
                value={params.date_filter}
                placeHolder='Select Date Range'
                options={[...frequency, {_id: '', name: 'Reset Filter'}] || []}
                onChange={onFilterDate}
                icon={<DateFieldIcon fill='none' height={16} stroke='black' />}
              />
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body overflow-auto position-relative' style={{padding: '0px 30px'}}>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead className='' style={{position: 'sticky', top: 0, zIndex: 2}}>
                <tr className='fw-bold text-muted'>
                  {fakeActiveReportDataHead.map(({label, accessor, sortable}, key) => {
                    return (
                      <th
                        key={key}
                        className={`${label === 'REPORT NAME' && 'min-w-250px'} ${
                          label === 'open' && 'text-end'
                        } ${label === 'CLAIM NUMBER' && 'min-w-125px'} ${
                          label === 'DUE DATE' && 'min-w-125px'
                        }`}
                        onClick={() => {
                          if (sortable) handleSortingChange(accessor)
                        }}
                        style={{cursor: 'pointer'}}
                      >
                        <div className='d-flex align-items-center gap-1'>
                          {label}
                          {sortable &&
                            (sortField === accessor && order === 'asc' ? (
                              <div style={{cursor: 'pointer'}} className='d-flex'>
                                <KTIcon iconName='arrow-up-down' className='text-dark fs-4' />
                              </div>
                            ) : sortField === accessor && order === 'desc' ? (
                              <div style={{cursor: 'pointer', rotate: '180deg'}} className='d-flex'>
                                <KTIcon iconName='arrow-up-down' className='text-dark fs-4' />
                              </div>
                            ) : (
                              <div style={{cursor: 'pointer'}} className='d-flex'>
                                <KTIcon
                                  iconType='outline'
                                  iconName='arrow-up-down'
                                  className='text-gray-500 fs-4'
                                />
                              </div>
                            ))}
                        </div>
                      </th>
                    )
                  })}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {data.data?.map((data: any, key: number) => {
                  return (
                    <tr key={key}>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data?.name || ''}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {status(data?.status)}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data.matter?.name || 'N/A'}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data?.client?.name}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {`${data.attorney_user?.first_name} ${data.attorney_user?.last_name}`}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data?.matter?.claim_number}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {/* {moment(data?.due_date).format('MM/DD/YYYY')} */}
                            {data?.due_date ? moment(data?.due_date).format('MM/DD/YYYY') : 'N/A'}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center justify-content-end py-2 gap-3'>
                          <OverlayTrigger
                            key={'tooltip-view-report'}
                            placement={'top'}
                            overlay={
                              <Tooltip id={`tooltip-view-report`} style={{position: 'fixed'}}>
                                Click to view report
                              </Tooltip>
                            }
                          >
                            <div
                              className='bg-primary-subtle d-flex p-2 rounded cursor-pointer'
                              onClick={() => {
                                navigate(`/active-report-detail/${data._id}`)
                              }}
                            >
                              <KTIcon iconName='eye' className='text-gray-700 fs-4' />
                            </div>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {data.data?.length === 0 && (
            <div
              className='position-absolute top-0 left-0 right-0 h-100 d-flex justify-content-center align-items-center'
              style={{width: 'calc(100% - 60px)'}}
            >
              <h1 className='m-0 fs-2x text-gray-400'>No Data Found</h1>
            </div>
          )}
          {/* end::Table container */}
        </div>

        <div className='h-auto d-flex justify-content-end' style={{padding: '20px 20px 0px 20px'}}>
          <Pagination
            currentPage={params.page}
            totalPages={data.total_page}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </div>
  )
}

export default ActiveReports
