/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import clsx from 'clsx'
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import { KTIcon } from '../../../helpers'
import UpdateInfo from '../../../assets/image/updateinfo.png'
import display from '../../../assets/image/display.png'
import { Link, useLocation } from 'react-router-dom'
import { ROLES } from '../../../../utils/constants'
import { useSelector } from 'react-redux'
import { RootStateTypes } from '../../../../app/modules/auth'
// {
//   link: 'projects',
//     icon: 'home',

// },
const tabs: ReadonlyArray<{ link: string; icon: string; toolTipInfo: string; role: any[] }> = [
  // {
  //   link: 'home',
  //   icon: 'home',
  //   role: Object.values(ROLES),
  // },
  {
    link: 'projects',
    icon: 'some-files',
    role: Object.values(ROLES),
    toolTipInfo: 'Report Manager',
  },
  {
    link: 'user',
    icon: 'profile-user',
    role: [ROLES.ADMIN, ROLES.SUPERVISING_ATTORNEY],
    toolTipInfo: 'Attorney Manager',
  },
]

type Props = {
  link: string
  setLink: Dispatch<SetStateAction<string>>
  setAsideIsActive: Dispatch<React.SetStateAction<string>>
  asideIsActive: string
}

const AsideTabs: FC<Props> = ({ link, setLink, setAsideIsActive, asideIsActive }) => {
  const { role } = useSelector((state: RootStateTypes) => state.auth.user.data.result)

  const checkAccess = useCallback(
    (tab: any) => {
      if (tab.role.includes(role)) {
        return true
      }
    },
    [role]
  )

  const accessibleTabs = useMemo(() => {
    return tabs.filter((tab) => checkAccess(tab))
  }, [checkAccess])

  return (
    <div
      className='hover-scroll-y mb-10'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-wrappers='#kt_aside_nav'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-offset='0px'
    >
      {/* begin::Nav */}
      <ul className='nav flex-column' id='kt_aside_nav_tabs overflow-hidden '>
        <li className='mt-5' onClick={() => setLink('home')}>
          {/* begin::Nav link */}
          <OverlayTrigger
            key={'tooltip-Home'}
            placement={'right'}
            overlay={
              <Tooltip id={`tooltip-Home`} style={{ position: 'fixed' }}>
                Go Home/Dashboard
              </Tooltip>
            }
          >
            <Link to={'/dashboard'} replace>
              <div
                className={clsx(
                  'nav-link btn btn-active-color-primary btn-color-white btn-active-light',
                  { active: link === 'home' }
                )}
                onClick={() => {
                  let active;
                  if (link === 'home') {
                    active = asideIsActive === 'true' ? 'false' : 'true';
                  } else {
                    active = asideIsActive === 'false' ? 'true' : asideIsActive;
                  }
                  setAsideIsActive(active);
                }}
              >
                <div className='d-flex flex-column align-items-center '>
                  <KTIcon iconName='home' className='fs-1' />
                </div>
              </div>
            </Link>

          </OverlayTrigger>
          {/* end::Nav link */}
        </li>
        {/* begin::Nav item */}
        {accessibleTabs.map((t) => (
          <li key={t.link} className='mt-5'>
            {/* begin::Nav link */}
            <OverlayTrigger
              key={'right'}
              placement={'right'}
              overlay={
                <Tooltip id={`tooltip-${t.toolTipInfo}`} style={{ position: 'fixed' }}>
                  {t.toolTipInfo}
                </Tooltip>
              }
            >
              <a
                className={clsx(
                  'nav-link btn btn-active-color-primary btn-color-white btn-active-light',
                  { active: t.link === link }
                )}
                // id='kt_aside_toggle'
                // data-kt-toggle='true'
                // data-kt-toggle-state='active'
                // data-kt-toggle-target='body'
                // data-kt-toggle-name='aside-minimize'
                onClick={() => {
                  setLink(t.link)
                  let active

                  if (link === t.link) {
                    active = asideIsActive === 'true' ? 'false' : 'true'
                  } else {
                    active = asideIsActive === 'false' ? 'true' : asideIsActive
                  }
                  // document.body.removeAttribute('data-kt-aside-minimize')
                  setAsideIsActive(active)
                }}
              >
                <div className='d-flex flex-column align-items-center '>
                  {/* <img src={t.image} className='fs-2x pb-3 w-30px' alt='' />
              <h3 className='fs-8'>{t.tooltip}</h3> */}
                  <KTIcon iconName={t.icon} className='fs-1 ' />
                </div>
              </a>
            </OverlayTrigger>
            {/* end::Nav link */}
          </li>
        ))}
        {/* end::Nav link */}
      </ul>
      {/* end::Tabs */}
    </div>
  )
}

export { AsideTabs }
