import React from 'react'

interface InputBoxProps {
  label?: string
  labelClassName?: string
  className?: string
  error?: any
  isValid?: any
  props?: object
}

const InputBox: React.FC<InputBoxProps> = ({
  label,
  labelClassName,
  className,
  error,
  isValid,
  props,
}) => {
  return (
    <div className='w-auto'>
      {label && (
        <label className={`form-label fw-bolder text-dark fs-6 mb-2 ${labelClassName}`}>
          {label}
        </label>
      )}

      <input
        autoComplete='off'
        className={`form-control ${className} ${isValid && 'is-valid'} ${
          Boolean(error) && 'is-invalid'
        }`}
        {...props}
      />
      {Boolean(error) && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{error}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default InputBox
