import { SVGProps } from 'react'

const SectionDivider = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M2 7H12" stroke={props.stroke} strokeLinecap="round"/>
<path d="M13 1V3C13 3.55228 12.5523 4 12 4H2C1.44772 4 1 3.55228 1 3V1" stroke={props.stroke} strokeWidth="0.75" strokeLinecap="round"/>
<path d="M1 13V11C1 10.4477 1.44772 10 2 10H12C12.5523 10 13 10.4477 13 11V13" stroke={props.stroke} strokeWidth="0.75" strokeLinecap="round"/>
</svg>
  )
}

export default SectionDivider
