import InputBox from '../../../components/InputBox'
import Button from '../../../components/Button'
import {useFormik} from 'formik'
import {forgotPasswordValidationSchema} from '../../../../utils/validations'
import {useNavigate} from 'react-router-dom'
import {sendForgotMail} from '../core/_requests'
import {useState} from 'react'

interface forgotmailProps {
  email: string
}
export function ForgotPassword() {
  const navigate = useNavigate()
  const [isMailSent, setIsMailSent] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const {handleChange, handleSubmit, touched, handleBlur, errors, values, resetForm, setErrors} =
    useFormik({
      initialValues: {
        email: '',
      },
      validationSchema: forgotPasswordValidationSchema,
      onSubmit: async (values: forgotmailProps) => {
        // console.log('values', values.email)
        let param = {
          email: values.email,
        }
        setLoading(true)
        sendForgotMail(param)
          .then((res) => {
            // console.log('res', res)
            setIsMailSent(true)
            resetForm()
            setLoading(false)
          })
          .catch((error) => {
            // console.log('error', error)
            // console.log("error", error.data.message);
            setErrors({email: 'User does not exist '})
            setIsMailSent(false)
            setLoading(false)
          })
        // resetForm()
      },
    })

  return (
    <div className='vh-100 d-flex justify-content-center align-items-center flex-column gap-8 bg-light'>
      {isMailSent ? (
        <>
          <div className='text-center'>
            <h1 className='card-title align-items-center flex-column d-flex mb-6 mx-5'>
              <span className='card-label fw-bold fs-2qx mb-1'>Mail sent sucessfully !.</span>
              <span className='text-muted mt-1 fw-semibold fs-7 mb-1 '>
                Please check your email and update your Password{' '}
              </span>
            </h1>

            <Button
              className='w-100'
              text='Go to Login'
              loading={false}
              props={{type: 'button', onClick: () => navigate('/auth')}}
            />
          </div>
        </>
      ) : (
        <>
          <div className='text-center'>
            <h1 className='fs-2qx'>Forgot Password?</h1>
          </div>

          <form className='d-flex flex-column gap-8 w-400px'>
            <InputBox
              label='Email Address'
              error={touched.email && errors.email}
              isValid={touched.email && !errors.email}
              props={{
                type: 'text',
                name: 'email',
                value: values.email,
                onChange: handleChange,
                onBlur: handleBlur,
              }}
            />
            <Button
              className='w-100'
              text='Reset Password'
              loading={loading}
              props={{type: 'button', onClick: handleSubmit, disabled: loading}}
            />
          </form>
          <p className='text-gray-500 d-flex gap-2 fs-4'>
            Go back to
            <span
              className='text-primary fw-semibold cursor-pointer'
              onClick={() => navigate('/auth')}
            >
              Login
            </span>
          </p>
        </>
      )}
    </div>
  )
}
