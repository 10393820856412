import {SVGProps} from 'react'

const DateFieldIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 14 14' {...props} stroke='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='1' y='1.99976' width='12' height='11.0003' rx='2' stroke={props.stroke} />
      <line x1='1' y1='4.5' x2='13' y2='4.5' stroke={props.stroke} />
      <path d='M4 2.99976L4 0.999934' stroke={props.stroke} strokeLinecap='round' />
      <path d='M7 2.99976V0.999934' stroke={props.stroke} strokeLinecap='round' />
      <path d='M10 2.99976V0.999934' stroke={props.stroke} strokeLinecap='round' />
      <ellipse cx='3.5' cy='7.49922' rx='0.5' ry='0.499955' fill={props.stroke} />
      <ellipse cx='3.5' cy='9.50044' rx='0.5' ry='0.499955' fill={props.stroke} />
      <ellipse cx='7.5' cy='7.49922' rx='0.5' ry='0.499955' fill={props.stroke} />
      <ellipse cx='7.5' cy='9.50044' rx='0.5' ry='0.499955' fill={props.stroke} />
      <ellipse cx='5.5' cy='7.49922' rx='0.5' ry='0.499955' fill={props.stroke} />
      <ellipse cx='5.5' cy='9.50044' rx='0.5' ry='0.499955' fill={props.stroke} />
      <rect x='9' y='6.99927' width='2' height='0.999911' rx='0.499955' fill={props.stroke} />
      <rect x='9' y='9.00049' width='2' height='0.999911' rx='0.499955' fill={props.stroke} />
    </svg>
  )
}

export default DateFieldIcon
