import React from 'react'
import {OverlayTrigger} from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import {KTIcon} from '../../theme/helpers'
import moment from 'moment'
import {status} from '../../utils/Helpers'

interface ReportListCardProps {
  data: any
  onViewClick?: any
}

const bulletStatus = (status: string) => {
  switch (status) {
    case 'in_progress':
      return <span className='bullet bullet-vertical h-100 bg-primary' />
    case 'approved':
      return <span className='bullet bullet-vertical h-100 bg-success' />
    case 'in_review':
      return <span className='bullet bullet-vertical h-100 bg-warning' />
    case 'requires_revision':
      return <span className='bullet bullet-vertical h-100 bg-danger' />
    default:
      return null
  }
}

export const ReportListCard: React.FC<ReportListCardProps> = ({data, onViewClick}) => {
  return (
    <div className='d-flex justify-content-between mb-5'>
      <div className='d-flex gap-5'>
        {bulletStatus(data.status)}
        <div className='d-flex flex-column gap-2'>
          <p className='m-0'>{data.client.name}</p>
          <h5 className='m-0'>{data.name}</h5>
          <p className='m-0'>{data.matter?.name || 'N/A'}</p>
        </div>
      </div>
      <div className='d-flex flex-column justify-content-between align-items-end'>
        <p className='m-0 text-muted'>
          {data?.due_date ? moment(data?.due_date).format('MM/DD/YYYY') : 'N/A'}
        </p>
        <div className='d-flex align-items-center gap-2 justify-content-end'>
          {status(data.status)}
          <OverlayTrigger
            key={'tooltip-upcoming-report'}
            placement={'top'}
            overlay={
              <Tooltip id={`tooltip-upcoming-report`} style={{position: 'fixed'}}>
                See the upcoming report
              </Tooltip>
            }
          >
            <div className='bg-gray-300 d-flex p-2 rounded cursor-pointer' onClick={onViewClick}>
              <KTIcon iconName='eye' className='text-dark fs-4' />
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  )
}
