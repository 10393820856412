import React, {useCallback, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../theme/helpers'
import Button from '../../../components/Button'
import {useAppSelector} from '../../../redux/redux-hook/hooks'
import InputBox from '../../../components/InputBox'
import {CustomSelect} from '../../../components/CustomSelect'
import {callAllClient} from '../../../redux/reducer/ClientSlice'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import {RootStateTypes} from '../../../modules/auth'

interface CreateMatterModalProps {
  open: boolean
  setOpen: Function
  onCreateMatter: any
}

export const CreateMatterModal: React.FC<CreateMatterModalProps> = ({
  open,
  setOpen,
  onCreateMatter,
}) => {
  const [matter, setMatter] = useState({name: '', client_id: '', file_number: '', claim_number: ''})
  const [formError, setFormError] = useState({matterName: '', client: ''})
  const [isSubmitted, setIsSubmitted] = useState(false)

  const dispatch = useDispatch()
  const token = useSelector((state: RootStateTypes) => state.auth.user.data.result.token)
  const clients = useAppSelector((state) => state.clientSlice.allClients.client)
  

  const [editMatterNameOpen, setEditMatterNameOpen] = useState(false)

  const {loading} = useAppSelector((state) => state.clientMatters.createMatter)

  const onClose = useCallback(() => {
    setOpen(false)
    setEditMatterNameOpen(false)
    setMatter({name: '', client_id: '', file_number: '', claim_number: ''})
    setFormError({matterName: '', client: ''})
    setIsSubmitted(false)
  }, [setOpen])

  const onMatterNameChange = (e: any) => {
    const {value} = e.target
    setMatter({...matter, name: value})
  }
  const onFileNumberChange = (e: any) => {
    const {value} = e.target
    setMatter({...matter, file_number: value})
  }

  const onClaimNumberChange = (e: any) => {
    const {value} = e.target
    setMatter({...matter, claim_number: value})
  }

  const validateForm = useCallback(() => {
    const {name, client_id} = matter
    const formErrors = {matterName: '', client: ''}
    let isError = false
    if (name.length === 0) {
      formErrors.matterName = "Matter name can't be empty"
      isError = true
    }
    if (client_id.length === 0) {
      formErrors.client = 'Please select any client'
      isError = true
    }
    setFormError(formErrors)
    return isError
  }, [matter])

  const onSubmit = () => {
    setIsSubmitted(true)
    if (!validateForm()) {
      onCreateMatter(matter)
    }
  }

  const clientOnChange = (value: {_id: string; name: string}) => {
    setMatter({...matter, client_id: value._id})
  }

  useEffect(() => {
    if (!open) {
      onClose()
    }
  }, [onClose, open])

  useEffect(() => {
    if (isSubmitted) validateForm()
  }, [isSubmitted, validateForm])

  useEffect(() => {
    dispatch(callAllClient({token}))
  }, [dispatch, token])

  return (
    <Modal show={open} centered className='modal-md' onHide={onClose}>
      <div className='modal-content gap-8 rounded-3' style={{padding: '40px'}}>
        <div className='modal-header border-0 p-0 flex-column align-items-start gap-3'>
          <KTIcon iconName='folder' className='fs-2hx text-light bg-primary rounded-circle p-4' />
          <div className='d-flex flex-column gap-3'>
            <h1 className='m-0'>Create New Matter</h1>
            <p className='m-0'>Give the new matter a name and connect it to a client</p>
          </div>
        </div>
        <div className='modal-body border rounded' style={{padding: '30px'}}>
          <form className='d-flex flex-column gap-5 w-100' onSubmit={(e) => e.preventDefault()}>
            <div className='row'>
              <div className='col'>
                <p className='m-0 fw-bolder text-muted mb-2'>MATTER NAME</p>
                <InputBox
                    error={formError.matterName}
                    className='h-40px'
                    props={{
                      type: 'text',
                      name: 'matterName',
                      value: matter.name,
                      onChange: onMatterNameChange,
                    }}
                  />
              </div>
              <div className='col'>
                <CustomSelect
                  label='ASSIGNED CLIENT'
                  labelClassName='text-muted fs-7'
                  name='client'
                  placeHolder='Client'
                  options={[...clients, {_id: '', name: 'Reset Filter'}] || []}
                  value={matter.client_id}
                  onChange={clientOnChange}
                  error={formError.client}
                  icon={<KTIcon iconName='profile-circle' className='fs-1 text-dark' />}
                  className='bg-white'
                  clientSelect={true}
                />
              </div>
            </div>
            <div className='row'>
            <div className='col'>
                <p className='m-0 fw-bolder text-muted mb-2'>CLAIM NUMBER</p>
                <InputBox
                    error={formError.matterName}
                    className='h-40px'
                    props={{
                      type: 'text',
                      name: 'claimNumber',
                      value: matter.claim_number,
                      onChange: onClaimNumberChange,
                    }}
                  />
              </div>
              <div className='col'>
              <p className='m-0 fw-bolder text-muted mb-2'>FILE NUMBER</p>
                <InputBox
                    error={formError.matterName}
                    className='h-40px'
                    props={{
                      type: 'text',
                      name: 'claimNumber',
                      value: matter.file_number,
                      onChange: onFileNumberChange,
                    }}
                  />
              </div>
            </div>
          </form>
        </div>
        <div className='modal-footer border-0 p-0 justify-content-start'>
          <Button text='Cancel' className='btn-secondary' props={{onClick: onClose}} />
          <Button
            text='Create Matter'
            loading={loading}
            props={{type: 'button', disabled: loading, onClick: onSubmit}}
          />
        </div>
      </div>
    </Modal>
  )
}
