import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import {useEditor} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import {useState} from 'react'

export const TextAreaPdfElement = ({values, index, textAreaOnChange}: any) => {
  const [text, setText] = useState(values.text)

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    ],
    content: text,
    onUpdate: ({editor}) => {
      setText(editor.getJSON())
      textAreaOnChange(index, editor.getJSON())
    },
  })

  return (
    <div style={{gridColumn: `span ${values.col || 12}`}}>
      {values.isLabel && <p style={{fontWeight: 'bold', margin: 0}}>{values.labelTitle}</p>}
      <div dangerouslySetInnerHTML={{__html: editor?.getHTML() || ''}}></div>
    </div>
  )
}
