import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getFrequency } from '../../modules/auth/core/_requests'
import { FrequencyParamModal } from '../../modules/auth'


export const callFrequency = createAsyncThunk(
    'frequencySlice/callFrequency',
    async (params: FrequencyParamModal, { rejectWithValue }) => {
        try {
            return await getFrequency(params)
        } catch (error) {
            return rejectWithValue(error)
        }
    }
) as any



const initialState = {
    frequency: {
        loading: false, frequencies: [], error: null
    }
}

const FrequencySlice = createSlice({
    name: 'frequencySlice',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {

        builder
            .addCase(callFrequency.pending, (state) => {
                state.frequency.loading = true
            })
            .addCase(callFrequency.fulfilled, (state, action) => {
                // console.log("action", action);
                state.frequency.loading = false
                state.frequency.frequencies = action.payload.data.result
            })
            .addCase(callFrequency.rejected, (state, action) => {
                state.frequency.loading = false
            })

    },
})

export default FrequencySlice.reducer
