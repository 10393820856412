import React, {useEffect, useState} from 'react'
import {OverlayTrigger} from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import {PageTitle} from '../../../theme/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  ListsWidget9,
  MixedWidget3,
  MixedWidget8,
  StatisticsWidget4,
  TablesWidget5,
  TablesWidget9,
} from '../../../theme/partials/widgets'
import {DefaultTitle} from '../../../theme/layout/components/header/page-title/DefaultTitle'
import {dashboardBreadCrumbs, fakeDashboardDataHead} from '../../../utils/constants'
import {KTIcon} from '../../../theme/helpers'
import {ReportListCard} from '../../widgets/ReportListCard'
import {useAppDispatch, useAppSelector} from '../../redux/redux-hook/hooks'
import {useSelector} from 'react-redux'
import {RootStateTypes} from '../../modules/auth'
import {callGetOverDueReport, callGetUpcomingReport} from '../../redux/reducer/ReportSlice'
import {status} from '../../../utils/Helpers'
import moment from 'moment'
import {Pagination} from '../../components/Pagination'
import {useNavigate} from 'react-router-dom'

const DashboardPage = () => {
  const {token, role, _id, first_name, last_name} = useSelector(
    (state: RootStateTypes) => state.auth.user.data.result
  )
  const [upcomingReportParams, setUpcomingReportParams] = useState({
    search: '',
    limit: 10,
    page: 1,
    status: '',
    client_id: '',
    date_filter: '',
    due_date: 'upcoming',
    attorney_id: '',
    supervising_attorney_id: '',
    matter_id: '',
    order: '',
    sort: '',
  })
  const [overdueReportParams, setOverdueReportParams] = useState({
    search: '',
    limit: 10,
    page: 1,
    status: '',
    client_id: '',
    date_filter: '',
    due_date: 'overdue',
    attorney_id: '',
    supervising_attorney_id: '',
    matter_id: '',
    order: '',
    sort: '',
  })
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {overdueReport, upcomingReport} = useAppSelector((state) => state.report)
  const upcomingReportPageOnChange = (page: number) => {
    setUpcomingReportParams({...upcomingReportParams, page: page})
  }
  const overdueReportPageOnChange = (page: number) => {
    setOverdueReportParams({...overdueReportParams, page: page})
  }

  useEffect(() => {
    if (role === 'attorney') {
      dispatch(callGetUpcomingReport({...upcomingReportParams, attorney_id: _id, token}))
    } else if (role === 'supervising_attorney') {
      dispatch(
        callGetUpcomingReport({...upcomingReportParams, supervising_attorney_id: _id, token})
      )
    } else {
      dispatch(callGetUpcomingReport({...upcomingReportParams, token}))
    }
  }, [_id, dispatch, role, token, upcomingReportParams])

  useEffect(() => {
    if (role === 'attorney') {
      dispatch(callGetOverDueReport({...overdueReportParams, attorney_id: _id, token}))
    } else if (role === 'supervising_attorney') {
      dispatch(callGetOverDueReport({...overdueReportParams, supervising_attorney_id: _id, token}))
    } else {
      dispatch(callGetOverDueReport({...overdueReportParams, token}))
    }
  }, [_id, dispatch, overdueReportParams, role, token])

  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <React.Fragment>
      <div className='min-vh-100' style={{padding: '50px 0px'}}>
        {/* Page Head */}
        <PageTitle breadcrumbs={dashboardBreadCrumbs}>
          {`Welcome, ${first_name} ${last_name}`}
        </PageTitle>
        <DefaultTitle
          breadcrumbTitle='Dashboard'
          onBackPress={() => {
            // navigate(-1)
          }}
        />
        {/* <h6 className='m-0'>Pearson, Harris, and Litt</h6> */}
        <div style={{height: 'calc(100% - 120px)', padding: '30px 0px', marginTop: '33px'}}>
          <div className='row' style={{height: '103vh'}}>
            <div className='col-4 h-100'>
              <div className='h-100 card shadow-sm'>
                {/* begin::Header */}
                <div className='h-auto' style={{padding: '30px 30px 20px 30px'}}>
                  <div className='w-100 d-flex flex-column gap-3'>
                    <h3 className='m-0'>Upcoming Reports</h3>
                    <h6 className='m-0 text-muted'>Reports due in the next 7 days</h6>
                  </div>
                </div>
                {/* end::Header */}
                <div
                  className='h-100 overflow-auto position-relative'
                  style={{padding: '0px 30px'}}
                >
                  {upcomingReport.result.data?.map((item: any, key) => (
                    <ReportListCard
                      key={key}
                      data={item}
                      onViewClick={() => {
                        navigate(`/active-report-detail/${item._id}`)
                      }}
                    />
                  ))}
                  {upcomingReport.result.data?.length === 0 && (
                    <div
                      className='position-absolute top-0 left-0 right-0 h-100 d-flex justify-content-center align-items-center'
                      style={{width: 'calc(100% - 60px)'}}
                    >
                      <h1 className='m-0 fs-2x text-gray-400'>No Data Found</h1>
                    </div>
                  )}
                </div>
                <div
                  className='h-auto d-flex justify-content-end'
                  style={{padding: '20px 30px 30px 30px'}}
                >
                  <Pagination
                    currentPage={upcomingReportParams.page}
                    totalPages={upcomingReport.result.total_page}
                    onPageChange={upcomingReportPageOnChange}
                  />
                </div>
              </div>
            </div>
            <div className='col-8 h-100'>
              <div className='h-100 card shadow-sm'>
                {/* begin::Header */}
                <div className='h-auto' style={{padding: '30px 30px 20px 30px'}}>
                  <div className='w-100 d-flex flex-column gap-3'>
                    <h3 className='m-0'>Overdue Reports</h3>
                    <h6 className='m-0 text-muted'>
                      {overdueReport.result.data?.length} reports are overdue
                    </h6>
                  </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div
                  className='card-body overflow-auto position-relative'
                  style={{padding: '0px 30px'}}
                >
                  {/* begin::Table container */}
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <thead className='' style={{position: 'sticky', top: 0, zIndex: 2}}>
                        <tr className='fw-bold text-muted'>
                          {fakeDashboardDataHead.map((columnName, key) => {
                            return (
                              <th key={key} className={`${columnName === 'OPEN' && ''}`}>
                                {columnName}
                              </th>
                            )
                          })}
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {overdueReport.result.data?.map((data: any, key) => {
                          return (
                            <tr key={key}>
                              <td>
                                <div className='d-flex align-items-center py-2'>
                                  <span className='text-dark fw-bold text-hover-primary fs-6'>
                                    {data?.name}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center py-2'>
                                  <span className='text-dark fw-bold text-hover-primary fs-6'>
                                    {status(data.status)}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center py-2'>
                                  <span className='text-dark fw-bold text-hover-primary fs-6'>
                                    {data.matter?.name || 'N/A'}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center py-2'>
                                  <span className='text-dark fw-bold text-hover-primary fs-6'>
                                    {data.client?.name}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center py-2'>
                                  <span className='text-dark fw-bold text-hover-primary fs-6'>
                                    {data?.due_date
                                      ? moment(data?.due_date).format('MM/DD/YYYY')
                                      : 'N/A'}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center justify-content-end py-2 gap-3'>
                                  <OverlayTrigger
                                    key={'tooltip-overdue-report'}
                                    placement={'top'}
                                    overlay={
                                      <Tooltip
                                        id={`tooltip-overdue-report`}
                                        style={{position: 'fixed'}}
                                      >
                                        See the overdue report
                                      </Tooltip>
                                    }
                                  >
                                    <div
                                      className='bg-primary-subtle d-flex p-2 rounded cursor-pointer'
                                      onClick={() => {
                                        navigate(`/active-report-detail/${data._id}`)
                                      }}
                                    >
                                      <KTIcon iconName='eye' className='text-primary fs-4' />
                                    </div>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                  </div>
                  {overdueReport.result.data?.length === 0 && (
                    <div
                      className='position-absolute top-0 left-0 right-0 h-100 d-flex justify-content-center align-items-center'
                      style={{width: 'calc(100% - 60px)'}}
                    >
                      <h1 className='m-0 fs-2x text-gray-400'>No Data Found</h1>
                    </div>
                  )}
                  {/* end::Table container */}
                </div>
                <div
                  className='h-auto d-flex justify-content-end'
                  style={{padding: '20px 30px 30px 30px'}}
                >
                  <Pagination
                    currentPage={overdueReportParams.page}
                    totalPages={overdueReport.result.total_page}
                    onPageChange={overdueReportPageOnChange}
                  />
                </div>
                {/* begin::Body */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const DashboardWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        Dashboard
      </PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
;<React.Fragment>
  {/* begin::Row */};
  <div className='row gy-5 g-xl-8'>
    {/* begin::Col */}
    <div className='col-xxl-4'>
      <MixedWidget3 className='card-xl-stretch mb-xl-8' chartColor='primary' chartHeight='250px' />
    </div>
    {/* end::Col */}

    {/* begin::Col */}
    <div className='col-xxl-8'>
      <TablesWidget9 className='card-xxl-stretch mb-5 mb-xl-8' />
    </div>
    {/* end::Col */}
  </div>
  {/* end::Row */}
  {/* begin::Row */};
  <div className='row gy-5 g-xl-8'>
    {/* begin::Col */}
    <div className='col-xxl-4'>
      <StatisticsWidget4
        className='card-xxl-stretch-50 mb-5 mb-xl-8'
        svgIcon='element-11'
        color='danger'
        description='Weekly Income'
        change='750$'
      />

      <StatisticsWidget4
        className='card-xxl-stretch-50 mb-xl-8'
        svgIcon='basket'
        color='success'
        description='Sales Change'
        change='+259'
      />
    </div>
    {/* end::Col */}

    {/* begin::Col */}
    <div className='col-xxl-4'>
      <ListsWidget9 className='card-xxl-stretch mb-xl-8' />
    </div>
    {/* end::Col */}

    {/* begin::Col */}
    <div className='col-xxl-4'>
      <ListsWidget4 className='card-xxl-stretch mb-5 mb-xl-8' />
    </div>
    {/* end::Col */}
  </div>
  {/* end::Row */}
  {/* begin::Row */};
  <div className='row gy-5 g-xl-8'>
    {/* begin::Col */}
    <div className='col-xxl-4'>
      <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
    </div>
    {/* end::Col */}

    {/* begin::Col */}
    <div className='col-xxl-8'>
      <TablesWidget9 className='card-xxl-stretch mb-5 mb-xl-8' />
    </div>
    {/* end::Col */}
  </div>
  {/* end::Row */}
  {/* begin::Row */};
  <div className='row gy-5 g-xl-8'>
    {/* begin::Col */}
    <div className='col-xl-4'>
      <ListsWidget2 className='card-xl-stretch mb-xl-8' />
    </div>
    {/* end::Col */}

    {/* begin::Col */}
    <div className='col-xl-4'>
      <ListsWidget6 className='card-xl-stretch mb-xl-8' />
    </div>
    {/* end::Col */}

    {/* begin::Col */}
    <div className='col-xl-4'>
      <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
    </div>
    {/* end::Col */}
  </div>
  {/* end::Row */}
  {/* begin::Row */};
  <div className='row g-5 gx-xxl-8'>
    {/* begin::Col */}
    <div className='col-xxl-4'>
      <MixedWidget8 className='card-xxl-stretch mb-xl-3' chartColor='success' chartHeight='150px' />
    </div>
    {/* end::Col */}

    {/* begin::Col */}
    <div className='col-xxl-8'>
      <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
    </div>
    {/* end::Col */}
  </div>
  {/* end::Row */}
</React.Fragment>
