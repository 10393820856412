import React from 'react'
import {isTokenActivated} from '../utils/Helpers'
import { MasterLayout } from '../theme/layout/MasterLayout'

type Props = {
  to: string
  replace: boolean,
  token: string
}


const withUser = ( RenderComponent: React.ComponentType, NavigateComponent: React.ComponentType<{to: string; replace: boolean}> ) =>
  ({to, replace, token, ...props }: Props) => {
    return isTokenActivated(token) ? (
      <MasterLayout>
        <RenderComponent {...props} />
      </MasterLayout>
    ) : (
      <NavigateComponent {...{to, replace}} />
    )
  }

export default withUser
