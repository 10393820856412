import React, {useEffect, useRef, useState} from 'react'
import DateFieldIcon from '../../assets/icons/DateFieldIcon'

interface DateProps {
  label?: string
  labelClassName?: string
  placeHolder: string
  name: string
  value?: string
  onChange: (e: any) => void
  error?: any
  className?: string
}

export const CustomDate: React.FC<DateProps> = ({
  label,
  labelClassName,
  placeHolder,
  name,
  value,
  onChange,
  error,
  className,
}) => {
  const [inputType, setInputType] = useState<'text' | 'date'>('text')
  const datePickerRef = useRef<HTMLInputElement | any>(null)
  const [toggle, setToggle] = useState(true)

  const openDatePicker = () => {
    if (datePickerRef.current) {
      if (toggle) {
        setInputType('date')
        datePickerRef.current.showPicker()
      }
      if (inputType === 'date') setToggle(!toggle)
    }
  }

  useEffect(() => {
    if (value?.length === 0) {
      setInputType('text')
    }
  }, [value])

  useEffect(() => {
    if (inputType === 'date') {
      openDatePicker()
    }
  }, [inputType])

  return (
    <div>
      {label && (
        <label className={`form-label fw-bolder text-dark fs-6 mb-2 ${labelClassName}`}>
          {label}
        </label>
      )}
      <div className='input-group bg-gray-200 rounded overflow-hidden' style={{height: '38px'}}>
        <input
          ref={datePickerRef}
          name={name}
          type={inputType}
          className='form-control bg-transparent border-0 p-0 text-dark fs-6 fw-normal rounded-0 ms-3 custom-date-input'
          onClick={openDatePicker}
          value={inputType === 'text' ? placeHolder : value}
          onChange={(e) => {
            onChange(e)
            setToggle(!toggle)
          }}
        />
        <span
          className='input-group-text bg-gray-200 border-0 p-0 rounded-0 px-3 cursor-pointer'
          onClick={openDatePicker}
        >
          <DateFieldIcon fill='none' stroke='black' height={18} />
        </span>
      </div>
      {Boolean(error) && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{error}</span>
          </div>
        </div>
      )}
    </div>
  )
}
