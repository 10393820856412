import {memo, useMemo, useState} from 'react'
import DisplayView from './DisplayView'
import {ElementProps, StateProps} from './NumberField.propTypes'
import EditViewComponent from './EditView'

const NumberField = memo(
  ({
    handleFormDragStart,
    handleFormDragOver,
    handleFormDragEnd,
    onDelete,
    onDuplicate,
    onSave,
    index,
    elementData,
  }: ElementProps) => {
    const [state, setState] = useState<StateProps>({
      edit: false,
      isLabel: true,
      number: '',
      title: '',
    })

    const handleSave = () => {
      let param = {
        isLabel: state.isLabel,
        number: state.number,
        label: state.title,
      }
      onSave(param)
      setState((prevState) => ({...prevState, edit: false}))
    }

    const onResize = () => {
      if (elementData.col === 12) {
        onSave({
          col: 6,
        })
      } else if (elementData.col === 6) {
        onSave({
          col: 4,
        })
      } else {
        onSave({
          col: 12,
        })
      }
    }

    useMemo(() => {
      setState((prevState) => ({
        ...prevState,
        isLabel: elementData.isLabel,
        number: elementData.number,
        title: elementData.label,
      }))
    }, [elementData])

    const handleNumberChange = () => {}
    return (
      <div
        className={`col-${elementData.col || 4}`}
        draggable
        onDragStart={handleFormDragStart}
        onDragOver={handleFormDragOver}
        onDragEnd={handleFormDragEnd}
      >
        {state.edit ? (
          <EditViewComponent
            onNumberChange={(value) => setState((prevState) => ({...prevState, number: value}))}
            state={state}
            onLabelChange={() =>
              setState((prevState) => ({...prevState, isLabel: !prevState.isLabel}))
            }
            onChangeLabelText={(e) =>
              setState((prevState) => ({...prevState, title: e.target.value}))
            }
            saveButton={() => handleSave()}
            onDuplicate={() => onDuplicate(index)}
            onDelete={() => onDelete(index)}
          />
        ) : (
          <DisplayView
            handleNumberChange={handleNumberChange}
            state={state}
            onEditButton={() => setState((prevState) => ({...prevState, edit: true}))}
            onResize={onResize}
          />
        )}
      </div>
    )
  }
)

export default NumberField
