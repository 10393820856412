import React from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../theme/helpers'
import Button from '../../../components/Button'
import {useAppSelector} from '../../../redux/redux-hook/hooks'

interface DeleteMatterModalProps {
  open: boolean
  setOpen: Function
  matterName: string
  handleDelete: any
}

export const DeleteMatterModal: React.FC<DeleteMatterModalProps> = ({
  open,
  setOpen,
  matterName,
  handleDelete,
}) => {
  const {loading} = useAppSelector((state) => state.clientMatters.deleteMatter)

  const onClose = () => {
    setOpen(false)
  }

  return (
    <Modal show={open} centered className='modal' onHide={onClose}>
      <div className='modal-content gap-8 rounded-3' style={{padding: '40px'}}>
        <div className='modal-header border-0 p-0 flex-column align-items-center gap-3'>
          <KTIcon iconName='folder' className='fs-2hx text-light bg-primary rounded-circle p-4' />
          <div className='d-flex flex-column gap-3 align-items-center text-center'>
            <h1 className='m-0'>Delete Client Matter?</h1>
            <p className='m-0'>
              This action cannot be undone, are you ready to delete <strong>{matterName}</strong>?
            </p>
          </div>
        </div>
        <div className='modal-footer border-0 p-0 justify-content-center'>
          <Button text='Cancel' className='btn-secondary' props={{onClick: onClose}} />
          <Button
            text='Delete'
            className='btn-danger'
            loading={loading}
            props={{type: 'button', onClick: handleDelete, disabled: loading}}
          />
        </div>
      </div>
    </Modal>
  )
}
