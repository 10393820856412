import React, {Fragment, useEffect, useMemo, useState} from 'react'
import {PageTitle} from '../../../theme/layout/core'
import {DefaultTitle} from '../../../theme/layout/components/header/page-title/DefaultTitle'
import {ROLES, dashboardBreadCrumbs} from '../../../utils/constants'
import Button from '../../components/Button'
import {useNavigate, useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../redux/redux-hook/hooks'
import {SectionHeadingElement} from '../reports/repport-element/section-heading-element'
import {SectionDividerElement} from '../reports/repport-element/section-devider-element'
import {NumberFieldElement} from '../reports/repport-element/number-field-element'
import {DateFieldElement} from '../reports/repport-element/date-field-element'
import {TextFieldElement} from '../reports/repport-element/text-field-element'
import {RadioButtonElement} from '../reports/repport-element/radio-button-element'
import {CheckBoxElement} from '../reports/repport-element/check-box-field-element'
import {TextAreaElement} from '../reports/repport-element/text-area-element'
import {SelectFieldElement} from '../reports/repport-element/select-field-element'
import {callGetReportById} from '../../redux/reducer/ReportSlice'
import {RootStateTypes} from '../../modules/auth'
import {useSelector} from 'react-redux'
import {ReportSentModal} from './Modals/ReportSentModal'
import {ReportSubmittedModal} from './Modals/ReportSubmittedModal'
import {ReportRevisionsSubmitModal} from './Modals/ReportRevisionsSubmitModal'
import {ReportApprovalSubmitModal} from './Modals/ReportApprovalSubmitModal'
import {ReportReviewModal} from './Modals/ReportReviewModal'
import {
  convertToPDF,
  pdfBase64Generator,
  pdfBlobGenerator,
  renderPdfElement,
  status,
} from '../../../utils/Helpers'
import {FeedBackCard} from '../../widgets/FeedBackCard'
import {sentReport, updateReport} from '../../modules/auth/core/_requests'
import {ReportSubmitForeReviewModal} from './Modals/ReportSubmitForReviewModal'
import moment from 'moment'
import {GoBackAlertModal} from '../templateBuilder/Modal/back-alert-modal'
import {useCallbackPrompt} from '../../../hooks/useCallBackPropmpt'
import DialogBox from '../templateBuilder/Modal/browser-back-aletr'
import ReportSaveModal from './Modals/ReportSaveModal'

var reportElementsWithValue = [] as any

interface Option {
  label: string
  value: string
}
const ActiveReportDetail = () => {
  const [reportSentModalOpen, setReportSentModalOpen] = useState(false)
  const [reportSubmittedModalOpen, setReportSubmittedModalOpen] = useState(false)
  const [reportRevisionsSubmitModalOpen, setReportRevisionsSubmitModalOpen] = useState(false)
  const [reportApprovalSubmitModalOpen, setReportApprovalSubmitModalOpen] = useState(false)
  const [reportReviewModalOpen, setReportReviewModalOpen] = useState(false)
  const [reportFeedBack, setReportFeedBack] = useState<string[]>([])
  const [feedbackMessage, setFeedBackMessage] = useState('')
  const [reportSentForReview, setReportSentForReview] = useState(false)
  const [goBackModalVisible, setGoBackModalVisible] = useState(false)
  const dispatch = useAppDispatch()
  const location = useParams()
  const authUser = useSelector((state: RootStateTypes) => state.auth.user.data)
  const {report_detail} = useAppSelector((state) => state.report.report_details) as any
  const [formElements, setFormElements] = useState<any>([])
  reportElementsWithValue = formElements
  const [loading, setLoading] = useState(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog)
  const [multiEmailValue, setMultiEmailValue] = React.useState<readonly Option[]>([])

  const navigate = useNavigate()

  const renderElement = (type: string | undefined, index: number, elementData: any) => {
    switch (type) {
      case 'section-heading':
        return <SectionHeadingElement elementData={elementData} />
      case 'section-divider':
        return <SectionDividerElement elementData={elementData} />
      case 'number-field':
        return (
          <NumberFieldElement
            elementData={elementData}
            onChange={(e: any) => onTextFieldChnage(e, index)}
          />
        )
      case 'date-field':
        return (
          <DateFieldElement
            values={elementData.values}
            onChange={(e: any) => onDateChange(e, index)}
          />
        )
      case 'select-field':
        return (
          <SelectFieldElement
            values={elementData.values}
            index={index}
            onChanage={(e: any) => {
              onSelectionchange(e, index)
            }}
          />
        )
      case 'text-field':
        return (
          <TextFieldElement
            elementData={elementData}
            onChangeText={(e: any) => onTextFieldChnage(e, index)}
          />
        )
      case 'radio-button':
        return (
          <RadioButtonElement
            elementData={elementData}
            onChange={(options: any) => {
              onRadioOptionChnage(options, index)
            }}
          />
        )
      case 'check-box':
        return (
          <CheckBoxElement
            index={index}
            values={elementData.values}
            checkBoxOnChange={checkBoxOnChange}
          />
        )
      case 'text-area':
        return (
          <TextAreaElement
            index={index}
            values={elementData.values}
            textAreaOnChange={textAreaOnChange}
          />
        )
      default:
        return null
    }
  }

  const checkForChanges = () => {
    if (report_detail?.status !== 'sent') {
      setShowDialog(true)
    } else if (report_detail?.status !== 'approved') {
      setShowDialog(true)
    } else {
      setShowDialog(false)
    }
  }

  const onTextFieldChnage = (e: any, index: number) => {
    let data = formElements[index]
    let updatedData = {
      ...data,
      value: e.target.value,
    }
    formElements[index] = updatedData
    setFormElements([...formElements])
    checkForChanges()
  }

  const onDateChange = (date: any, index: number) => {
    let data = formElements[index]
    let updatedData = {
      ...data,
      values: {
        ...data.values,
        date: date,
      },
    }
    formElements[index] = updatedData
    setFormElements([...formElements])
    checkForChanges()
  }

  const onSelectionchange = (selected: string, index: number) => {
    let data = formElements[index]
    let updatedData = {
      ...data,
      values: {
        ...data.values,
        selectedOption: selected,
      },
    }
    formElements[index] = updatedData
    setFormElements([...formElements])
    checkForChanges()
  }

  const onRadioOptionChnage = (option: any, index: number) => {
    const updatedData = formElements.map((element: any, i: number) => {
      if (i === index) {
        return {...element, options: option}
      }
      return element
    })
    // setFormElements(updatedData)
    // let data = formElements[index]
    // let updatedData = {
    //     ...data,
    //     values: {
    //         ...data.values,
    //         options: option,
    //     },
    // }
    // formElements[index] = updatedData
    setFormElements(updatedData)
    checkForChanges()
  }

  const checkBoxOnChange = (index: number, value: any) => {
    const updatedData = formElements.map((element: any, i: number) => {
      if (i === index) {
        return {...element, values: {...element.values, checkboxOptions: value}}
      }
      return element
    })
    setFormElements(updatedData)
    checkForChanges()
  }

  const textAreaOnChange = (index: number, value: any) => {
    const updatedData = formElements.map((element: any, i: number) => {
      if (i === index) {
        return {...element, values: {...element.values, text: value}}
      }
      return element
    })
    setFormElements(updatedData)
    checkForChanges()
  }

  const renderButton = (status: string) => {
    switch (status) {
      case 'approved':
        return (
          <div className='d-flex align-items-center gap-1'>
            <Button
              text='Send to Client'
              className='btn btn-sm fs-8 px-3'
              props={{
                onClick: () => {
                  setReportReviewModalOpen(true)
                },
              }}
            />
          </div>
        )
      case 'in_review':
        return (
          <div className='d-flex align-items-center gap-1'>
            {authUser.result.role === ROLES.SUPERVISING_ATTORNEY ||
            authUser.result.role === ROLES.ADMIN ? (
              <>
                <Button
                  text='Require Revision'
                  className='btn btn-sm btn-active-light-danger bg-transparent text-danger border border-danger fs-8 px-3'
                  props={{onClick: handleRevisionReport, loading: loading}}
                />
                <Button
                  text='Approve'
                  className='btn btn-sm btn-success fs-8 px-3'
                  props={{onClick: handleApproveReport, loading: loading}}
                />
              </>
            ) : null}
          </div>
        )
      case 'in_progress':
        return (
          <div className='d-flex align-items-center gap-1'>
            {authUser.result.role === 'attorney' && (
              <Button
                text='Submit for Review'
                className='btn btn-sm btn-active-warning bg-warning fs-8 px-3'
                props={{onClick: handleSubmitReview, loading: loading}}
              />
            )}
            {/* <Button text='Approve' className='btn btn-sm btn-success fs-8 px-3' props={{}} /> */}
          </div>
        )
      case 'requires_revision':
        return (
          <div className='d-flex align-items-center gap-1'>
            {authUser.result.role === 'attorney' && (
              <Button
                text='Submit for Review'
                className='btn btn-sm btn-active-warning bg-warning fs-8 px-3'
                props={{onClick: handleSubmitReview, loading: loading}}
              />
            )}
            {/* <Button text='Approve' className='btn btn-sm btn-success fs-8 px-3' props={{}} /> */}
          </div>
        )
      default:
        return null
    }
  }

  useMemo(() => {
    if (report_detail?.report_json) setFormElements([...report_detail?.report_json])
    if (report_detail?.feedback) setReportFeedBack([...report_detail?.feedback])
  }, [report_detail])

  useEffect(() => {
    if (location) {
      let param = {
        token: authUser.result.token,
        report_id: location?.reportId,
      }
      dispatch(callGetReportById(param))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGoBack = () => {
    let param = {
      token: authUser?.result?.token,
      report_json: reportElementsWithValue,
      id: location?.reportId,
    } as any

    if (report_detail?.status === 'requires_revision') {
      param = {
        ...param,
        feedback: reportFeedBack,
      }
    }
    updateReport(param).then(() => {
      setFormElements([])
      navigate(-1)
    })
  }
  const handleSubmitReview = () => {
    setReportSentForReview(true)
  }
  const handleRevisionReport = () => {
    setReportRevisionsSubmitModalOpen(true)
  }
  const handleApproveReport = () => {
    setReportApprovalSubmitModalOpen(true)
  }
  const callUpdateReport = async (status: string) => {
    let param = {
      token: authUser?.result?.token,
      report_json: formElements,
      status: status,
      id: location?.reportId,
    } as any
    if (status === 'requires_revision') {
      param = {
        ...param,
        feedback: reportFeedBack,
      }
    }
    const res = await updateReport(param)
    if (res) {
      setLoading(false)
      let param = {
        token: authUser.result.token,
        report_id: location?.reportId,
      }
      dispatch(callGetReportById(param))
    }
  }
  // console.log("Form WLwlwlw", formElements);
  function blobToFile(theBlob: any, fileName: any) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date()
    theBlob.name = fileName
    return theBlob
  }

  const sendReport = async () => {
    // let blob2 = await pdfBlobGenerator(document.getElementById('reportToGeneratePdf'))
    // console.log('blob2: ', blobToFile(blob2, 'report1'))

    try {
      // const pdf = await pdfBlobGenerator(
      //   document.getElementById('reportToGeneratePdf'),
      //   report_detail?.name
      // )
      // let base6eString = await pdf.split('base64,')[1]
      // console.log('🚀 ~ file: active-report-detail.tsx:348 ~ sendReport ~ pdf:', pdf)
      // const formData = new FormData()

      // formData.append('token', authUser.result.token)
      // formData.append('blob', base6eString)
      // const htmlData =
      let arrOfEmail = multiEmailValue?.map(({value}) => value)
      let param = {
        token: authUser.result.token,
        report_id: report_detail._id,
        html: document.getElementById('reportToGeneratePdf')?.innerHTML.toString(),
        email_array: JSON.stringify(arrOfEmail),
      }
      const res = await sentReport(param)

      await updateReport({
        token: authUser?.result?.token,
        report_json: reportElementsWithValue,
        id: location?.reportId,
      })
      setReportReviewModalOpen(false)
      setReportSentModalOpen(true)
      setShowDialog(false)
      dispatch(
        callGetReportById({
          token: authUser.result.token,
          report_id: location?.reportId,
        })
      )
    } catch (error) {
      console.log('error: ', error)
    }
  }

  const saveReportChanges = () => {
    let param = {
      token: authUser?.result?.token,
      report_json: reportElementsWithValue,
      id: location?.reportId,
      feedback: reportFeedBack,
    } as any
    // if (report_detail?.status === 'requires_revision') {
    //   param = {
    //     ...param,
    //     feedback: reportFeedBack,
    //   }
    // }
    updateReport(param).then(() => {
      // @ts-ignore
      confirmNavigation()
      setFormElements([])
    })
  }

  return (
    <div className='vh-100' style={{padding: '50px 0px'}}>
      {/* Modal */}
      <ReportSaveModal
        // @ts-ignore
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
        saveReportChanges={saveReportChanges}
      />
      <ReportSentModal open={reportSentModalOpen} setOpen={setReportSentModalOpen} />
      <ReportSubmittedModal open={reportSubmittedModalOpen} setOpen={setReportSubmittedModalOpen} />
      <ReportRevisionsSubmitModal
        open={reportRevisionsSubmitModalOpen}
        setOpen={setReportRevisionsSubmitModalOpen}
        reportId={report_detail?.report_id}
        onRevision={() => {
          setLoading(true)
          callUpdateReport('requires_revision').then(() => {
            setReportRevisionsSubmitModalOpen(false)
            setReportSubmittedModalOpen(true)
            setShowDialog(false)
          })
        }}
      />
      <ReportApprovalSubmitModal
        open={reportApprovalSubmitModalOpen}
        setOpen={setReportApprovalSubmitModalOpen}
        reportId={report_detail?.report_id}
        onApprove={() => {
          setLoading(true)
          callUpdateReport('approved').then(() => {
            setReportApprovalSubmitModalOpen(false)
            setReportSubmittedModalOpen(true)
            setShowDialog(false)
          })
        }}
      />
      <ReportReviewModal
        open={reportReviewModalOpen}
        setOpen={setReportReviewModalOpen}
        reportId={report_detail?.report_id}
        report_detail={report_detail}
        handleSendReport={() => sendReport()}
        multiEmailValue={multiEmailValue}
        setMultiEmailValue={setMultiEmailValue}
      />
      <ReportSubmitForeReviewModal
        open={reportSentForReview}
        setOpen={setReportSentForReview}
        reportId={report_detail?.report_id}
        onSubmitted={() => {
          setLoading(true)
          callUpdateReport('in_review').then(() => {
            setReportSentForReview(false)
            setReportSubmittedModalOpen(true)
            setShowDialog(false)
          })
        }}
      />
      <GoBackAlertModal
        open={goBackModalVisible}
        setOpen={setGoBackModalVisible}
        onSaveChange={() => handleGoBack()}
      />
      {/* Page Head */}
      <div>
        <PageTitle breadcrumbs={dashboardBreadCrumbs}>Active Report</PageTitle>
        <DefaultTitle
          onBackPress={() => {
            navigate(-1)
            // setGoBackModalVisible(true)
          }}
        />
        <hr className='text-gray-500 mb-0' style={{marginTop: '25px'}} />

        {/* Header */}
        <div className='row gy-1' style={{padding: '20px 0px'}}>
          <div className='col-6 col-xxl-2 order-1 order-xxl-1'>
            <div className='d-flex flex-column align-items-start justify-content-center gap-2 h-100'>
              {status(report_detail?.status)}
              <h4 className='m-0'>{report_detail?.name}</h4>
              <p
                className='m-0 fs-8'
                style={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {report_detail?.description}
              </p>
            </div>
          </div>
          <div className='col-12 col-xxl-8 order-3 order-xxl-2'>
            <div className='row gy-1 h-100'>
              <div className='col-8 h-100'>
                <div className='row gy-1 h-100'>
                  <div className='col-4 h-100'>
                    <div className='d-flex flex-column align-items-start justify-content-end gap-3 h-100 flex-wrap'>
                      <label className='m-0 fs-8 text-gray-500'>ATTORNEY</label>
                      <div className='w-100'>
                        <h5 className='m-0'>{`${report_detail?.attorney_user?.first_name} ${report_detail?.attorney_user?.last_name}`}</h5>
                        <p
                          className='m-0 fs-7'
                          style={{
                            display: 'block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                          }}
                        >
                          {report_detail?.attorney_user?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                  {report_detail?.created_by !== 'attorney' && (
                    <div className='col-4'>
                      <div className='d-flex flex-column align-items-start justify-content-end gap-3 h-100 flex-wrap'>
                        <label className='m-0 fs-8 text-gray-500'>SUPERVISOR</label>
                        <div className='w-100'>
                          <h5 className='m-0'>
                            {`${report_detail?.supervising_attorney_user?.first_name || ''} ${
                              report_detail?.supervising_attorney_user?.last_name || ''
                            }`}
                          </h5>
                          <p
                            className='m-0 fs-7'
                            style={{
                              display: 'block',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: '100%',
                            }}
                          >
                            {report_detail?.supervising_attorney_user?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='col-4'>
                    <div className='d-flex flex-column align-items-start justify-content-end gap-3 h-100'>
                      <label className='m-0 fs-8 text-gray-500'>CLIENT</label>
                      <div className='w-100'>
                        <h5 className='m-0'>{report_detail?.client?.name}</h5>
                        <p
                          className='m-0 fs-7'
                          style={{
                            display: 'block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                          }}
                        >
                          {report_detail?.client?.email || ''}
                        </p>
                      </div>
                    </div>
                  </div>
                  {report_detail?.created_by === 'attorney' && (
                    <div className='col-4'>
                      <div className='d-flex flex-column align-items-start justify-content-end gap-3 h-100'>
                        <label className='m-0 fs-8 text-gray-500'>REPORT FREQUENCY</label>
                        <div>
                          <h5 className='m-0'>{report_detail?.template?.frequency_days} Days</h5>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-4 h-100'>
                {report_detail?.created_by !== 'attorney' && (
                  <div className='row gy-1 h-100'>
                    <div className='col-6'>
                      {report_detail?.created_by !== 'attorney' && (
                        <div className='d-flex flex-column align-items-start justify-content-end gap-3 h-100'>
                          <label className='m-0 fs-8 text-gray-500'>DUE DATE</label>
                          <div>
                            <h5 className='m-0'>
                              {report_detail?.due_date
                                ? moment(report_detail?.due_date).format('MM/DD/YYYY')
                                : 'N/A'}
                            </h5>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-6'>
                      <div className='d-flex flex-column align-items-start justify-content-end gap-3 h-100'>
                        <label className='m-0 fs-8 text-gray-500'>REPORT FREQUENCY</label>
                        <div>
                          <h5 className='m-0'>{report_detail?.template?.frequency_days} Days</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='col-6 col-xxl-2 order-2 order-xxl-3'>
            <div className='d-flex flex-column align-items-end justify-content-between gap-5 h-100'>
              <div className='text-end'>
                {report_detail?.created_by !== 'attorney' && (
                  <p className='m-0 fs-7 fw-semibold'>Matter: {report_detail?.matter?.name}</p>
                )}
                <p className='m-0 fs-7 fw-semibold text-gray-700'>
                  Report ID: {report_detail?.report_id}
                </p>
              </div>
              {report_detail?.created_by === 'attorney' && report_detail?.status === 'in_progress'
                ? renderButton('approved')
                : renderButton(report_detail?.status)}
            </div>
          </div>
        </div>
      </div>

      <div style={{height: 'calc(100% - 270px)'}}>
        <div className='row gx-7 h-100'>
          {/* Report Section */}
          <div className={`${report_detail?.status !== 'sent' ? 'col-9' : 'col-12'} h-100`}>
            <div
              className='bg-gray-200 h-100 rounded-3 border-solid overflow-auto'
              style={{padding: '20px'}}
            >
              <div
                className='bg-white rounded-3'
                id='reportToGeneratePdf'
                style={{padding: '30px'}}
                onSubmit={(e) => e.preventDefault()}
              >
                {report_detail?.status === 'approved' ||
                report_detail?.status === 'sent' ||
                reportReviewModalOpen ? (
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(12, 1fr)',
                      gridGap: '28px 36px', // 10px for Y-gap, 20px for X-gap
                    }}
                    id='reportToGeneratePdfHtml'
                  >
                    {formElements?.map((element: any, index: number) => {
                      return (
                        <Fragment key={index}>{renderPdfElement(element.type, element)}</Fragment>
                      )
                    })}
                  </div>
                ) : (
                  <div className='row gx-9 gy-7'>
                    {formElements?.map((element: any, index: number) => {
                      return (
                        <Fragment key={index}>
                          {renderElement(element.type, index, element)}
                        </Fragment>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Feedback Section */}
          {report_detail?.status !== 'sent' && (
            <div className='col-3 h-100'>
              <div className='border border-gray-300 rounded-3 h-100 p-6 d-flex flex-column justify-content-between gap-3'>
                <h3 className='mb-3'>Report Feedback</h3>
                <div className='h-100 overflow-auto'>
                  <div className='d-flex flex-column gap-3'>
                    {reportFeedBack.map((item, index) => {
                      if (item.length === 0) {
                        return null
                      } else return <FeedBackCard text={item} />
                    })}
                  </div>
                </div>
                {authUser.result.role === ROLES.ADMIN ||
                authUser.result.role === ROLES.SUPERVISING_ATTORNEY ? (
                  <div className='d-flex flex-column gap-3'>
                    <textarea
                      name='feedback'
                      placeholder='Write your report feedback here.'
                      rows={3}
                      className='form-control fs-7 p-2'
                      style={{resize: 'none'}}
                      value={feedbackMessage}
                      onChange={(e) => {
                        setFeedBackMessage(e.target.value)
                      }}
                    ></textarea>
                    <Button
                      className='btn-sm align-self-end'
                      text='Submit'
                      loading={false}
                      props={{
                        disabled: false,
                        onClick: () => {
                          // setReportFeedBack([...reportFeedBack, feedbackMessage])
                          let param = {
                            token: authUser?.result?.token,
                            report_json: reportElementsWithValue,
                            id: location?.reportId,
                            feedback: [...reportFeedBack, feedbackMessage],
                          } as any
                          // if (report_detail?.status === 'requires_revision') {
                          //   param = {
                          //     ...param,
                          //     feedback: reportFeedBack,
                          //   }
                          // }
                          updateReport(param).then(() => {
                            // @ts-ignore
                            confirmNavigation()
                            setReportFeedBack([...reportFeedBack, feedbackMessage])
                            // setFormElements([])
                            setFeedBackMessage('')
                          })
                        },
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export {ActiveReportDetail}
