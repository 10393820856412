import React from 'react'

interface HeadingProps {
  elementData: any
}
const SectionHeadingElement = ({ elementData }: HeadingProps) => {
  return (
    <div className='col-12'>
      <h6 className='m-0 fw-bolder fs-3'>{elementData.label}</h6>
    </div>
  )
}

export { SectionHeadingElement }
