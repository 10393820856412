import {PageTitle} from '../../../theme/layout/core'
import {DefaultTitle} from '../../../theme/layout/components/header/page-title/DefaultTitle'
import {ROLES, dashboardBreadCrumbs, fakeArchiveReportDataHead} from '../../../utils/constants'
import Button from '../../components/Button'
import {useNavigate} from 'react-router'
import SearchBar from '../../components/SearchBar'
import {CustomSelect} from '../../components/CustomSelect'
import {useEffect, useState} from 'react'
import {KTIcon} from '../../../theme/helpers'
import {useAppDispatch, useAppSelector} from '../../redux/redux-hook/hooks'
import {callAllClient} from '../../redux/reducer/ClientSlice'
import {useSelector} from 'react-redux'
import {RootStateTypes} from '../../modules/auth'
import {callGetTemplate} from '../../redux/reducer/TemplateSlice'
import moment from 'moment'
import {Select} from '../../components/Select'
import {callGetReport} from '../../redux/reducer/ReportSlice'
import {status} from '../../../utils/Helpers'
import {Pagination} from '../../components/Pagination'
import DateFieldIcon from '../../assets/icons/DateFieldIcon'
import {callAttorney} from '../../redux/reducer/AttorneySlice'
import {callFrequency} from '../../redux/reducer/FrequencySlice'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const ArchiveReports = () => {
  const navigate = useNavigate()

  const [params, setParams] = useState({
    search: '',
    limit: 10,
    page: 1,
    status: 'archive',
    client_id: '',
    date_filter: '',
    due_date: '',
    attorney_id: '',
    supervising_attorney_id: '',
    matter_id: '',
    order: '',
    sort: '',
  })
  const [location, setLocation] = useState('')
  const dispatch = useAppDispatch()
  const {client} = useAppSelector((state) => state.clientSlice.allClients)
  const frequency = useAppSelector((state) => state.frequencySlice.frequency.frequencies)
  const attorneys = useAppSelector((state) => state.attorney.all_attorney.data)
  const {token, role, _id} = useSelector((state: RootStateTypes) => state.auth.user.data.result)
  const {loading, data}: any = useAppSelector((state) => state.report.report)

  useEffect(() => {
    dispatch(callFrequency({token}))
    dispatch(callAllClient({token}))
    dispatch(callAttorney({token}))
  }, [])

  useEffect(() => {
    if (role === 'attorney') {
      dispatch(callGetReport({token, ...params, attorney_id: _id}))
    } else if (role === 'supervising_attorney') {
      dispatch(callGetReport({token, ...params, supervising_attorney_id: _id}))
    } else {
      dispatch(callGetReport({token, ...params}))
    }
  }, [_id, dispatch, params, role, token])

  const onSearch = (e: any) => {
    const {value} = e.target
    setParams({...params, search: value})
  }

  const onFilterLocation = (value: any) => {
    setParams({...params, client_id: value._id})
  }

  const onFilterAttorney = (value: any) => {
    setParams({...params, attorney_id: value._id})
  }

  const onFilterDate = (value: any) => {
    setParams({...params, date_filter: value._id})
  }

  const onPageChange = (page: number) => {
    setParams({...params, page: page})
  }

  return (
    // <div className='vh-100' style={{ padding: '50px 0px' }}>
    <div className='min-vh-100' style={{padding: '50px 0px'}}>
      {/* Page Head */}
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Report Archive</PageTitle>
      <DefaultTitle
        onBackPress={() => {
          navigate(-1)
        }}
      />
      {/* <div
        className='card shadow-sm border'
        style={{height: 'calc(100% - 120px)', padding: '30px 0px', marginTop: '33px'}}
      > */}
      <div
        className='card shadow-sm border'
        style={{minHeight: 'calc(100vh - 232px)', padding: '30px 0px', marginTop: '33px'}}
      >
        {/* begin::Header */}
        <div className='h-auto' style={{padding: '0px 30px 30px 30px'}}>
          <div className='w-100 d-flex justify-content-between align-items-center p-0 m-0 gap-4 flex-wrap'>
            <SearchBar placeholder='Search Templates' handleSearch={onSearch} />
            <div className='d-flex gap-4 flex-wrap justify-content-between align-items-center'>
              <div className='d-flex gap-4 flex-wrap justify-content-between align-items-center'>
                <CustomSelect
                  className='w-200px h-45px'
                  name='location'
                  value={location}
                  placeHolder='Filter Client'
                  options={[...client, {_id: '', name: 'Reset Filter'}] || []}
                  onChange={onFilterLocation}
                />
                {role === ROLES.ADMIN && (
                  <CustomSelect
                    className='w-175px h-45px'
                    name='attorney'
                    value={params.attorney_id}
                    placeHolder='Filter Attorney'
                    options={[...attorneys, {_id: '', name: 'Reset Filter'}] || []}
                    onChange={onFilterAttorney}
                  />
                )}
                <CustomSelect
                  className='w-175px h-45px'
                  name='date'
                  value={params.date_filter}
                  placeHolder='Select Date Range'
                  options={[...frequency, {_id: '', name: 'Reset Filter'}] || []}
                  onChange={onFilterDate}
                  icon={<DateFieldIcon fill='none' height={16} stroke='black' />}
                />
              </div>

              {role === ROLES.ADMIN && (
                <Button
                  text='New Report'
                  className='h-45px'
                  props={{onClick: () => navigate('/template-builder')}}
                />
              )}
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body overflow-auto position-relative' style={{padding: '0px 30px'}}>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead className='' style={{position: 'sticky', top: 0, zIndex: 2}}>
                <tr className='fw-bold text-muted'>
                  {fakeArchiveReportDataHead.map((columnName, key) => {
                    return (
                      <th
                        key={key}
                        className={`${columnName === 'REPORT NAME' && 'min-w-300px'} ${
                          columnName === 'OPEN' && 'text-end'
                        }`}
                      >
                        {columnName}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {data.data?.map((data: any, key: number) => {
                  return (
                    <tr key={key}>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data?.name}
                          </span>
                        </div>
                      </td>
                      {/* <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {status(data.status)}
                          </span>
                        </div>
                      </td> */}
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data?.matter?.name}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data?.client?.name}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {`${data?.attorney_user?.first_name} ${data?.attorney_user?.last_name}`}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {`${data?.supervising_attorney_user?.first_name} ${data?.supervising_attorney_user?.last_name}`}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data?.matter?.claim_number}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {moment(data?.due_date).format('MMM D, YYYY')}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center justify-content-end py-2 gap-3'>
                          <OverlayTrigger
                            key={'tooltip-view-report'}
                            placement={'top'}
                            overlay={
                              <Tooltip id={`tooltip-view-report`} style={{position: 'fixed'}}>
                                Click to view report
                              </Tooltip>
                            }
                          >
                            <div
                              className='bg-primary-subtle d-flex p-2 rounded cursor-pointer'
                              onClick={() => {
                                navigate(`/archive-report-detail/${data?._id}`)
                              }}
                            >
                              <KTIcon iconName='eye' className='text-gray-700 fs-4' />
                            </div>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {data.data?.length === 0 && (
            <div
              className='position-absolute top-0 left-0 right-0 h-100 d-flex justify-content-center align-items-center'
              style={{width: 'calc(100% - 60px)'}}
            >
              <h1 className='m-0 fs-2x text-gray-400'>No Data Found</h1>
            </div>
          )}
          {/* end::Table container */}
        </div>
        <div className='h-auto d-flex justify-content-end' style={{padding: '20px 20px 0px 20px'}}>
          <Pagination
            currentPage={params.page}
            totalPages={data.total_page}
            onPageChange={onPageChange}
          />
        </div>

        {/* begin::Body */}
      </div>
    </div>
  )
}

export default ArchiveReports
