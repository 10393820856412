import {Modal} from 'react-bootstrap'
import ArchiveTickIcon from '../../../assets/icons/ArchiveTickIcon'
import Button from '../../../components/Button'

interface ReportSaveModalProps {
  showDialog: boolean
  cancelNavigation: any
  confirmNavigation: any
  saveReportChanges:any
}

const ReportSaveModal: React.FC<ReportSaveModalProps> = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
  saveReportChanges,
}) => {
  return (
    <Modal show={showDialog} centered>
      <div
        className='modal-content gap-8 rounded-3 d-flex justify-content-center'
        style={{padding: '30px'}}
      >
        <div className='modal-header border-0 p-0 flex-column align-items-center gap-3'>
          <div className='bg-primary p-4 rounded-circle'>
            <ArchiveTickIcon fill='none' height={30} width={30} />
          </div>
          {/* <SavedTempateIcon height={60} width={60} /> */}
          <div className='d-flex flex-column gap-3 align-items-center'>
            <h1 className='m-0'>Leave This Page ?</h1>
            <p className='m-0 text-muted text-center'>Please save the changes before going back.</p>
          </div>
        </div>

        <div className='modal-footer border-0 p-0 justify-content-center'>
          <Button className='btn-sm btn-danger' text='Skip' props={{onClick: confirmNavigation}} />
          <Button className='btn-sm' text='Save Changes' props={{onClick: saveReportChanges}} />
        </div>
      </div>
    </Modal>
  )
}
export default ReportSaveModal
