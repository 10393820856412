import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { checkOtp, login } from '../../modules/auth/core/_requests'
import { CheckOtpModal, LoginModal } from '../../modules/auth'

export const callLogin = createAsyncThunk(
  'loginSlice/callLogin',
  async (params: LoginModal, { rejectWithValue }) => {
    try {
      return await login(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

export const callCheckOtp = createAsyncThunk(
  'loginSlice/callCheckOtp',
  async (params: CheckOtpModal, { rejectWithValue }) => {
    try {
      return await checkOtp(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

const initialState = {
  login: { loading: false, error: null },
  user: { loading: false, data: {}, error: null },
  newPassword: { loading: false, error: null },
}

const LoginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    resetLoginSlice: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(callLogin.pending, (state) => {
        state.login.loading = true
      })
      .addCase(callLogin.fulfilled, (state, action) => {
        state.login.loading = false
        state.login.error = null
      })
      .addCase(callLogin.rejected, (state, action) => {
        state.login.loading = false
        state.login.error = action.payload.response.data.message
      })
    builder
      .addCase(callCheckOtp.pending, (state) => {
        state.user.loading = true
      })
      .addCase(callCheckOtp.fulfilled, (state, action) => {
        state.user.loading = false
        state.user.data = action.payload.data
        state.user.error = null
      })
      .addCase(callCheckOtp.rejected, (state, action) => {
        // console.log('action: ', action);

        state.user.loading = false
        state.user.error = action.payload.response.data.message
      })
  },
})
export const { resetLoginSlice } = LoginSlice.actions;
export default LoginSlice.reducer
