import * as Yup from 'yup'
import {
  emailRegEx,
  lowerCaseRegEx,
  numberRegEx,
  passwordLength,
  specialCharRegEx,
  upperCaseRegEx,
} from './constants'

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required("Email can't be empty")
    .min(3, 'Email must contain at least 3 characters')
    .max(50, 'Email cannot exceed 50 characters')
    .matches(emailRegEx, 'Must be a valid email'),
  password: Yup.string()
    .trim()
    .required("Password can't be empty")
    .min(3, 'Password must contain at least 3 characters')
    .max(50, 'Password cannot exceed 50 characters'),
})

export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required("Email can't be empty")
    .min(3, 'Email must contain at least 3 characters')
    .max(50, 'Email cannot exceed 50 characters')
    .matches(emailRegEx, 'Must be a valid email'),
})

export const createNewPasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .trim()
    .required("Password can't be empty")
    .min(passwordLength, 'Must contain at least 8 characters')
    .max(20, 'Password cannot exceed 20 characters')
    .matches(upperCaseRegEx, 'Must contain uppercase letters')
    .matches(lowerCaseRegEx, 'Must contain lowercase letters')
    .matches(numberRegEx, 'Must contain numbers')
    .matches(specialCharRegEx, 'Must contain one special character'),
  confirmPassword: Yup.string()
    .trim()
    .max(255)
    .required("Confirm Password can't be empty")
    .oneOf([Yup.ref('newPassword')], 'Confirm password does not match'),
})

export const createAttorneyValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required("First Name can't be empty")
    .min(3, 'First Name must contain at least 3 characters')
    .max(50, 'First Name cannot exceed 50 characters'),
  lastName: Yup.string()
    .trim()
    .required("Last Name can't be empty")
    .min(3, 'Last Name must contain at least 3 characters')
    .max(50, 'Last Name cannot exceed 50 characters'),
  states: Yup.array().min(1, "States can't be empty"),
  barNumber: Yup.string().trim().required("Bar Number can't be empty"),
  email: Yup.string()
    .trim()
    .required("Email can't be empty")
    .min(3, 'Email must contain at least 3 characters')
    .max(50, 'Email cannot exceed 50 characters')
    .matches(emailRegEx, 'Must be a valid email'),
  accountRole: Yup.string().trim().required("Account Role can't be empty"),
})

export const reviewTemplateValidationSchema = Yup.object().shape({
  templateName: Yup.string().trim().required("Template Name can't be empty"),
  description: Yup.string().trim().required("Description can't be empty"),
  location: Yup.string().trim().required("Location can't be empty"),
  frequency: Yup.string().trim().required("Frequency can't be empty"),
})

export const reviewReportValidationSchema = Yup.object().shape({
  templateName: Yup.string().trim().required("Template Name can't be empty"),
  description: Yup.string().trim().required("Description can't be empty"),
  attorney: Yup.string().trim().required("Attorney can't be empty"),
  supervisor: Yup.string().trim().required("Supervisor can't be empty"),
  client: Yup.string().trim().required("Client can't be empty"),
  matter: Yup.string().trim().required("Matter can't be empty"),
  dueDate: Yup.string().trim().required("Due date can't be empty"),
})

export const reviewSendReportValidationSchema = Yup.object().shape({
  reportName: Yup.string().trim().required("Report name can't be empty"),
  // matterName: Yup.string().trim().required("Matter name can't be empty"),
  client: Yup.string().trim().required("Client can't be empty"),
})
