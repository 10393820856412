import InputBox from '../../../components/InputBox'
import Button from '../../../components/Button'
import {useFormik} from 'formik'
import {loginValidationSchema} from '../../../../utils/validations'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {callCheckOtp, callLogin} from '../../../redux/reducer/LoginSlice'
import {useAppDispatch} from '../../../redux/redux-hook/hooks'
import {setHeaderTocken} from '../core/_requests'

export function Login() {
  const [signIn, setSignIn] = useState(false)

  const [securityCodeError, setSecurityCodeError] = useState('')
  const [securityCode, setSecurityCode] = useState('')
  const [securityCodeIsValid, setSecurityCodeIsValid] = useState(false)

  const [loginLoading, setLoginLoading] = useState(false)
  const [loginError, setLoginError] = useState('')
  const [userLoading, setUserLoading] = useState(false)
  const [userError, setUserError] = useState('')

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const {handleChange, handleSubmit, touched, handleBlur, errors, values, isValid, resetForm}: any =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: loginValidationSchema,
      onSubmit: async (values: object) => {
        setLoginLoading(true)
        dispatch(callLogin(values)).then((res: any) => {
          setLoginLoading(false)
          if (res.payload.data?.success) {
            setSignIn(true)
            setLoginError('')
          } else {
            setLoginError('The provided credentials are invalid. Please try again.')
          }
        })
      },
    })

  const securityCodeOnChange = (e: any) => {
    const code = e.target.value.replace(/\D/g, '')
    setSecurityCode(code)
    if (code.length < 4) {
      setSecurityCodeError('Security Code is invalid')
    } else {
      setSecurityCodeError('')
      setSecurityCodeIsValid(true)
    }
  }

  const onSecurityCodeSubmit = () => {
    if (securityCode.length >= 4) {
      const data = {code: securityCode, email: values.email}
      setUserLoading(true)
      dispatch(callCheckOtp(data)).then((res: any) => {
        setUserLoading(false)
        if (res.payload.data?.success) {
          setHeaderTocken(res.payload.data.token)
          setUserError('')
        } else {
          setUserError('Security Code is invalid')
        }
      })
    } else {
      setSecurityCodeError('Security Code is invalid')
    }
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmit(e)
    }
  }

  const handleCodeKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      onSecurityCodeSubmit()
    }
  }

  useEffect(() => {
    setLoginError('')
    setUserError('')
    setSecurityCode('')
  }, [signIn])

  return (
    <div className='vh-100 d-flex justify-content-center align-items-center flex-column gap-6 bg-light'>
      <div className='d-flex flex-column align-items-center gap-3 w-400px'>
        <h1 className='m-0 fs-2qx'>Welcome Back!</h1>
        <p className='text-gray-500 m-0'>Sign in to access your dashboard.</p>
        {(loginError || userError) && (
          <p className='text-danger m-0'>
            {loginError}
            {userError}
          </p>
        )}
      </div>
      {!signIn ? (
        <form className='d-flex flex-column gap-5 w-400px' onSubmit={(e) => e.preventDefault()}>
          <InputBox
            label='Email Address'
            error={touched.email && errors.email}
            isValid={touched.email && !errors.email}
            props={{
              type: 'email',
              name: 'email',
              value: values.email,
              onChange: handleChange,
              onBlur: handleBlur,
              onKeyPress: handleKeyPress,
            }}
          />
          <InputBox
            label='Password'
            error={touched.password && errors.password}
            isValid={touched.password && !errors.password}
            props={{
              type: 'password',
              name: 'password',
              value: values.password,
              onChange: handleChange,
              onBlur: handleBlur,
              onKeyPress: handleKeyPress,
            }}
          />
          <p
            className='text-primary cursor-pointer m-0'
            onClick={() => navigate('/forgot-password')}
          >
            Forgot Password?
          </p>
          <Button
            className='w-100'
            text='Sign in'
            loading={loginLoading}
            props={{type: 'button', onClick: handleSubmit, disabled: loginLoading || !isValid}}
          />
        </form>
      ) : (
        <form className='d-flex flex-column gap-6 w-400px' onSubmit={(e) => e.preventDefault()}>
          <InputBox
            label='Security Code'
            error={securityCodeError}
            isValid={securityCodeIsValid}
            props={{
              type: 'password',
              name: 'securityCode',
              value: securityCode,
              onChange: securityCodeOnChange,
              onKeyPress: handleCodeKeyPress,
            }}
          />
          <Button
            className='w-100'
            text='Continue'
            loading={userLoading}
            props={{
              type: 'button',
              onClick: onSecurityCodeSubmit,
              disabled: securityCodeError.length > 0 || userLoading,
            }}
          />
        </form>
      )}

      <p className='text-gray-500 d-flex gap-2'>
        Don't have an account?
        <span className='text-primary fw-semibold cursor-pointer'>Contact Admin</span>
      </p>
      {signIn && (
        <p className='text-gray-500 d-flex gap-2 '>
          Back to
          <span
            onClick={() => {
              setSignIn(false)
              resetForm()
            }}
            className='text-primary fw-semibold cursor-pointer'
          >
            Login
          </span>
        </p>
      )}
    </div>
  )
}
