import { Modal } from 'react-bootstrap'
import { KTIcon } from '../../../../theme/helpers'
import Button from '../../../components/Button'

interface ReportRevisionsSubmitModalProp {
  open: boolean
  setOpen: Function
  reportId: string
  onRevision: any
}
const ReportRevisionsSubmitModal = ({ open, setOpen, reportId, onRevision }: ReportRevisionsSubmitModalProp) => {

  const onClose = () => {
    setOpen(false)
  }

  return (
    <Modal show={open} centered onHide={onClose}>
      <div className='modal-content gap-8 rounded-3' style={{ padding: '40px' }}>
        <div className='modal-header border-0 p-0 flex-column align-items-start gap-6'>
          <KTIcon iconName='folder' className='fs-2hx text-light bg-primary rounded-circle p-4' />
          <div className='d-flex flex-column gap-3'>
            <h1 className='m-0'>Submit For Revisions</h1>
            <p className='m-0 text-gray-600'>Are you ready to submit the report for revisions?</p>
          </div>
          <div className='d-flex justify-content-between align-items-center w-100'>
            <div className='d-flex align-content-center gap-3'>
              <Button
                text='Cancel'
                className='btn-sm btn-secondary'
                props={{ type: 'button', onClick: onClose }}
              />
              <Button text='Submit' className='btn-sm' props={{ type: 'button', onClick: onRevision }} />
            </div>
            <p className='m-0 fs-7 fw-bold text-muted'>Report ID: {reportId}</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export { ReportRevisionsSubmitModal }
