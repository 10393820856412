import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {createMatter, deleteMatter, getAllMatters} from '../../modules/auth/core/_requests'
import {MatterDataType, MattersParamModal, createMatterModal} from '../../modules/auth'

interface response {
  success: number
  message: string
  result: {
    data: MatterDataType[]
    total_page: number
  }
}

interface MattersState {
  loading: boolean
  error: string | null
  data: response
  deleteMatter: any
  createMatter: any
}

export const fetchClientMatters = createAsyncThunk(
  'mattersSlice/fetchClientMatters',
  async (params: MattersParamModal, {rejectWithValue}) => {
    try {
      return await getAllMatters(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

export const callDeleteMatter = createAsyncThunk(
  'mattersSlice/callDeleteMatter',
  async (params: {id: string; token: string}, {rejectWithValue}) => {
    try {
      return await deleteMatter(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

export const callCreateMatter = createAsyncThunk(
  'mattersSlice/callCreateMatter',
  async (params: createMatterModal, {rejectWithValue}) => {
    try {
      return await createMatter(params)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
) as any

const initialState: MattersState = {
  loading: false,
  error: null,
  data: {
    success: 0,
    message: '',
    result: {
      data: [],
      total_page: 1,
    },
  },
  deleteMatter: {loading: false, error: null},
  createMatter: {loading: false, error: null},
}

const MattersSlice = createSlice({
  name: 'mattersSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientMatters.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchClientMatters.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.loading = false
        state.error = null
      })
      .addCase(fetchClientMatters.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload.message
      })

      .addCase(callDeleteMatter.pending, (state) => {
        state.deleteMatter.loading = true
      })
      .addCase(callDeleteMatter.fulfilled, (state, action) => {
        state.deleteMatter.loading = false
        state.deleteMatter.error = null
      })
      .addCase(callDeleteMatter.rejected, (state, action) => {
        state.deleteMatter.loading = false
        state.deleteMatter.error = action.payload.response.data.message
      })

      .addCase(callCreateMatter.pending, (state) => {
        state.createMatter.loading = true
      })
      .addCase(callCreateMatter.fulfilled, (state, action) => {
        state.createMatter.loading = false
        state.createMatter.error = null
      })
      .addCase(callCreateMatter.rejected, (state, action) => {
        state.createMatter.loading = false
        state.createMatter.error = action.payload.response.data.message
      })
  },
})
export const getAllMattersData = (state: MattersState) => state
export default MattersSlice.reducer
