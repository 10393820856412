import InputBox from '../../../components/InputBox'
import Button from '../../../components/Button'
import {useFormik} from 'formik'
import {createNewPasswordValidationSchema} from '../../../../utils/validations'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useAppSelector} from '../../../redux/redux-hook/hooks'
import {useState} from 'react'
import {resetPassword} from '../core/_requests'

export function CreateNewPassword() {
  const [errorMessage, setErrorMessage] = useState('')
  const [isSucess, setSucess] = useState(false)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const location = useLocation() as any
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')
  // console.log('token: ', token)

  // const dispatch = useAppDispatch()
  const {error} = useAppSelector((state) => state.auth.newPassword)

  const {handleChange, handleSubmit, touched, handleBlur, errors, values, isValid}: any = useFormik(
    {
      initialValues: {
        newPassword: '',
        confirmPassword: '',
      },
      validationSchema: createNewPasswordValidationSchema,
      onSubmit: async (values) => {
        // console.log('🚀 ~ file: CreatePassword.tsx:29 ~ onSubmit: ~ values:', values)
        if (!token) {
          return
        } else {
          let param = {
            password: values.confirmPassword,
            token: token,
          }
          setLoading(true)
          resetPassword(param)
            .then((res) => {
              setSucess(true)
              setLoading(false)
            })
            .catch((error) => {
              setSucess(false)
              setLoading(false)
            })
        }
      },
    }
  )

  return (
    <div className='vh-100 d-flex justify-content-center align-items-center flex-column gap-8 bg-light'>
      {isSucess ? (
        <>
          <div className='text-center'>
            <h1 className='card-title align-items-center flex-column d-flex mb-6 mx-5'>
              <span className='card-label fw-bold fs-2qx mb-1 '>
                Your password has changed sucessfully !.
              </span>
              <span className='text-muted mt-1 fw-semibold fs-7 mb-1 '>Please check by login </span>
            </h1>

            <Button
              className='w-100'
              text='Go to Login'
              loading={false}
              props={{type: 'button', onClick: () => navigate('/auth')}}
            />
          </div>
        </>
      ) : (
        <>
          <div className='d-flex flex-column align-items-center gap-3 w-400px'>
            <h1 className='m-0 fs-2qx'>Enter New Password!</h1>
            {error && <p className='text-danger m-0'>{error}</p>}
          </div>
          <form className='d-flex flex-column gap-6 w-400px' onSubmit={(e) => e.preventDefault()}>
            <InputBox
              label='New Password'
              error={touched.newPassword && errors.newPassword}
              isValid={touched.newPassword && !errors.newPassword}
              props={{
                type: 'password',
                name: 'newPassword',
                value: values.newPassword,
                onChange: handleChange,
                onBlur: handleBlur,
              }}
            />
            <InputBox
              label='Confirm Password'
              error={touched.confirmPassword && errors.confirmPassword}
              isValid={touched.confirmPassword && !errors.confirmPassword}
              props={{
                type: 'password',
                name: 'confirmPassword',
                value: values.confirmPassword,
                onChange: handleChange,
                onBlur: handleBlur,
              }}
            />
            <Button
              className='w-100'
              text='Reset Passowrd'
              loading={loading}
              props={{type: 'button', onClick: handleSubmit, disabled: loading || !isValid}}
            />
          </form>
        </>
      )}
    </div>
  )
}
