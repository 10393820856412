import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { KTIcon } from '../../../../../theme/helpers'
import Button from '../../../../components/Button'
import SavedTempateIcon from '../../../../assets/icons/SavedTemplate'
import { useNavigate } from 'react-router-dom'

interface GoBackAlertModalProp {
    open: boolean
    setOpen: Function
    onSaveChange: any
    onCloseEvent?: any
}
const GoBackAlertModal = ({ open, setOpen, onSaveChange, onCloseEvent }: GoBackAlertModalProp) => {
    const navigate = useNavigate()
    const onClose = () => {
        // if(onCloseEvent){
        //     onCloseEvent
        // }
        setOpen(false)
    }
    return (
        <Modal show={open} centered onHide={onClose}>
            <div
                className='modal-content gap-8 rounded-3 d-flex justify-content-center'
                style={{ padding: '30px' }}
            >
                <div className='modal-header border-0 p-0 flex-column align-items-center gap-3'>
                    {/* <KTIcon
                        iconName='file'
                        className='fs-2hx text-light bg-primary rounded-circle p-4'
                    /> */}
                    <SavedTempateIcon height={60} width={60} />
                    <div className='d-flex flex-column gap-3 align-items-center'>
                        <h1 className='m-0'>Please Wait...!</h1>
                        <p className='m-0 text-muted text-center'>
                            Are you sure you want to return back ? <br /> Please save changes before going back
                        </p>
                    </div>
                </div>

                <div className='modal-footer border-0 p-0 justify-content-center'>
                    <Button
                        text='Save Chanages'
                        className='btn-sm'
                        props={{
                            onClick: onSaveChange,
                        }}
                    />
                    {/* <Button
                        text='Create Another'
                        className='btn-sm'
                        // loading={loading}
                        props={{ type: 'button', onClick: onClose }}
                    /> */}

                </div>
            </div>
        </Modal>
    )
}
export { GoBackAlertModal }
