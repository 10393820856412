import React from 'react'
import {KTIcon} from '../../../theme/helpers'

interface SearchBarProps {
  handleSearch: (e: any) => void
  loading?: boolean
  placeholder: string
}

const SearchBar: React.FC<SearchBarProps> = ({placeholder, handleSearch, loading}) => {
  return (
    <form
      data-kt-search-element='form'
      className='position-relative mb-5 mb-lg-0 h-45px'
      onSubmit={(e) => e.preventDefault()}
      autoComplete='off'
    >
      <KTIcon
        iconName='magnifier'
        className='fs-2 text-lg-3 text-gray-800 position-absolute top-50 translate-middle-y ms-5'
      />
      {/*begin::Input*/}
      <input
        type='search'
        className='search-input form-control form-control-solid ps-13 d-flex'
        placeholder={placeholder}
        onChange={handleSearch}
      />
      {/*end::Input*/}
      {/*begin::Spinner*/}
      <span
        className={`position-absolute top-50 end-0 translate-middle-y lh-0 ${!loading && 'd-none'}`}
        data-kt-search-element='spinner'
        style={{marginRight: '14.8px'}}
      >
        <span className='spinner-border h-15px w-15px align-middle text-gray-400'></span>
      </span>
      {/*end::Spinner*/}
      {/*begin::Reset*/}
      <span
        className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-5 d-none'
        data-kt-search-element='clear'
      >
        <KTIcon iconName='cross' className=' fs-2 text-lg-1 me-0' />
      </span>
      {/*end::Reset*/}
    </form>
  )
}

export default SearchBar
