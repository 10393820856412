import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { PageTitle } from '../../../theme/layout/core'
import { DefaultTitle } from '../../../theme/layout/components/header/page-title/DefaultTitle'
import { ROLES, dashboardBreadCrumbs } from '../../../utils/constants'
import Button from '../../components/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../redux/redux-hook/hooks'
import { SectionHeadingElement } from '../reports/repport-element/section-heading-element'
import { SectionDividerElement } from '../reports/repport-element/section-devider-element'
import { NumberFieldElement } from '../reports/repport-element/number-field-element'
import { DateFieldElement } from '../reports/repport-element/date-field-element'
import { TextFieldElement } from '../reports/repport-element/text-field-element'
import { RadioButtonElement } from '../reports/repport-element/radio-button-element'
import { CheckBoxElement } from '../reports/repport-element/check-box-field-element'
import { TextAreaElement } from '../reports/repport-element/text-area-element'
import { SelectFieldElement } from '../reports/repport-element/select-field-element'
import { callGetReportById } from '../../redux/reducer/ReportSlice'
import { RootStateTypes } from '../../modules/auth'
import { useSelector } from 'react-redux'

import { status } from '../../../utils/Helpers'
import { FeedBackCard } from '../../widgets/FeedBackCard'
import { updateReport } from '../../modules/auth/core/_requests'

const ArchiveReportDetail = () => {
  const [reportSentModalOpen, setReportSentModalOpen] = useState(false)
  const [reportSubmittedModalOpen, setReportSubmittedModalOpen] = useState(false)
  const [reportRevisionsSubmitModalOpen, setReportRevisionsSubmitModalOpen] = useState(false)
  const [reportApprovalSubmitModalOpen, setReportApprovalSubmitModalOpen] = useState(false)
  const [reportReviewModalOpen, setReportReviewModalOpen] = useState(false)

  const dispatch = useAppDispatch()
  const location = useParams()
  const authUser = useSelector((state: RootStateTypes) => state.auth.user.data)
  const { report_detail } = useAppSelector((state) => state.report.report_details) as any
  const [formElements, setFormElements] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const renderElement = (type: string | undefined, index: number, elementData: any) => {
    switch (type) {
      case 'section-heading':
        return <SectionHeadingElement elementData={elementData} />
      case 'section-divider':
        return <SectionDividerElement elementData={elementData} />
      case 'number-field':
        return (
          <NumberFieldElement
            elementData={elementData}
            onChange={(e: any) => onTextFieldChnage(e, index)}
          />
        )
      case 'date-field':
        return (
          <DateFieldElement
            values={elementData.values}
            onChange={(e: any) => onDateChange(e, index)}
          />
        )
      case 'select-field':
        return (
          <SelectFieldElement
            values={elementData.values}
            index={index}
            onChanage={(e: any) => {
              onSelectionchange(e, index)
            }}
          />
        )
      case 'text-field':
        return (
          <TextFieldElement
            elementData={elementData}
            onChangeText={(e: any) => onTextFieldChnage(e, index)}
          />
        )
      case 'radio-button':
        return (
          <RadioButtonElement
            elementData={elementData}
            onChange={(options: any) => {
              onRadioOptionChnage(options, index)
            }}
          />
        )
      case 'check-box':
        return (
          <CheckBoxElement
            index={index}
            values={elementData.values}
            checkBoxOnChange={checkBoxOnChange}
          />
        )
      case 'text-area':
        return (
          <TextAreaElement
            index={index}
            values={elementData.values}
            textAreaOnChange={textAreaOnChange}
          />
        )
      default:
        return null
    }
  }

  const onTextFieldChnage = (e: any, index: number) => {
    let data = formElements[index]
    let updatedData = {
      ...data,
      value: e.target.value,
    }
    formElements[index] = updatedData
    setFormElements([...formElements])
  }

  const onDateChange = (date: any, index: number) => {
    let data = formElements[index]
    let updatedData = {
      ...data,
      values: {
        ...data.values,
        date: date,
      },
    }
    formElements[index] = updatedData
    setFormElements([...formElements])
  }

  const onSelectionchange = (selected: string, index: number) => {
    let data = formElements[index]
    let updatedData = {
      ...data,
      values: {
        ...data.values,
        selectedOption: selected,
      },
    }
    formElements[index] = updatedData
    setFormElements([...formElements])
  }

  const onRadioOptionChnage = (option: any, index: number) => {
    const updatedData = formElements.map((element: any, i: number) => {
      if (i === index) {
        return { ...element, options: option }
      }
      return element
    })
    // setFormElements(updatedData)
    // let data = formElements[index]
    // let updatedData = {
    //     ...data,
    //     values: {
    //         ...data.values,
    //         options: option,
    //     },
    // }
    // formElements[index] = updatedData
    setFormElements(updatedData)
  }

  const checkBoxOnChange = (index: number, value: any) => {
    const updatedData = formElements.map((element: any, i: number) => {
      if (i === index) {
        return { ...element, values: { ...element.values, checkboxOptions: value } }
      }
      return element
    })
    setFormElements(updatedData)
  }

  const textAreaOnChange = (index: number, value: any) => {
    const updatedData = formElements.map((element: any, i: number) => {
      if (i === index) {
        return { ...element, values: { ...element.values, text: value } }
      }
      return element
    })
    setFormElements(updatedData)
  }

  const renderButton = (status: string) => {
    switch (status) {
      case 'approved':
        return (
          <div className='d-flex align-items-center gap-1'>
            <Button text='Send to Client' className='btn btn-sm fs-8 px-3' props={{}} />
          </div>
        )
      case 'in_review':
        return (
          <div className='d-flex align-items-center gap-1'>
            {authUser.result.role == ROLES.SUPERVISING_ATTORNEY || authUser.result.role == ROLES.ADMIN ? (
              <>
                <Button
                  text='Require Revision'
                  className='btn btn-sm btn-active-light-danger bg-transparent text-danger border border-danger fs-8 px-3'
                  props={{ onClick: handleRevisionReport, loading: loading }}
                />
                <Button text='Approve' className='btn btn-sm btn-success fs-8 px-3' props={{ onClick: handleApproveReport, loading: loading }} />
              </>
            ) : null}

          </div>
        )
      case 'in_progress':
        return (
          <div className='d-flex align-items-center gap-1'>
            {authUser.result.role == 'attorney' && <Button
              text='Submit for Review'
              className='btn btn-sm btn-active-warning bg-warning fs-8 px-3'
              props={{ onClick: handleSubmitReview, loading: loading }}
            />}
            {/* <Button text='Approve' className='btn btn-sm btn-success fs-8 px-3' props={{}} /> */}
          </div>
        )
      case 'requires_revision':
        return (
          <div className='d-flex align-items-center gap-1'>
            {authUser.result.role == 'attorney' && <Button
              text='Submit for Review'
              className='btn btn-sm btn-active-warning bg-warning fs-8 px-3'
              props={{ onClick: handleSubmitReview, loading: loading }}
            />}
            {/* <Button text='Approve' className='btn btn-sm btn-success fs-8 px-3' props={{}} /> */}
          </div>
        )
      default:
        return null
    }
  }

  useMemo(() => {
    if (report_detail?.report_json) setFormElements([...report_detail?.report_json])
  }, [report_detail])

  useEffect(() => {
    if (location) {
      let param = {
        token: authUser.result.token,
        report_id: location?.reportId,
      }
      dispatch(callGetReportById(param))
    }
    return () => {
      setFormElements([])
    }
  }, [])
  const handleSubmitReview = () => {
    // console.log("");
    setLoading(true)
    callUpdateReport('in_review');

  }
  const handleRevisionReport = () => {
    setLoading(true)
    callUpdateReport('requires_revision');
  }
  const handleApproveReport = () => {
    setLoading(true)
    callUpdateReport('approved');
  }
  const callUpdateReport = async (status: string) => {
    let param = {
      token: authUser?.result?.token,
      report_json: formElements,
      status: status,
      id: report_detail._id
    }
    const res = await updateReport(param)
    if (res) {
      // console.log('res: ', res);
      setLoading(false);
      let param = {
        token: authUser.result.token,
        report_id: location?.reportId,
      }
      dispatch(callGetReportById(param))
    }
  }
  return (
    <div className='vh-100' style={{ padding: '50px 0px' }}>
      {/* Modal */}

      {/* Page Head */}
      <div>
        <PageTitle breadcrumbs={dashboardBreadCrumbs}>Archive Report</PageTitle>
        <DefaultTitle onBackPress={() => {
          navigate(-1)
        }} />
        <hr className='text-gray-500 mb-0' style={{ marginTop: '25px' }} />

        {/* Header */}
        <div className='row gy-1' style={{ padding: '20px 0px' }}>
          <div className='col-6 col-xxl-2 order-1 order-xxl-1'>
            <div className='d-flex flex-column align-items-start justify-content-center gap-2 h-100'>
              <span className='badge badge-secondary p-3'>Archived</span>
              <h4 className='m-0'>{report_detail?.name}</h4>
              <p
                className='m-0 fs-8'
                style={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {report_detail?.description}
              </p>
            </div>
          </div>
          <div className='col-12 col-xxl-8 order-3 order-xxl-2'>
            <div className='row gy-1 h-100'>
              <div className='col-8 h-100'>
                <div className='row gy-1 h-100'>
                  <div className='col-4 h-100'>
                    <div className='d-flex flex-column align-items-start justify-content-end gap-3 h-100 flex-wrap'>
                      <label className='m-0 fs-8 text-gray-500'>ATTORNEY</label>
                      <div className='w-100'>
                        <h5 className='m-0'>{`${report_detail?.attorney_user?.first_name} ${report_detail?.attorney_user?.last_name}`}</h5>
                        <p
                          className='m-0 fs-7'
                          style={{
                            display: 'block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                          }}
                        >
                          {report_detail?.attorney_user?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='d-flex flex-column align-items-start justify-content-end gap-3 h-100 flex-wrap'>
                      <label className='m-0 fs-8 text-gray-500'>SUPERVISOR</label>
                      <div className='w-100'>
                        <h5 className='m-0'>
                          {`${report_detail?.supervising_attorney_user?.first_name} ${report_detail?.supervising_attorney_user?.last_name}`}
                        </h5>
                        <p
                          className='m-0 fs-7'
                          style={{
                            display: 'block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                          }}
                        >
                          {report_detail?.supervising_attorney_user?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='d-flex flex-column align-items-start justify-content-end gap-3 h-100'>
                      <label className='m-0 fs-8 text-gray-500'>CLIENT</label>
                      <div className='w-100'>
                        <h5 className='m-0'>{report_detail?.client?.name}</h5>
                        <p
                          className='m-0 fs-7'
                          style={{
                            display: 'block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                          }}
                        >
                          support@allstate.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-4 h-100'>
                <div className='row gy-1 h-100'>
                  <div className='col-6'>
                    <div className='d-flex flex-column align-items-start justify-content-end gap-3 h-100'>
                      <label className='m-0 fs-8 text-gray-500'>DUE DATE SUBMITED</label>
                      <div>
                        <h5 className='m-0'>{report_detail?.due_date || ''}</h5>
                      </div>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='d-flex flex-column align-items-start justify-content-end gap-3 h-100'>
                      <label className='m-0 fs-8 text-gray-500'>REPORT FREQUENCY</label>
                      <div>
                        <h5 className='m-0'>30 Days</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-6 col-xxl-2 order-2 order-xxl-3'>
            <div className='d-flex flex-column align-items-end justify-content-between gap-5 h-100'>
              <div className='text-end'>
                <p className='m-0 fs-7 fw-semibold'>Matter: {report_detail?.matter?.name}</p>
                <p className='m-0 fs-7 fw-semibold text-gray-700'>
                  Report ID: {report_detail?.report_id}
                </p>
              </div>
              {/* {renderButton(report_detail?.status)} */}
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: 'calc(100% - 270px)' }}>
        <div className='row gx-7 h-100'>
          {/* Report Section */}
          <div className='col-12 h-100'>
            <div
              className='bg-gray-200 h-100 rounded-3 border-solid overflow-auto'
              style={{ padding: '20px' }}
            >
              <div
                className='bg-white rounded-3'
                style={{ padding: '30px' }}
                onSubmit={(e) => e.preventDefault()}
              >
                <div className='row gx-9 gy-7'>
                  {formElements?.map((element: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        {renderElement(element.type, index, element)}
                      </Fragment>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>

          {/* Feedback Section */}
        </div>
      </div>
    </div>
  )
}

export { ArchiveReportDetail }
