import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../theme/helpers'
import Button from '../../../../components/Button'
import InputBox from '../../../../components/InputBox'
import {CustomSelect} from '../../../../components/CustomSelect'
import {useFormik} from 'formik'
import {reviewReportValidationSchema} from '../../../../../utils/validations'
import {CustomDate} from '../../../../components/CustomDate'
import {useSelector} from 'react-redux'
import {RootStateTypes} from '../../../../modules/auth'
import {useAppDispatch, useAppSelector} from '../../../../redux/redux-hook/hooks'
import {callAllClient} from '../../../../redux/reducer/ClientSlice'
import {callAttorney, callSA} from '../../../../redux/reducer/AttorneySlice'
import {assignReport} from '../../../../modules/auth/core/_requests'
import {fetchClientMatters} from '../../../../redux/reducer/MattersSlice'

interface ReviewReportModalProps {
  open: boolean
  setOpen: Function
  setAssignReportComplete: any
  templateData: any
}

export const ReviewReportModal: React.FC<ReviewReportModalProps> = ({
  open,
  setOpen,
  setAssignReportComplete,
  templateData,
}) => {
  const [formLoading, setFormLoading] = useState(false)
  const authUser = useSelector((state: RootStateTypes) => state.auth.user.data)
  const Allclient = useAppSelector((state) => state.clientSlice.allClients.client)
  const {data: all_attorney} = useAppSelector((state) => state.attorney.all_attorney)
  const {data: all_SA} = useAppSelector((state) => state.attorney.all_sa)
  const {data: matter} = useAppSelector((state) => state.clientMatters)

  const dispatch = useAppDispatch()

  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    let param = {token: authUser?.result?.token}
    dispatch(callAllClient(param))
    dispatch(callSA(param))
    dispatch(callAttorney(param))
  }, [])

  const {
    handleChange,
    handleSubmit,
    touched,
    handleBlur,
    errors,
    values,
    resetForm,
    setFieldValue,
  }: any = useFormik({
    initialValues: {
      templateName: templateData.name,
      description: templateData.description,
      attorney: '',
      supervisor: '',
      client: '',
      matter: '',
      dueDate: '',
    },
    validationSchema: reviewReportValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const newValue = {
        name: values.templateName,
        description: values.description,
        client_id: values.client,
        attorney_id: values.attorney,
        supervising_attorney_id: values.supervisor,
        matter_id: values.matter,
        due_date: values.dueDate,
        report_json: templateData.template_json,
        template_id: templateData._id,
        token: authUser?.result?.token,
      }
      try {
        setFormLoading(true)
        const res = await assignReport(newValue)
        setFormLoading(false)
        onClose()
        setAssignReportComplete(true)
      } catch (error) {
        setFormLoading(false)
        console.log('Error', error)
      }
    },
  })

  const attorneyOnChange = (value: {_id: string; name: string}) => {
    setFieldValue('attorney', value._id)
  }
  const supervisorOnChange = (value: {_id: string; name: string}) => {
    setFieldValue('supervisor', value._id)
  }
  const clientOnChange = (value: {_id: string; name: string}) => {
    setFieldValue('client', value._id)
    dispatch(
      fetchClientMatters({
        list_all: 0,
        client_id: value._id,
        search: '',
        page: '',
        limit: '',
        token: authUser?.result?.token,
      })
    )
  }
  const matterOnChange = (value: {_id: string; name: string}) => {
    setFieldValue('matter', value._id)
  }

  useEffect(() => {
    if (!open) resetForm()
  }, [open, resetForm])

  return (
    <Modal show={open} centered className='modal-lg' onHide={onClose}>
      <div className='modal-content gap-8 rounded-3' style={{padding: '40px'}}>
        <div className='modal-header border-0 p-0 flex-column align-items-start gap-3'>
          {/* <KTIcon iconName='folder' className='fs-2hx text-light bg-primary rounded-circle p-4' /> */}
          <KTIcon iconName='folder' className='fs-2hx text-light bg-primary rounded-circle p-4' />
          <div className='d-flex flex-column gap-3'>
            <h1 className='m-0'>Review Your Report</h1>
            <p className='m-0'>Confirm the report details before assigning the report.</p>
          </div>
        </div>
        <div className='modal-body border rounded' style={{padding: '30px'}}>
          <form className='d-flex flex-column w-100' onSubmit={(e) => e.preventDefault()}>
            <div>
              <div className='d-flex gap-3 align-items-end'>
                <InputBox
                  label='TEMPLATE NAME'
                  labelClassName='fs-8 text-gray-500'
                  className='p-0 border-0 rounded-0'
                  props={{
                    type: 'text',
                    name: 'templateName',
                    value: values.templateName,
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                />
                <KTIcon iconName='pencil' className='fs-6 text-primary mb-2' />
              </div>
              {Boolean(touched.templateName && errors.templateName) && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{touched.templateName && errors.templateName}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='separator my-4' />
            <div>
              <label className='form-label fw-bolder fs-8 text-gray-500'>DESCRIPTION</label>
              <div className='d-flex justify-content-between gap-2 border border-primary p-2 rounded'>
                <textarea
                  className='form-control p-0 border-0 rounded-0'
                  id='exampleFormControlTextarea1'
                  rows={4}
                  name='description'
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <KTIcon iconName='pencil' className='fs-6 text-primary mb-2' />
              </div>
              {Boolean(touched.description && errors.description) && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{touched.description && errors.description}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='separator my-4' />
            <div>
              <label className='form-label fw-bolder fs-8 text-gray-500'>ASSIGNED PEOPLE</label>
              <div className='row'>
                <div className='col-4'>
                  <CustomSelect
                    name='attorney'
                    placeHolder='Attorney'
                    options={all_attorney}
                    value={values.attorney}
                    onChange={attorneyOnChange}
                    error={touched.attorney && errors.attorney}
                    icon={<KTIcon iconName='profile-circle' className='fs-1 text-dark' />}
                    className='bg-white'
                    clientSelect={true}
                  />
                </div>
                <div className='col-4'>
                  <CustomSelect
                    name='supervisor'
                    placeHolder='Supervisor'
                    // options={clients}
                    options={all_SA}
                    value={values.supervisor}
                    onChange={supervisorOnChange}
                    error={touched.supervisor && errors.supervisor}
                    icon={<KTIcon iconName='profile-circle' className='fs-1 text-dark' />}
                    className='bg-white'
                    clientSelect={true}
                  />
                </div>
                <div className='col-4'>
                  <CustomSelect
                    name='client'
                    placeHolder='Client'
                    options={Allclient}
                    value={values.client}
                    onChange={clientOnChange}
                    error={touched.client && errors.client}
                    icon={<KTIcon iconName='profile-circle' className='fs-1 text-dark' />}
                    className='bg-white'
                    clientSelect={true}
                  />
                </div>
                <div className='separator my-4' />
                <div className='col-4'>
                  <CustomSelect
                    label='MATTER'
                    labelClassName='fs-8 text-gray-500'
                    name='matter'
                    placeHolder='Matter'
                    options={matter.result.data[0]?.matters || []}
                    value={values.matter}
                    onChange={matterOnChange}
                    error={touched.matter && errors.matter}
                  />
                </div>
                <div className='col-5'>
                  <CustomDate
                    label='DUE DATE'
                    labelClassName='fs-8 text-gray-500'
                    name='dueDate'
                    placeHolder='Set Due Date'
                    value={values.dueDate}
                    onChange={handleChange}
                    error={touched.dueDate && errors.dueDate}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className='modal-footer border-0 p-0 justify-content-between align-items-center'>
          <div className='d-flex gap-3'>
            <Button text='Cancel' className='btn-secondary' props={{onClick: onClose}} />
            <Button
              text='Assign Report'
              loading={formLoading}
              props={{type: 'button', onClick: handleSubmit, disabled: formLoading}}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
