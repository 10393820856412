import moment from 'moment'
import React, {useEffect, useState} from 'react'
// import { Pagination } from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../theme/layout/core'
import {DefaultTitle} from '../../../theme/layout/components/header/page-title/DefaultTitle'
import {
  dashboardBreadCrumbs,
  fakeActiveReportDataHead,
  fakeOneTimeReportDataHead,
  state,
} from '../../../utils/constants'
import SearchBar from '../../components/SearchBar'
import {useAppDispatch, useAppSelector} from '../../redux/redux-hook/hooks'
import {KTIcon} from '../../../theme/helpers'
import {status} from '../../../utils/Helpers'
import {useSelector} from 'react-redux'
import {RootStateTypes} from '../../modules/auth'
import {callAllClient, callPublicClient} from '../../redux/reducer/ClientSlice'
import {Pagination} from '../../components/Pagination'
import {callGetTemplate} from '../../redux/reducer/TemplateSlice'

const OneTimeReport = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {client} = useAppSelector((state) => state.clientSlice.publicClient) as any
  const {loading, data} = useAppSelector((state) => state.templates.templates)
  const {token, role, _id} = useSelector((state: RootStateTypes) => state.auth.user.data.result)

  const [params, setParams] = useState({
    search: '',
    limit: 10,
    page: 1,
    client_id: '',
  })

  useEffect(() => {
    dispatch(callPublicClient({token, oneTimeReport: 1}))
  }, [dispatch, token])

  const onPageChange = (page: number) => {
    setParams({...params, page: page})
  }

  const onSearch = (e: any) => {
    const {value} = e.target
    setParams({...params, search: value})
  }

  useEffect(() => {
    if (params.client_id) {
      dispatch(callGetTemplate({...params, token}))
    }
  }, [dispatch, params, token])

  useEffect(() => {
    setParams({...params, client_id: client[0]?._id})
  }, [client])

  return (
    // <div className='vh-100' style={{ padding: '50px 0px' }}>
    <div className='min-vh-100' style={{padding: '50px 0px'}}>
      {/* Page Head */}
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>One Time Reports</PageTitle>
      <DefaultTitle
        breadcrumbTitle='Client Templates'
        onBackPress={() => {
          navigate(-1)
        }}
      />
      {/* <div
        className='card shadow-sm border'
        style={{height: 'calc(100% - 120px)', padding: '30px 0px', marginTop: '33px'}}
      > */}
      <div
        className='card shadow-sm border'
        style={{minHeight: 'calc(100vh - 232px)', padding: '30px 0px', marginTop: '33px'}}
      >
        {/* begin::Header */}
        <div className='h-auto' style={{padding: '0px 20px 20px 20px'}}>
          <div className='w-100 d-flex justify-content-between align-items-center p-0 m-0 gap-4 flex-wrap'>
            <SearchBar
              placeholder='Search Templates'
              handleSearch={onSearch}
              loading={loading && params.search.length !== 0}
            />
            <div className='d-flex gap-4 flex-wrap justify-content-between align-items-center'></div>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body overflow-auto position-relative' style={{padding: '0px 30px'}}>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead className='' style={{position: 'sticky', top: 0, zIndex: 2}}>
                <tr className='fw-bold text-muted'>
                  {fakeOneTimeReportDataHead.map((columnName, key) => {
                    return (
                      <th
                        key={key}
                        className={`${columnName === 'REPORT NAME' && 'min-w-400px'} ${
                          columnName === 'ACTIONS' && 'd-flex flex-end'
                        }`}
                      >
                        {columnName}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {data.data.map((data: any, key: number) => {
                  return (
                    <tr key={key}>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data.name}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {data.client.name}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center py-2'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {moment(data.createdAt).format('MM/DD/YYYY')}
                          </span>
                        </div>
                      </td>
                      <td className='d-flex flex-end '>
                        <div className='d-flex align-items-center py-2 gap-3'>
                          <div
                            className='bg-primary-subtle d-flex p-2 rounded cursor-pointer'
                            onClick={() => {
                              navigate(`/report/${data._id}`)
                            }}
                          >
                            <KTIcon iconName='copy' className='text-gray-700 fs-4 align-self-end' />
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {data.data.length === 0 && (
            <div
              className='position-absolute top-0 left-0 right-0 h-100 d-flex justify-content-center align-items-center'
              style={{width: 'calc(100% - 60px)'}}
            >
              <h1 className='m-0 fs-2x text-gray-400'>No Data Found</h1>
            </div>
          )}
          {/* end::Table container */}
        </div>

        <div className='h-auto d-flex justify-content-end' style={{padding: '20px 20px 0px 20px'}}>
          <Pagination
            currentPage={params.page}
            totalPages={data.total_page}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </div>
  )
}

export {OneTimeReport}
