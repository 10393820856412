import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Modal } from 'react-bootstrap'
import { KTIcon } from '../../../../theme/helpers'
import InputBox from '../../../components/InputBox'
import { accountRole, states } from '../../../../utils/constants'
import Button from '../../../components/Button'
import { createAttorneyValidationSchema } from '../../../../utils/validations'
import { useNavigate } from 'react-router-dom'
import { RootStateTypes, createAttorneyModal } from '../../../modules/auth'
import { callCreateAttorney, callGetAttorneys } from '../../../redux/reducer/AttorneySlice'
import { useAppDispatch, useAppSelector } from '../../../redux/redux-hook/hooks'
import { CustomSelect } from '../../../components/CustomSelect'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { useSelector } from 'react-redux'

const animatedComponents = makeAnimated()

interface CreateAttorneyFormProps {
  open: boolean
  setOpen: Function
}

export const CreateAttorneyForm: React.FC<CreateAttorneyFormProps> = ({ open, setOpen }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector((state) => state.attorney.createAttorney)
  const [params, setParams] = useState({
    search: '',
    date_filter: '',
    state: '',
    admin: 1,
    attorney: 1,
    supervising_attorney: 1,
    limit: 10,
    page: 1,
  })
  const { token, role } = useSelector((state: RootStateTypes) => state.auth.user.data.result)
  const onClose = () => {
    setOpen(false)
    navigate('/view-attorney')
  }

  const {
    handleChange,
    handleSubmit,
    touched,
    handleBlur,
    errors,
    values,
    resetForm,
    setFieldValue,
  }: any = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      states: [],
      barNumber: '',
      email: '',
      accountRole: '',
    },
    validationSchema: createAttorneyValidationSchema,
    onSubmit: async (values) => {
      const { firstName, lastName, email, accountRole, barNumber, states } = values

      const newStates = states.map((state: any) => state.value)

      const data: createAttorneyModal = {
        first_name: firstName,
        last_name: lastName,
        states: newStates,
        bar_number: barNumber,
        email,
        role: accountRole,
      }
      dispatch(callCreateAttorney(data)).then((res: any) => {
        if (res.payload.data.success) {
          dispatch(callGetAttorneys({ token, ...params }))
          onClose()

        }
      })
    },
  })

  const accountRoleOnChange = (value: any) => {
    setFieldValue('accountRole', value._id)
  }

  const statesOnChange = (value: any) => {
    setFieldValue('states', value)
  }

  useEffect(() => {
    if (!open) resetForm()
  }, [open, resetForm])

  return (
    <Modal show={open} centered className='modal-lg' onHide={onClose}>
      <div className='modal-content gap-8 rounded-3' style={{ padding: '40px' }}>
        <div className='modal-header border-0 p-0 flex-column align-items-start gap-3'>
          <KTIcon
            iconName='user-tick'
            className='fs-2hx text-light bg-primary rounded-circle p-4'
          />
          <div className='d-flex flex-column gap-3'>
            <h1 className='m-0'>Create Attorney</h1>
            <p className='m-0'>
              Enter the attorney's account information and <br /> assign a role for them to access
              reports
            </p>
          </div>
        </div>
        <div className='modal-body border rounded' style={{ padding: '30px' }}>
          <form className='d-flex flex-column gap-5 w-100' onSubmit={(e) => e.preventDefault()}>
            <div className='row'>
              <div className='col'>
                <InputBox
                  label='FIRST NAME'
                  labelClassName='fs-8 text-gray-500'
                  className='h-50'
                  error={touched.firstName && errors.firstName}
                  isValid={touched.firstName && !errors.firstName}
                  props={{
                    type: 'text',
                    name: 'firstName',
                    value: values.firstName,
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                />
              </div>
              <div className='col'>
                <div className='w-100'>
                  <InputBox
                    label='LAST NAME'
                    labelClassName='fs-8 text-gray-500'
                    className='h-50'
                    error={touched.lastName && errors.lastName}
                    isValid={touched.lastName && !errors.lastName}
                    props={{
                      type: 'text',
                      name: 'lastName',
                      value: values.lastName,
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <label className={`form-label fw-bolder fs-8 text-gray-500 mb-2`}>STATE(S)</label>
                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={states}
                  name='states'
                  value={values.states}
                  onChange={statesOnChange}
                  placeholder=''
                  styles={{
                    control: (baseStyles: any, state: any) => {
                      // console.log('baseStyles', baseStyles)
                      // console.log('state', state)
                      return {
                        ...baseStyles,
                        borderWidth: '1px',
                        minHeight: '39px',
                        boxShadow: 'none',
                        borderColor: state.isFocused ? '#b5b5c3' : '#e1e3ea',
                        borderRadius: '0.475rem',
                      }
                    },
                  }}
                  theme={(theme: any) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#EEF6FF',
                      primary: '#b5b5c3',
                      danger: '#F1416C',
                    },
                  })}
                />
                {!(touched.states && errors.states) ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='text-gray-500'>Separate states with comma</span>
                    </div>
                  </div>
                ) : (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{touched.states && errors.states}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col'>
                <InputBox
                  label='BAR NUMBER'
                  labelClassName='fs-8 text-gray-500'
                  className='h-50'
                  error={touched.barNumber && errors.barNumber}
                  isValid={touched.barNumber && !errors.barNumber}
                  props={{
                    type: 'text',
                    name: 'barNumber',
                    value: values.barNumber,
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-8'>
                <InputBox
                  label='EMAIL ADDRESS'
                  labelClassName='fs-8 text-gray-500'
                  className='h-50'
                  error={touched.email && errors.email}
                  isValid={touched.email && !errors.email}
                  props={{
                    type: 'text',
                    name: 'email',
                    value: values.email,
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                />
              </div>
              <div className='col-4'>
                <CustomSelect
                  label='ACCOUNT ROLE'
                  labelClassName='fs-8 text-gray-500'
                  name='accountRole'
                  className='h-40px'
                  placeHolder='Account Role'
                  options={[
                    ...accountRole,
                    {
                      _id: '',
                      name: 'Reset Role',
                    },
                  ]}
                  value={values.accountRole}
                  error={touched.accountRole && errors.accountRole}
                  onChange={accountRoleOnChange}
                />
              </div>
            </div>
          </form>
        </div>
        <div className='modal-footer border-0 p-0 justify-content-start'>
          <Button text='Cancel' className='btn-secondary' props={{ onClick: onClose }} />
          <Button
            text='Create Account'
            loading={loading}
            props={{ type: 'button', onClick: handleSubmit, disabled: loading }}
          />
        </div>
      </div>
    </Modal>
  )
}
